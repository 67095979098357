<template>
  <div>
    <div class="title">
      <h2>联系我们</h2>
      <p>contact us</p>
    </div>
    <div class="foot-imgbtn">
      <div class="foot-imgbtn1" @click="gocontact"></div>
      <div class="foot-imgbtn2" @click="gocontact"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    gocontact() {
      this.$router.push({ path: "/contact" });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 1320px;
  margin: 0 auto;
  margin-top: 100px;
  > h2 {
    font-size: 40px;
    font-weight: bold;
    color: #333333;
    line-height: 60px;
    text-align: center;
  }
  > p {
    font-size: 20px;
    color: #999595;
    line-height: 28px;
    text-align: center;

    letter-spacing: 10px;
  }
}
.foot-imgbtn {
  padding: 80px 0;
  margin: 0 auto;
  display: flex;
  width: 1320px;
  height: 20vh;
  justify-content: space-between;
  .foot-imgbtn1 {
    width: 616px;
    background: url(../assets/fot1.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .foot-imgbtn2 {
    width: 616px;
    background: url(../assets/fot2.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>