<template>
  <div class="EV260">
    <Sidebar />
    <!-- 头部盒子产品名 -->
    <div class="EV260-bottom">
      <div class="EV260-head">
        <div class="EV260-head__title">
          <p class="EV260-head__text1">{{ uavname }}</p>
          <p class="EV260-head__text2">{{ uavpdec }}</p>
        </div>
      </div>
    </div>

    <!-- 产品展示页面 -->
    <div class="EV260-introduction">
      <div class="EV260-introduction__head">
        <div class="EV260-introduction__name">
          <div class="EV260-introduction__headimg"></div>
          <div class="EV260-introduction__headtitle">
            <p class="EV260-introduction__text1">{{ Subtitlehead.text1 }}</p>
            <p class="EV260-introduction__text2">{{ Subtitlehead.text2 }}</p>
          </div>
        </div>
      </div>
      <!-- 产品展示图片 -->
      <div class="EV260-image">
        <div class="EV260-small__img">
          <img v-lazy="imgurl + uavsm_pic" alt />
        </div>
        <div class="EV260-big__img">
          <img v-lazy="imgurl + uavlg_pic" alt />
        </div>
      </div>
    </div>
    <!-- 产品参数 -->
    <div class="EV260-product">
      <div class="EV260-introduction__head">
        <div class="EV260-introduction__name">
          <div class="EV260-introduction__headimg"></div>
          <div class="EV260-introduction__headtitle">
            <p class="EV260-introduction__text1">{{ Subtitleparameter.text1 }}</p>
            <p class="EV260-introduction__text2">{{ Subtitleparameter.text2 }}</p>
          </div>
        </div>
      </div>
      <!-- 参数图片 -->
      <div class="EV260-center__image">
        <img v-lazy="imgurl + uavppara" alt />
      </div>
    </div>
     <!-- 相关设备 -->
     <div class="EV260-product" v-if="relequ">
      <div class="EV260-introduction__head">
        <div class="EV260-introduction__name">
          <div class="EV260-introduction__headimg"></div>
          <div class="EV260-introduction__headtitle">
            <p class="EV260-introduction__text1">{{ Subtitquipment.text1 }}</p>
            <p class="EV260-introduction__text2">{{ Subtitquipment.text2 }}</p>
          </div>
        </div>
      </div>
      <!-- 参数图片 -->
      <div class="EV260-center__image">
        <img v-lazy="imgurl + relequ" alt />
      </div>
    </div>

    <!-- 相关文档 -->
    <div class="EV260-related" v-if="uavpword.length!==undefined&&uavpword.length>0">
      <div>
        <!-- <Subtitle ref="Subtitlefile" /> -->
        <div class="EV260-introduction__name">
          <div class="EV260-introduction__headimg"></div>
          <div class="EV260-introduction__headtitle">
            <p class="EV260-introduction__text1">{{ Subtitlefile.text1 }}</p>
            <p class="EV260-introduction__text2">{{ Subtitlefile.text2 }}</p>
          </div>
        </div>
        <div class="EV260-related__center">
          <div class="EV260-related__buy" v-for="(item, index) in uavpword" :key="index">
            <div class="EV260-related__text">{{ item.name }}</div>

            <div class="EV260-related__bt">
              <i class="el-icon-download">
                <a :href="imgurl + item.src" class="fl-item-a">立即下载</a>
              </i>
            </div>
          </div>
          <!-- <div class="EV260-related__download">
            <div class="EV260-related__text">售后文档</div>
            <el-button><i class="el-icon-download">立即下载</i></el-button>
          </div>-->
        </div>
      </div>
    </div>
    <!-- 如何购买 -->
    <div class="EV260-how">
      <div class="EV260-introduction__head">
        <div class="EV260-introduction__name">
          <div class="EV260-introduction__headimg"></div>
          <div class="EV260-introduction__headtitle">
            <p class="EV260-introduction__text1">{{ Subtitlebuy.text1 }}</p>
            <p class="EV260-introduction__text2">{{ Subtitlebuy.text2 }}</p>
          </div>
        </div>
      </div>
      <buy />
    </div>

    <!-- 底部链接 -->
    <!-- <div class="fixed-footer">
      <MyFooter />
    </div>-->
  </div>
</template>

<script>
// import MyFooter from "../components/footer.vue";
// import { computed, components } from "vue";
import { Uav } from "../../axios/modules/officialwebsite.js";
import { networkConfig } from "../../axios/networkConfig";
import buy from "../../components/buy.vue";
import Sidebar from "../../components/sidebar.vue";
export default {
  data() {
    return {
      uavname: "",
      uavpdec: "",
      uavsm_pic: "",
      uavlg_pic: "",
      uavppara: "",
      uavpword: "",
      relequ:'',
      Subtitlehead: {
        text1: "产品展示",
        text2: "PRODUCT DISPLAY"
      },
      Subtitleparameter: {
        text1: "产品参数",
        text2: "PRODUCT PARAMETERS"
      },
      Subtitquipment: {
        text1: "相关设备",
        text2: "RELATED EQUIPMENT"
      },
      Subtitlefile: {
        text1: "相关文档",
        text2: "RELATED DOCUMENTS"
      },
      Subtitlebuy: {
        text1: "如何购买",
        text2: "HOW TO BUY"
      },
      imgurl: networkConfig.serverUrl + "upload/"
    };
  },
  methods: {
    stationData(code, data) {
      if (code == 0) {
        // console.log(data);
        this.uavname = data.ptitle;
        this.uavpdec = data.pdec;
        this.uavsm_pic = data.sm_pic;
        this.uavlg_pic = data.lg_pic;
        this.uavpword = data.pword;
        this.uavppara = data.ppara;
        this.relequ = data.relequ
        // console.log(this.right_list_arr[0], 444);
        return;
      }
    }
  },
  mounted() {},
  components: {
    buy,
    Sidebar
  }
};
</script>
<style lang="scss" scoped>
// 页面整体

.EV260 {
  width: 1320px;
  margin: 0 auto;
}
// 产品名底层盒子
.EV260-bottom {
  // position: relative;
  height: 390px;
  margin-bottom: 63px;
}
// 产品头部产品名
.EV260-head {
  width: 1900px;
  height: 390px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: linear-gradient(180deg, #ffffff 0%, #f2f6ff 100%);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  .EV260-head__title {
    width: 1320px;
    margin: 0 auto;
    text-align: left;
    padding: 100px 0;
    .EV260-head__text1 {
      font-size: 60px;
      line-height: 200px;
      font-weight: bold;
      color: #333333;

      // margin-bottom: 50px;
    }
    .EV260-head__text2 {
      font-size: 30px;

      // font-weight: 400;
      color: #333333;
      // line-height: 30px;
    }
  }
}
// 产品展示
.EV260-introduction {
  width: 100%;
}
.EV260-image {
  width: 100%;

  // margin: auto;
  display: flex;

  align-items: center;

  // margin: 40px 0;
  margin-top: 55px;
  margin-bottom: 120px;

  .EV260-small__img {
    width: 108px;
    height: 108px;
    margin-right: 80px;

    border: 1px solid #ccc;
    margin-top: 10px;
    > img {
      width: 100%;
    }
  }
  .EV260-big__img {
    width: 1132px;
    height: 404px;
    > img {
      width: 80%;
    }
  }
}
// 产品参数图
.EV260-product {
  width: 100%;
  margin: auto;

  .EV260-center__image {
    width: 100%;
    // height: 4.7rem;
    // margin: 60px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 200px;

    background-size: contain;
  }
}
// 相关文档
.EV260-related {
  width: 1320px;

  .EV260-related__center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 38px;
    margin-bottom: 109px;
  }
  .EV260-related__buy {
    width: 649px;
    height: 80px;
    // margin: 50px 0;
    border: 1px solid #cccccc;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .EV260-related__text {
      padding: 28px 40px;
      // font-size: 16px;
      font-size: 18px;
    }
    .EV260-related__bt {
      width: 147px;
      height: 40px;
      // padding: 20px 50px;
      margin: 20px 50px;
      text-align: center;
      line-height: 40px;
      // color: #004598;
      border: 1px solid #004598;
    }
    .fl-item-a {
      font-size: 20px;
      text-decoration: none;
      color: #004598;
    }
  }
}
.EV260-introduction__head {
  width: 100%;
  // height: 130px;
  margin: 40px 0;
}

.EV260-introduction__name {
  display: flex;
  // align-items: center;
  // margin-top: 250px;

  .EV260-introduction__headimg {
    width: 18px;
    height: 66px;
    background-image: url(../../assets/image/aircraft/bt1.jpg);
    background-size: 100% 100%;
  }
  .EV260-introduction__headtitle {
    margin-left: 23px;
    text-align: left;
    .EV260-introduction__text1 {
      font-size: 25px;
      margin-bottom: 14px;
      // font-weight: 400;
      color: #333333;
      // line-height: 28px;
    }
    .EV260-introduction__text2 {
      font-size: 14px;

      // font-weight: 400;
      color: #666666;
      // line-height: 28px;
    }
  }
}
</style>