<template>
  <div class="application_page">
    <sidebar></sidebar>
    <!--海报-->
    <div class="application-bottom">
      <div class="application-post"></div>
    </div>
    <h2>行业应用分类</h2>
    <p class="application-text__EN">INDUSTRY APPLICATION CLASSIFICATION</p>
    <!--瀑布流五栏布局-->
    <div class="Waterfall">
      <ul v-for="item in appList" :key="item.id">
        <div v-for="i in item.listitem" :key="i.id">
          <li>
            <img :src="i.src" alt />
            <h3>{{ i.text1 }}</h3>
            <p class="Waterfall-text2">{{ i.text2 }}</p>
          </li>
        </div>
      </ul>
    </div>
    <!-- 底部链接 -->
    <!-- <div class="fixed-footer">
      <MyFooter />
    </div>-->
  </div>
</template>

<script>
//import MyFooter from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";

import { networkConfig } from "../axios/networkConfig";
export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      appList: [
        {
          id: 1,
          listitem: [
            {
              href: "http://localhost:8080/#/home/index",
              src: require("../assets/application/first.jpg"),
              text1: "测绘",
              text2:
                " RTK结合强大的数据采集与后处理软件，为地形图测量、城市测量、管道和道路测量以及土木工程从业者提供专业、稳定的设备、解决方案，以获取高精度的地物位置信息。"
            },
            {
              href: "http://localhost:3000/#/",
              src: require("../assets/application/second.jpg"),
              text1: "农林",
              text2:
                " 结合正射影像、激光雷达、多光谱传感技术，分析农作物、森林树木的覆盖率和面积，了解其疏密程度以及不同树龄树木的情况、推算其位置及数量，便于农业和深林进行合理规划和利用。"
            },
            {
              href: "http://localhost:3000/#/",
              src: require("../assets/application/third.jpg"),
              text1: "地理信息",
              text2:
                "  通过自主研发的内外业一体化数据生产作业平台及配套终端，提供从外业采集、内业编辑成图到入库更新的全套解决方案，大幅提升自然资源、林业、水利等领域的作业效率。"
            }
          ]
        },
        {
          id: 2,
          listitem: [
            {
              href: "http://localhost:8080/#/power",
              src: require("../assets/application/fourth.jpg"),
              text1: "电力",
              text2:
                "  基于北斗、UWB技术，融合地理信息、云计算、大数据等技术， 实现电网全时空融合精准定位，解决电力现场作业安全管控问题， 提升作业人员与设备安全保障水平"
            },
            {
              href: "http://localhost:3000/#/",
              src: require("../assets/application/fifth.jpg"),
              text1: "地灾",
              text2:
                " 针对崩塌、滑坡、泥石流等地质灾害，结合机载激光雷达、地表专业监测传感器、水面无人船等设备，采用物联网、大数据、人工智能等技术实现实时动态安全监测。"
            },
            {
              href: "http://localhost:3000/#/",
              src: require("../assets/application/eight.jpg"),
              text1: "水文水利",
              text2:
                "  利用声光电等先进技术，破解流量、泥沙等水文要素在线自动监测的技术难题，全面实现水文要素自动监测； 利用遥感遥测、视频监控等技术，构建空天地一体化的立体水文监测体系； 深化数据加工，丰富信息产品，为防汛抗旱、水资源管理、水生态修复、 水环境保护和经济社会发展等方面提供全方位服务。"
            }
          ]
        },
        {
          id: 3,
          listitem: [
            {
              href: "http://localhost:3000/#/",
              src: require("../assets/application/sixth.jpg"),
              text1: "铁路",
              text2:
                " 结合铁路运输安全生产需求，基于北斗技术，融合地理信息、云计算、大数据等技术，打造出“云+端+图+网”一体化的北斗高精度应用整体解决方案，有效提高铁路作业效率和运输安全生产水平。"
            },
            {
              href: "http://localhost:3000/#/",
              src: require("../assets/application/seventh.jpg"),
              text1: "石油石化",
              text2:
                " 基于北斗、UWB技术，融合物联网、人工智能、云计算等技术，打造出“云+端+图+网”一体化的北斗高精度应用整体解决方案，为石油石化行业提供覆盖物探、开采、储运、炼化、销售环节的专业服务。"
            },
            {
              href: "http://localhost:3000/#/",
              src: require("../assets/application/ninth.jpg"),
              text1: "矿山",
              text2:
                " 利用无人机、激光雷达、倾斜相机快速获取矿山的三维信息，通过GIS系统实现航拍图像与大地坐标真实对应，形成三维地形地质模型，为矿山的规划设计、勘探建设、生产和运营管理以及矿山报废等提供数据支撑。 同时，针对露天矿边坡、尾矿库、采空区地表沉降，实现全覆盖、实时监测、精准预警， 帮助矿山企业全面提高安全监管水平。"
            }
          ]
        }
        // {
        //   id: 4,
        //   listitem: [

        //   ],
        // },
      ]
    };
  },
  components: {
    //MyFooter,
    Sidebar
  }
};
</script>
<style lang="scss" scoped>
.application_page {
  width: 1320px;
  margin: 0 auto;
}
//海报底层样式
.application-bottom {
  // position: relative;
  height: 969px;
}
//海报样式
.application-post {
  width: 1900px;
  height: 969px;
  background: url("../assets/application/bjappli.png") center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
// 行业应用分类标题
h2 {
  font-size: 40px;
  font-weight: bold;
  line-height: 59px;
  color: #333333;
  letter-spacing: 10px;
  text-align: center;
  margin-top: 109px;
}
// 英文标题
.application-text__EN {
  font-size: 20px;

  // font-weight: 400;
  line-height: 29px;
  color: #999595;
  letter-spacing: 10px;
  text-align: center;
  margin-bottom: 107px;
  margin-top: 25px;
}
//瀑布流样式
.Waterfall {
  //min-height: 15rem;
  // margin: 1.13rem 1.3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ul {
    height: 100%;
    width: 30%;
    background: #fff;
    margin: 10px;
  }
  a {
    text-decoration: none;
  }
  li {
    list-style-type: none;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    margin-bottom: 20px;
    img {
      width: 100%;
      //height: 60%;
      // min-width: 1rem;
      // border-radius: 0.2rem 0.2rem 0rem 0rem;
    }
    h3 {
      font-size: 25px;

      font-weight: bold;
      margin: 13px 30px;
      line-height: 28px;
      color: #333333;
    }
    .Waterfall-text2 {
      font-size: 14px;
      margin: 13px 30px;
      // margin-bottom: 20px;
      line-height: 28px;
      color: #666666;
    }
  }
}
.fixed-footer {
  width: 100%;
  min-height: 1rem;
  background-color: rgba(51, 51, 51, 1);
}
</style>