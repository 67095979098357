import { personalService } from "../personal.js";
import { getCookie } from "@/utils/auth";
// 个人中心
export function selectdata() {
  const data = {
    faccount: "36z049", //账号名
    fhz: 1, //频率
    page: 1,
    pagesize: 10,
  };
  return personalService({
    url: `/api/save/select/data?token=30420B5CE63423252A11234F93C6C9004298EA67CD72D370E124E849D93455B9C2CB375CA1FA4BE063FF1A`,
    method: "post",
    data: data,
  });
}
export function savemkexcel(fuptime) {
  const data = {
    faccount: "36z049", //账号名
    fhz: 1, //频率
    fuptime,
  };
  return personalService({
    url: `/api/save/mkexcel?token=30420B5CE63423252A11234F93C6C9004298EA67CD72D370E124E849D93455B9C2CB375CA1FA4BE063FF1A`,
    method: "post",
    data: data,
  });
}
