<template>
  <div class="home-product">
    <div class="home-product__one" v-for="(item, index) in productdata" :key="index">
      <router-link :to="item.path">
        <div :class="width[index]">
          <img :src="imgurl + item.purl" alt />
          <div class="home-product__title">
            <div class="home-product__typename">{{ item.typename }}</div>
            <div class="home-product__bor"></div>
            <div class="home-product__ptitlezh">{{ item.ptitlezh }}</div>
            <div class="home-product__ptitleen">{{ item.ptitleen }}</div>
          </div>
          <div class="home-product__rut">
            了解更多
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="home-product__zg"></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { Newcarousel, SugInfo } from "@/axios/modules/officialwebsite.js";
import CarouseLimg from "./Carouselimg.vue";

// import contact from "../components/contact.vue";
import { networkConfig } from "@/axios/networkConfig";
export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      productdata: [],

      width: ["widthOne", "widthtwo", "widthtwo", "widthOne"]
    };
  },
  components: {
    // swiper,
    // swiperSlide,
    // contact,
  }
};
</script>

<style lang="scss" scoped>
// 推荐产品

.home-product {
  width: 1320px;
  height: 990px;
  margin: 0 auto;
  // background: red;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 18px;
  .home-product__one {
    height: 485px;
    margin-bottom: 20px;
    .widthOne {
      width: 874px;
      height: 485px;
      border: 1px solid #ddd;
      position: relative;
      overflow: hidden;
      // background: red;
      > img {
        position: absolute;
        width: 874px;
        height: 485px;
        -webkit-transition: 0.5s;
        transition: 0.5s;

        //   overflow: hidden;
      }
    }
    .widthtwo {
      width: 426px;
      height: 485px;
      border: 1px solid #ddd;
      position: relative;
      overflow: hidden;
      > img {
        position: absolute;
        width: 426px;
        height: 485px;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        //   overflow: hidden;
      }
    }
  }
  .widthtwo:hover {
    > img {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);

      // overflow: hidden;
    }
    .home-product__title {
      position: absolute;
      left: 30px;
      bottom: 50px;
      z-index: 99;
      animation: fadenum 0.5s;
    }
    .home-product__rut {
      position: absolute;
      left: 30px;
      bottom: 24px;
      z-index: 99;
      animation: fadenum 0.5s;
    }
    .home-product__zg {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 426px;
      height: 485px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.05) 38%,
        #000000 90%,
        rgba(0, 0, 0, 0.55) 100%
      );
    }
    @keyframes fadenum {
      0% {
        transform: translateY(20px);
      }
    }
  }
  .widthOne:hover {
    > img {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
      // overflow: hidden;
    }
    .home-product__title {
      position: absolute;
      left: 30px;
      bottom: 50px;
      z-index: 99;
      animation: fadenum 0.5s;
    }
    .home-product__rut {
      position: absolute;
      left: 30px;
      bottom: 24px;
      z-index: 99;
      animation: fadenum 0.5s;
    }
    .home-product__zg {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 874px;
      height: 485px;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.05) 38%,
        #000000 90%,
        rgba(0, 0, 0, 0.55) 100%
      );
    }
    @keyframes fadenum {
      0% {
        transform: translateY(20px);
      }
      //   50% {
      //     transform: translateY(50px);
      //   }
    }
  }
}
.home-product__typename {
  color: #ffffff;
  font-size: 0.91em;
  line-height: 1.8em;
}
.home-product__bor {
  margin: 8px 0;
  border: 2px solid #ffff;

  border-radius: 4px;
  width: 48px;
}
.home-product__ptitlezh {
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1.4em;

  color: #ffffff;
}
.home-product__ptitleen {
  color: #ffffff;

  letter-spacing: 1px;
  font-size: 0.875em;
  line-height: 1.8em;
  margin-bottom: 12px;
}
.home-product__title {
  position: absolute;
  left: 30px;
  bottom: 24px;
  animation: fadnum 0.5s;
}
.home-product__rut {
  font-size: 0.875em;
  line-height: 1.875em;
  color: #fff;
  > i {
    color: #ff6031;
  }
}
@keyframes fadnum {
  0% {
    transform: translateY(-20px);
  }
}
</style>