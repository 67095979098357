<template>
  <div>
    <div v-for="(item, index) in catalogue" :key="index">
      <a class="textname" @click="jump(index, '#type-')">{{ item.name }}</a>
    </div>
    <div class="nwecenter">
      <div v-for="(item, index) in newcenter" :key="index">
        <div :name="'type-' + index" :id="'type-' + index" class="redtext">{{ item.redtext }}</div>

        <div class="text" v-for="(itemlist,index) in item.list" :key="index">
          <div v-if="itemlist.src">
            <img :src="itemlist.src" alt class="image" />
          </div>
          <div class="},{boldtext">
            {{ itemlist.boldtext }}
            <span>{{ itemlist.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      catalogue: [],
      newcenter: []
    };
  },
  methods: {
    jump(index, querry) {
      this.$el.querySelector(querry + index).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "center" // 上边框与视窗顶部平齐。默认值
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.textname {
  font-size: 16px;
  line-height: 40px;
  cursor: pointer;
}
.nwecenter {
  margin: 200px 0;
}
.redtext {
  font-size: 16px;
  color: red;
  line-height: 50px;
  margin-top: 30px;
  font-weight: bold;
  //   margin-top: 100px;
}
.image {
  width: 500px;
  height: 400px;
}
.text {
  display: flex;
  flex-wrap: wrap;
  text-indent: 2em;
  line-height: 30px;

  .boldtext {
    font-size: 16px;
    font-weight: bold;
    span {
      font-size: 16px;
      font-weight: normal;
    }
  }
}
</style>
