<template>
  <div class="container">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in smallPic" :key="index">
        <img :src="imgurl + item" alt class="goodsImg" @click="changePic(index)" />
      </swiper-slide>

      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>
  
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

import { networkConfig } from "../../../axios/networkConfig";
export default {
  name: "imgList",
  props: ["smallPic"],
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3,
        // loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      imgurl: networkConfig.serverUrl + "upload/"
    };
  },
  methods: {
    //
    changePic(index) {
      console.log(111), this.$emit("changeIndex", index);
    }
  }
};
</script>
  <style lang="scss" scoped>
.container {
  .swiper-container {
    padding: 0 5px;
  }
  .goodsImg {
    width: 108px;
    height: 108px;
  }
}
</style>