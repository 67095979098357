<template>
  <div class="home">
    <sidebar></sidebar>
    <div class="home-bj">
      <CarouseLimg ref="carouselimg" />
    </div>
    <div class="home-center">
      <div class="home-name">
        <div class="home-name__text">推荐信息</div>
        <p></p>
      </div>
      <!-- 产品推荐 -->
      <Product ref="product" />
      <Programme ref="programme" />
    </div>
    <div class="home-middle">
      <div class="home-name">
        <div class="home-name__text">应用领域</div>
        <p></p>
      </div>
      <Application ref="application" />
      <div class="home-btn">
        <el-button @click="gotoapp">
          更多
          <i class="el-icon-arrow-right"></i>
        </el-button>
      </div>
    </div>

    <div class="home-foot">
      <div class="home-name">
        <div class="home-name__text">新闻资讯</div>
        <p></p>
      </div>
      <Newsname ref="newsname" />
      <div class="home-btn">
        <el-button @click="gotonwes">
          更多
          <i class="el-icon-arrow-right"></i>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/sidebar.vue";
import {
  Newcarousel,
  SugInfo,
  Areainfo,
  News,
  get_home
} from "@/axios/modules/officialwebsite.js";
import CarouseLimg from "./Carouselimg.vue";
import Product from "./product.vue";
import Programme from "./programme.vue";
import Application from "./application.vue";
import Newsname from "./news.vue";
// import contact from "../components/contact.vue";
import { networkConfig } from "@/axios/networkConfig";
export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      tiemdd: []
    };
  },
  components: {
    // swiper,
    // swiperSlide,
    CarouseLimg,
    Product,
    Programme,
    Application,
    Newsname,
    Sidebar
    // contact,
  },
  mounted() {
    this.Get_home();
  },
  methods: {
    // 跳转
    gotoapp() {
      this.$router.push({ path: "/application" });
    },
    gotonwes() {
      this.$router.push({ path: "/news" });
    },
    // 首页轮播图
    async Get_home() {
      let res = await get_home();
      var moment = require("moment");

      if (res.code == 0) {
        this.$refs.carouselimg.imgList = res.head;
        this.$refs.application.applicationdata = res.appArea;
        this.$refs.product.productdata = res.recom.product;
        this.$refs.programme.resolvedata = res.recom.resolve;
        res.newsAndInfo.details.map(item => {
          // console.log(item.ndate);
          let time = item.ndate;
          item.ndate = moment(Number(time) * 1000).format("YYYY.MM");
          // item.ndate = moment().add(Number(item.ndate)).format("YYYY.MM.DD");
          item.nid = moment(Number(time) * 1000).format("DD");
          // item.nid = moment(item.ndate).date();
          // console.log(item.nid);
          // item.ndata = item.ndata.slice(0, 5);
          item.ntitle = item.ntitle.slice(0, 20) + "...";
        });
        this.$refs.newsname.newsdata = res.newsAndInfo.details;
        res.newsAndInfo.basicdata.map(item => {
          item.ndate = moment(Number(item.ndate) * 1000).format("YYYY.MM.DD");
          //   item.ntitle = item.ntitle.slice(0, 17) + "...";
        });
        // this.$refs.newsname.newsdata = this.timedd;
        this.$refs.newsname.Newsname = res.newsAndInfo.basicdata;
        console.log(res.newsAndInfo.basicdata);
        return;
      }
    },
    // 首页轮播图
    async Newcarouseldata(path) {
      let { code, data } = await Newcarousel(path);
      if (code == 0) {
        // console.log(data, 333);
        // this.$refs.carouselimg.imgList = data;
        // console.log(this.imgList);

        return;
      }
    },
    // 产品
    async SugInfodata(path) {
      let { code, data } = await SugInfo(path);
      if (code == 0) {
        // console.log(data, 333);
        // this.$refs.carouselimg.imgList = data;
        // console.log(this.data);

        return;
      }
    },
    // 应用领域
    async Areainfodata(path) {
      let { code, data } = await Areainfo(path);
      if (code == 0) {
        // this.$refs.application.applicationdata = data;
        // console.log(data);

        // console.log(this.data);

        return;
      }
    },
    // 新闻
    async Newsdata(pcount) {
      let { code, data } = await News(pcount);
      // console.log(data);
      if (code == 0) {
        // this.$refs.newsname.newsdata = this.timedd;
        // console.log(this.$refs.newsname.newsdata);
        // this.newsbj = data.fpage.psrc;
        // this.items1 = data.list;
        // console.log(this.newsbj);
        // console.log(this.right_list_arr[0], 444);
        return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  font-size: 18px;
}
.home-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;

  > p {
    width: 50px;
    height: 2px;

    background: #004598;
    border-radius: 20px;
  }
}
.home-name__text {
  width: 400px;
  text-align: center;
  color: #333333;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  font-size: 1.9em;
  line-height: 59px;
  margin-top: 70px;
  // margin-top: 68px;
}
// 首屏背景轮播图
.home-bj {
  margin: 0 auto;
  width: 1900px;
  height: 744px;
}

// 推荐产品
.home-center {
  width: 100%;
  height: 1841px;
}
// 应用领域
.home-middle {
  width: 100%;
  height: 790px;

  background: #f5f5f5;
}
// 新闻资讯
.home-foot {
  width: 100%;
  height: 1007px;
}
// 更多按钮
.home-btn {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  // background: #000;
}
.el-button {
  width: 159px;
  height: 45px;
  border: 1px solid #111;
  color: #333;
  font-size: 0.875em;
  // margin-left: 880px;
  // margin-top: 50px;
}
.el-button:hover {
  background: #004598;
  color: #fff;
}
</style>