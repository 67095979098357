<template>
  <div class="traffic">
    <img class="imgaes" src="@/assets/acs/组 567@3x.jpg" alt />
    <h3>新闻资讯</h3>
    <p class="enfile">DOWNLOAD INFORMATION</p>
    <div class="news">
      <div class="news-center" v-for="(item, index) in newsdata" :key="index">
        <router-link :to="item.path" class="news-center__image">
          <img :src="item.url" alt class="image" />
          <div class="news-center__name">
            <div class="news-center__text">{{ item.ntitle }}</div>
            <div>
              <div class="news-center__time">{{ item.ndate }}</div>
              <div class="news-center__time1">{{ item.nid }}</div>
            </div>
          </div>
          <div class="news-center__title">{{ item.ndetail }}</div>
          <div class="news-center_hr"></div>
          <div :to="item.path" class="news-center__btn">
            <p>阅读详情</p>
            <i class="el-icon-arrow-right"></i>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // newssrc: "/newsdetail",
      newsdata: [
        {
          hot: "1632",
          ndate: "2022.06",
          ndetail:
            "相信很多测量人会发现，在CORS账号设置完成后，RTK手簿会显示各种解状态，比如固定解、浮动解、单点解、伪距、无解、已知等，那么你知道这些解状态所代表的含义是什么吗?出现不同解状态的原因及解决办法是怎样的呢? 哪种解状态可以正常作业呢? 看完本文，你就懂了。",
          nid: "18",
          url: require("./home/image/12.jpg"),

          ntitle:
            "CORS账号设置完成后，RTK手簿显示的各种解状态含义、原因及解决办法",
          ntype: "office",
          path: "/newsdetail/newsxw7"
        },
        {
          hot: "1632",
          ndate: "2022.06",
          ndetail:
            "普通人可能对不同轨道之间的距离很难理解。例如，国际空间站距离地球约400公里，而一些卫星(Starlink)，如星链，轨道约550公里。这样做通常是有意为之的，因为在这些轨道上的物体最终会退化它们的轨道，并在地球大气层中烧毁。然而，许多系统的轨道要高出几个数量级——例如，构成欧盟卫星导航网络主干的伽利略(Galileo)卫星。在大约23000公里的轨道上，它与悬挂较低的卫星相比有一些优势，但也有很多缺点。现在，欧盟将通过发布一套全新的低轨道导航卫星来消除这些劣势。\r\n",
          nid: "01",
          url: require("./home/image/13.jpg"),

          ntitle: "欧洲计划建立低轨卫星星座，以实现超高精度定位服务",
          ntype: "office",
          path: "/newsdetail/newsxw6"
        },
        {
          hot: "5245",
          ndate: "2022.07",
          ndetail:
            "最近有小伙伴问“全国账号，在新疆哪里覆盖，哪里不覆盖啊？”这个问题，为了方便大家了解，下面做一个详细介绍。新疆范围很大，不同地区覆盖范围不同，有的市区全部覆盖，有的则部分覆盖。",
          nid: "4",
          url: require("./home/image/14.jpg"),

          ntitle: "全国CORS账号，在新疆哪里覆盖，哪里不覆盖啊？",
          ntype: "office",
          path: "/newsdetail/newsxw4"
        },
        {
          hot: "9766",
          ndate: "2022.08",
          ndetail:
            "RTK技术经过多年的积累，已经变得越来越成熟。它的高精度、高速度、高稳定性特点，使得其被测绘、无人机、车载、安防等领域广泛应用。",
          nid: "6",
          url: require("./home/image/15.jpg"),

          ntitle: "看懂RTK定位，这一篇就够啦！",
          ntype: "office",
          path: "/newsdetail/newsxw5"
        }
      ],
      timedd: []
    };
  }
};
</script>

<style lang="scss" scoped>
.traffic {
  width: 100%;
  position: relative;
}
.imgaes {
  width: 100vw;
}
h3 {
  margin-top: 20px;
  width: 100vw;
  font-size: 18px;
  font-weight: bold;
  // line-height: 100px;
  color: #333333;
  letter-spacing: 10px;
  text-align: center;
}
//英文小标题
.enfile {
  font-size: 10px;
  margin: 10px 0;
  // font-weight: 400;
  color: #999595;

  text-align: center;
}
.btn {
  width: 100vw;
  height: 28vw;
  // background: red;
  position: absolute;
  bottom: 128vw;
  left: 0vw;
}
.btn1 {
  width: 20vw;
  height: 8vw;
  // background: red;
  position: absolute;
  bottom: 3vw;
  left: 40vw;
}
.btn2 {
  width: 20vw;
  height: 8vw;
  // background: red;
  position: absolute;
  bottom: 50vw;
  left: 40vw;
}
.news {
  padding: 0 2%;
  //   background: red;
}
// 两条新闻
.news-center {
  margin-bottom: 10vw;
}
.news-center__image {
  background: #f8f8f8;

  .news-center__ku {
    border: 1px solid #ddd;
  }
}
.image {
  width: 100%;
}
.news-center__name {
  display: flex;
  justify-content: space-between;
  // padding: 24px 23px 17px 18px;
  .news-center__text {
    // width: 333px;
    line-height: 40px;
    padding: 0 2vw;
    margin: 2vw 0;
    color: #333333;
    font-size: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -webkit-ellipsis-lastline;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .news-center__time {
    color: #004598;
    font-size: 14px;
    padding: 5px 0;
  }
  .news-center__time1 {
    color: #004598;
    text-align: right;
    font-size: 30px;
  }
}
.news-center__title {
  padding-left: 2vw;
  margin: 0 auto;
  line-height: 6vw;
  color: #999999;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: -webkit-ellipsis-lastline;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.news-center_hr {
  // margin: 12px auto;
  width: 100%;
  height: 2px;
  background: #9999;
  // color: red;
  // margin:  0;
}
.news-center__btn {
  padding: 0 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 35px;
  margin: 0 auto;
  color: #999999;
  font-size: 14px;
}

.news-center__ku:hover {
  .image {
    // overflow: hidden;
  }
}
// 最新新闻

.router-link-active {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
</style>
