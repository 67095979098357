<template>
  <div class="home-product">
    <div class="home-product__one" v-for="(item, index) in productdata" :key="index">
      <router-link :to="'/h5'+item.path">
        <div class="home-product__center">
          <el-image class="images" :src="imgurl + item.pic_h5" alt />
          <div class="home-product__title">
            <div class="home-product__typename">{{ item.typename }}</div>
            <div class="home-product__bor"></div>
            <div class="home-product__ptitlezh">{{ item.ptitlezh }}</div>
            <!-- <div class="home-product__ptitleen">{{ item.ptitleen }}</div> -->
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { Newcarousel, SugInfo } from "@/axios/modules/officialwebsite.js";
import CarouseLimg from "./Carouselimg.vue";

// import contact from "../components/contact.vue";
import { networkConfig } from "@/axios/networkConfig";
export default {
  data() {
    return {
      productdata: [],
      imgurl: networkConfig.serverUrl + "upload/",

      width: ["widthOne", "widthtwo", "widthtwo", "widthOne"]
    };
  },
  components: {
    // swiper,
    // swiperSlide,
    // contact,
  }
};
</script>

<style lang="scss" scoped>
// 推荐产品

.home-product {
  width: 100%;

  margin: 0 auto;
  // background: red;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  font-size: 10px;
}
.home-product__one {
  width: 49%;
}
.images {
  width: 100%;
  // margin: 2%;
}
.home-product__center {
  position: relative;
  width: 100%;
}
.home-product__title {
  position: absolute;
  bottom: 6px;
  left: 2%;
  color: #ffffff;
}
.home-product__bor {
  margin: 3px 0;
  border: 1px solid #ffff;
  background: #fff;

  border-radius: 4px;
  width: 30px;
}
.home-product__typename {
  font-size: 12px;
  color: #ffffff;
}
.home-product__ptitlezh {
  color: #ffffff;
  font-size: 10px;
}
</style>
