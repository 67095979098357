import { requestService } from "../request.js";
import { getCookie } from "@/utils/auth";
const VITE_APP_API = "api/";


export function Api_product(path, type) {
    const data = {
        path,
        type
    };
    return requestService({
        url: `${VITE_APP_API}prod/list2`,
        method: "post",
        data: data,
    });
}

