export function dateFtt(fmt, date) {
  //author: meizz
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
}

/*获取一定范围内自定义格式的时间*/
export function dateFttScope(num) {
  let endTime = new Date();
  let starTime = new Date(endTime).getTime() - (num ?? 0) * 24 * 3600 * 1000;

  return {
    starTime: dateFtt("yyyy-MM-dd", new Date(starTime)),
    endTime: dateFtt("yyyy-MM-dd", endTime),
  };
}
