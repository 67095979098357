<template>
  <div class="centimeter-page">
    <!-- 右侧栏 -->
    <sidebar></sidebar>
    <div class="centimeter-bottom">
      <!--海报-->
      <div class="bg-centimeter" @click="toGoodsPage">
        <img v-lazy="imgurl + hpage" alt />
        <!-- <div class="centimeter-title">
            <p class="centimeter-cn">开普勒定位服务</p>
            <p class="centimeter-level">厘米级</p>
            <p class="centimeter-en">Centimeter level high-precision service</p>
            <p class="centimeter-text">
              一款覆盖亚太地区的厘米级高精度定位服务，可以7✕24小时，为
              十亿级用户提供水平精度2厘米、高程精度5厘米的实时定位数据。
            </p>
            <el-button type="primary" style="width: 120px; margin-top: 20px" round
              >主要按钮</el-button
            >
        </div>-->
      </div>
    </div>
    <h2>智连万物</h2>
    <p class="centimeter-text1">{{ cdecr }}</p>
    <!-- icon图标 -->
    <div class="centimeter-introduce">
      <img v-lazy="imgurl + cicon" alt />
      <!-- <div
          class="centimeter-title"
          v-for="(item, index) in soft_imgs"
          :key="index"
        >
          <div
            class="centimeter-icon"
            :style="{
              'background-image': `url(${item.softv-lazy})`,
              'background-size': `100% 100%`,
            }"
          ></div>
          <div class="centimeter-introduce-text">{{ item.name3 }}</div>
      </div>-->
    </div>
    <!-- 应用领域 -->
    <div class="one">
      <img v-lazy="imgurl + cpic" alt />
      <!-- <div
          v-if="index % 2 != 0"
          style="
            display: flex;
            justify-content: flex-start;
            margin-bottom: 0.8rem;
          "
        >
          <div
            class="img-css"
            :style="{ 'background-image': `url(${a.v-lazy})` }"
          ></div>
          <div class="name-css" style="margin-left: 0.9rem">
            <p class="name-text1" style="text-align: left">{{ a.text1 }}</p>
            <p class="name-text2" style="text-align: left">{{ a.text2 }}</p>
          </div>
      </div>-->
      <!-- <div v-else style="justify-content: flex-end; display: flex">
          <div class="name-css" style="margin-right: 0.9rem; text-align: right">
            <p class="name-text1">{{ a.text1 }}</p>
            <p class="name-text2">{{ a.text2 }}</p>
          </div>
          <div
            class="img-css"
            :style="{ 'background-image': `url(${a.v-lazy})` }"
          ></div>
      </div>-->
    </div>
    <Contact />
    <h2>应用领域</h2>
    <div class="content">
      <div class="content-img" v-for="(item, index) in soft_imgs" :key="index">
        <img v-lazy="imgurl + item.pic" alt />
        <div class="centimeter-foot__text">{{ item.title }}</div>
      </div>
    </div>
    <!-- 底部链接 -->
  </div>
</template>
  
  <script>
// import MyFooter from "../components/footer.vue";
// import { computed, components } from "vue";
import Sidebar from "@/components/sidebar.vue";
import Contact from "@/components/contact.vue";
import { Serve } from "@/axios/modules/officialwebsite.js";

import { networkConfig } from "@/axios/networkConfig";
export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      cdecr: "",
      cicon: "",
      cpic: "",
      farea: "",
      hpage: "",
      soft_imgs: []
    };
  },

  mounted() {
    const title = this.$route.query.title;
    console.log(this.$route.params);
    this.Servepath(title);
  },
  components: {
    Sidebar,
    Contact
  },
  methods: {
    // contactry() {
    //   this.$router.push({ path: "/contact" });
    // },
    async Servepath(path) {
      let { code, data } = await Serve(path);
      // console.log(data);
      if (code == 0) {
        this.hpage = data.hpage;
        this.cdecr = data.cdecr;
        this.cpic = data.cpic;
        this.farea = data.farea;
        this.cicon = data.cicon;
        this.soft_imgs = JSON.parse(data.farea);
        // console.log(this.soft_imgs);
        return;
      }
    },
    // 去购买产品页面
    toGoodsPage() {
      // console.log("dfjkldjfkl");
      this.$router.push({
        name: "buygoods",
        query: {
          table: "local_list",
          path: "centimeter"
        }
      });
    }
  }
};
</script>
  <style lang="scss" scoped>
// 页面
.centimeter-page {
  // width: 1320px;
  // margin: 0 auto;

  .centimeter-bottom {
    // width: 100%;
    height: 973px;
    // margin-bottom: 186px;
    // position: relative;
  }
  .bg-centimeter {
    width: 1900px;
    // height: 100%;
    height: 973px;

    // background-size: contain;
    // background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    > img {
      width: 100%;
    }

    //   .centimeter-title {
    //     position: absolute;
    //     width: 5.6rem;

    //     min-width: 3.6rem;
    //     min-height: 1.7rem;
    //     background: transparent;
    //     right: 2rem;
    //     top: 2rem;
    //     display: flex;
    //     flex-direction: column;
    //     color: #fff;

    //     .centimeter-cn {
    //       font-size: 0.6rem;
    //       font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;

    //       color: #ffffff;
    //       line-height: 1rem;
    //       letter-spacing: 0.1rem;
    //       text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.43);
    //     }
    //     .centimeter-en {
    //       font-size: 0.16rem;
    //       font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    //       font-weight: 400;
    //       color: #ffffff;
    //       line-height: 0.27rem;
    //     }
    //     .centimeter-level {
    //       font-size: 0.3rem;
    //       font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;

    //       color: #ff6031;
    //       line-height: 0.4rem;
    //     }
    //     .centimeter-text {
    //       font-size: 0.18rem;
    //       font-family: Microsoft YaHei-Regular, Microsoft YaHei;

    //       color: #ffffff;
    //       line-height: 0.4rem;
    //     }
    //     .centimeter-btn {
    //       height: 0.3rem;
    //       width: 100%;
    //     }
    //   }
    // }
  }
  h2 {
    margin-top: 180px;
    font-size: 50px;

    font-weight: bold;
    margin-bottom: 40px;
    color: #333333;

    text-align: center;
  }
  .centimeter-text1 {
    width: 944px;
    margin: 0 auto;
    font-size: 20px;
    color: #2f2b2a;
    margin-bottom: 120px;
    text-align: center;
  }

  //智连万物
  .centimeter-introduce {
    width: 1320px;
    height: 20vh;
    margin: 0 auto;
    margin-bottom: 180px;
    > img {
      width: 100%;
    }

    // .centimeter-title {
    //   width: 18%;
    //   height: 100%;
    //   .centimeter-icon {
    //     width: 0.8rem;
    //     height: 0.8rem;
    //     margin: 0 auto;
    //     margin-bottom: 40px;
    //   }
    //   .centimeter-introduce-text {
    //     font-size: 0.18rem;
    //     font-family: Source Han Sans SC-Medium, Source Han Sans SC;
    //     color: #004598;

    //     text-align: center;
    //   }
    // }
  }

  //链接
  // .contacty-nul {
  //   width: 100%;
  //   height: 8vw;
  //   position: relative;
  //   background: red;
  // }
  // .contacty {
  //   width: 100%;
  //   height: 11vw;
  //   background-image: url("../assets/image/contacty.jpg");
  //   background-size: 100% 100%;
  //   background-repeat: no-repeat;
  //   text-align: center;
  //   font-size: 0.4rem;
  //   font-family: Microsoft YaHei;
  //   font-weight: bold;
  //   line-height: 11vw;
  //   color: #ffffff;
  //   letter-spacing: 20px;
  //   position: absolute;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }

  // 应用领域
  .one {
    width: 1320px;
    margin: 0 auto;
  }
  // .name-css {
  //   display: inline-block;
  //   vertical-align: top;
  //   flex: 1;
  //   .name-text1 {
  //     font-size: 0.2rem;
  //     font-weight: bold;
  //   }
  //   .name-text2 {
  //     margin-top: 0.2rem;
  //     font-size: 0.12rem;
  //     line-height: 0.4rem;
  //   }
  // }
  // .img-css {
  //   height: 3rem;
  //   flex: 1;
  //   background-size: contain;
  //   background-repeat: no-repeat;
  // }
}
.content {
  width: 1320px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 180px;
  // padding: 5px;
  height: auto;
  position: relative;
  .content-img {
    position: relative;
    .centimeter-foot__text {
      font-size: 24px;
      // font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      color: #fff;
      line-height: 40px;

      z-index: 9998;
      position: absolute;
      left: 16px;
      bottom: 20px;
    }
  }
}
/* 如果最后一行的图片不够填满整行, 那么就让添加的这个元素填充剩余区域*/
.content::after {
  content: "";
  flex-grow: 99999;
}
.content > div {
  // /* 在一行中自动去填充剩余区域 */
  // flex-grow: 1;
  // margin: 8px;
  // height: 390px;
  // position: relative;
}
.content > div > img {
  height: 426px;
  width: 426px;
  padding: 4px 6px;
}
</style>