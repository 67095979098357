<template>
  <div>
    <div class="foot-imgbtn">
      <div class="foot-imgbtn1"></div>
      <div class="foot-imgbtn2" @click="gocontact"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    gocontact() {
      this.$router.push({ path: "/contact" });
    }
  }
};
</script>
<style lang="scss" scoped>
.foot-imgbtn {
  padding: 80px 0;
  display: flex;
  width: 100%;
  height: 20vh;
  justify-content: space-between;
  .foot-imgbtn1 {
    width: 49%;
    background: url(../assets/fot3.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .foot-imgbtn2 {
    width: 49%;
    background: url(../assets/fot4.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>