<template>
  <div class="DialogChange">
    <el-dialog
      title="修改密码"
      :visible.sync="dialogTableVisible"
      width="20%"
      :show-close="false"
      :close-on-click-modal="false"
      style="margin-top: 220px"
    >
      <el-form ref="form" label-width="80px" :rules="rules" :model="user">
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="user.newPassword"
            placeholder="请输入新密码"
            type="password"
          />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            v-model="user.confirmPassword"
            placeholder="请确认密码"
            type="password"
          />
        </el-form-item>
        <el-form-item>
          <div class="dialog-foot">
            <el-button @click="dialogTableVisible = false">取 消</el-button>
            <el-button type="primary" @click="Passwordmodification"
              >修改</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.user.newPassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };

    return {
      // 版本号
      version: "3.2.1",
      dialogTableVisible: false,
      currentUser: {},
      currentUserId: undefined,
      user: {
        newPassword: undefined,
        confirmPassword: undefined,
      },

      // 表单校验
      rules: {
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          { min: 6, max: 18, message: "长度在 6 到 18个字符", trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { required: true, validator: equalToPassword, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    Passwordmodification() {
      if (this.user.newPassword == this.user.confirmPassword) {
        if (this.user.newPassword.length >= 6) {
          this.$emit(
            "Passwordmodification",
            this.user.newPassword,
            this.user.confirmPassword
          );
        }
        // console.log(333);

        // this.dialogTableVisible = false;
      }
    },
  },
};
</script>

<style lang="scss">
.el-button--primary {
  background: #004598;
}
.dialog-foot {
  display: flex;
  justify-content: space-between;
  .el-button {
    width: 180px;
  }
  .el-button--primary {
    background: #004598;
  }
}
</style>
