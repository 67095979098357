<template>
  <div class="news">
    <div class="news-center" v-for="(item, index) in newsdata" :key="index">
      <router-link :to="item.path">
        <div class="news-center__image">
          <el-image :src="imgurl + item.pic_h5" alt class="image" />
          <div class="news-center__name">
            <div class="news-center__text">{{ item.ntitle }}</div>
            <div>
              <div class="news-center__time">{{ item.ndate }}</div>
              <div class="news-center__time1">{{ item.nid }}</div>
            </div>
          </div>
          <div class="news-center__title">{{ item.ndetail }}</div>
          <div class="news-center_hr"></div>
          <div class="news-center__btn">
            <p>阅读详情</p>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </router-link>
    </div>

    <div class="news-detaill">
      <div v-for="(item, index) in Newsname" :key="index">
        <router-link :to="item.path" class="news-detaill__name">
          {{
          item.ntitle
          }}
        </router-link>
        <p>{{ item.ndate }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { networkConfig } from "@/axios/networkConfig";
import { News, NewsDetail } from "@/axios/modules/officialwebsite.js";
export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",

      // newssrc: "/newsdetail",
      newsdata: [],
      timedd: [],
      Newsname: [
        {
          ndate: "2022-4-5",
          path: "/h5/newsdetail/newsxw7",
          ntitle:
            "CORS账号设置完成后，RTK手簿显示的各种解状态含义、原因及解决办法"
        },
        {
          ndate: "2022-4-5",
          path: "/h5/newsdetail/newsxw6",
          ntitle: "欧洲计划建立低轨卫星星座，以实现超高精度定位服务"
        },
        {
          ndate: "2022-4-5",
          path: "/h5/newsdetail/newsxw5",
          ntitle: "全国CORS账号，在新疆哪里覆盖，哪里不覆盖啊？"
        },
        {
          ndate: "2022-4-5",
          path: "/h5/newsdetail/newsxw4",
          ntitle: "看懂RTK定位，这一篇就够啦！"
        },

        {
          ndate: "2022-4-5",
          path: "/h5/newsdetail/newsxw1",
          ntitle: "【技术】RTK如何获得固定解，怎么设置？新手必看！"
        }
      ]
    };
  },

  mounted() {},
  methods: {
    // 页面数据接口
    async Newsdata() {
      let { code, data } = await News();
      var moment = require("moment");
      // console.log(data);
      if (code == 0) {
        data.list.map(item => {
          item.ndate = moment(Number(item.ndate) * 1000).format("YYYY.MM.DD");
          //   item.ntitle = item.ntitle.slice(0, 17) + "...";
        });
        this.Newsname = data.list;
        // console.log(this.Newsname);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.news {
  width: 100%;

  margin: 0 auto;
  //   background: red;
}
// 两条新闻
.news-center {
  margin-bottom: 10vw;
}
.news-center__image {
  background: #f8f8f8;

  .news-center__ku {
    border: 1px solid #ddd;
  }
}
.image {
  width: 100%;
}
.news-center__name {
  display: flex;
  justify-content: space-between;
  // padding: 24px 23px 17px 18px;
  .news-center__text {
    // width: 333px;
    line-height: 40px;
    padding: 0 2vw;
    margin: 2vw 0;
    color: #333333;
    font-size: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -webkit-ellipsis-lastline;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .news-center__time {
    color: #004598;
    font-size: 14px;
    padding: 5px 0;
  }
  .news-center__time1 {
    color: #004598;
    text-align: right;
    font-size: 30px;
  }
}
.news-center__title {
  padding-left: 2vw;
  margin: 0 auto;
  line-height: 6vw;
  color: #999999;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: -webkit-ellipsis-lastline;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.news-center_hr {
  // margin: 12px auto;
  width: 100%;
  height: 2px;
  background: #9999;
  // color: red;
  // margin:  0;
}
.news-center__btn {
  padding: 0 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 35px;
  margin: 0 auto;
  color: #999999;
  font-size: 14px;
}

.news-center__ku:hover {
  .image {
    // overflow: hidden;
  }
}
// 最新新闻
.news-detaill {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  > div {
    padding: 2%;
    // margin: 0 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;

    .news-detaill__name {
      color: #333333;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      margin: 0 2%;
    }
    > p {
      margin: 0 2%;
      color: #999999;
      font-size: 14px;
    }
    .news-detaill__name:hover {
      color: #004598;
    }
  }
}
.router-link-active {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
</style>
