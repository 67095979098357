<template>
  <div class="xw">
    <div class="xw-center">
      <h2>【技术】RTK如何获得固定解，怎么设置？新手必看！</h2>
      <hr />
      <div class="xw-time">
        <div class="xw-time__text">发布时间:</div>
        <div class="xw-time__text">{{ timedata }}</div>
        <div class="xw-time__text">浏览量:</div>
        <div class="xw-time__text">{{ hotdata }}次</div>
      </div>
      <p>RTK进行测量前，默认前提:主机处于固定解的状态，于入门的新手，如何化繁为简，轻松获得固定解？</p>
      <p>首选网络CORS作业模式，一键登录，一键固定！在CORS网覆盖范围内，不用架设基站，用单移动站作业就行了。</p>
      <p>怎么用网络CORS作业呢？小科教您两招，操作可简单了，跟着学，立马上手~</p>
      <p>开普勒工具</p>
      <h4>CORS绑定账号</h4>
      <p>CORS账号直接内置到主机或手簿，这种情况怎么设置连接？</p>
      <p>不用输入IP、端口、账号、密码等信息，一键登录，一键固定。</p>
      <p>
        想要实现这种
        <span>“秒固定”</span> 连接，两个步骤设置！
      </p>
      <h4>1）连接蓝牙</h4>
      <p>
        手簿联网后，打开工程之星5.0主菜单界面，点击点击
        <span>“配置”→“仪器连接”→“扫描”→“选中主机号”→“连接”</span>
        ，语音播报:
        <span>蓝牙连接成功。</span>
      </p>
      <h4>01</h4>
      <img src="../../assets/newsimg/xw5/img1.png" alt />
      <h4>02</h4>
      <img src="../../assets/newsimg/xw5/img2.png" alt />
      <h4>03</h4>
      <img src="../../assets/newsimg/xw5/img3.png" alt />
      <h4>▲图1、图2、图3</h4>
      <h4>2）登录账号</h4>
      <p>
        工程之星5.0主菜单界面，点击
        <span>“星”</span>悬浮图标→下拉菜单选
        <span>“秒”</span>→点击
        <span>“确定”</span>→跳转到登录界面，点击
        <span>“连接”，</span>页面将提示
        <span>“登录成功”，</span>
        返回主菜单后，底部菜单栏立即显示固定解。
      </p>

      <img style="width: 660px" src="../../assets/newsimg/xw5/img4.png" alt />
      <h4>▲图1、图2、图3</h4>
      <h4>注意事项</h4>
      <p>
        1）点击
        <span>“秒”</span>图标后，主机将切换成
        <span>“移动站数据链:手机网络-高精度位置服务”</span>模式。
      </p>
      <p>
        2）
        <span>“登录成功”</span>后，页面显示服务关联的
        <span>“机号”、“到期时间”、</span>使用的
        <span>“坐标系统”、“挂载点”</span>等信息，如需切换坐标系统和挂载点，可先断开连接，选择需要的坐标系统和挂载点后，再点击
        <span>“连接”。</span>
      </p>
      <p>
        3）选择该模式需要保证手簿上网正常，如手簿
        <span>“不能上网”</span>
        ，连接时会提示
        <span>“手簿当前没有网络连接，请检查”</span> ，如图7所示。
      </p>
      <p>
        4）如果手簿或RTK主机未开通高精度位置服务，连接界面会提示
        <span>“无账号”</span>，如图8所示:
      </p>
      <img style="width: 660px" src="../../assets/newsimg/xw5/img4.png" alt />
      <h4>▲图7、图8</h4>
      <p>*2</p>
      <h4>CORS通用账号</h4>
      <p>CORS通用账号服务，拥有独立的账号、密码，可以输入到任何一台手簿或主机上使用，这又怎么连接呢？连接步骤和普通的CORS一样，具体如下:</p>
      <h4>1）蓝牙连接</h4>
      <p>
        工程之星5.0主菜单，点击
        <span>“配置”→“仪器连接”→“扫描”→“选中主机号”→“连接”</span>
        ，语音播报:
        <span>蓝牙连接成功</span>。
      </p>
      <h4>01</h4>
      <img src="../../assets/newsimg/xw5/img6.png" alt />
      <h4>02</h4>
      <img src="../../assets/newsimg/xw5/img7.png" alt />
      <h4>03</h4>
      <img src="../../assets/newsimg/xw5/img8.png" alt />
      <p>▲图12、图13、图14</p>
      <h4>3）CORS连接设置</h4>
      <p>
        点击
        <span>“增加”→“输入账号信息”，</span>
        信息如下:
      </p>
      <p>选择服务器:120.253.226.97</p>
      <p>名称:自定义</p>
      <p>端口:8001（CGCS2000）、8002 （WGS84 ）</p>
      <p>账号、密码:由开通者提供</p>
      <p>接入点选择:点击刷新，接入点选择。（常用接入点:三星“RTCM33_GRC”、四星“RTCM33_GRCE”、五星“RTCM33_GRCEJ”）</p>
      <p>
        输入完成后点击
        <span>“确定”，</span>，点击
        <span>“连接”，</span>提示
        <span>“登录成功”，</span>说明已经连上
        <span>“CORS服务器”</span>了，返回主菜单，底部菜单栏显示固定解。
      </p>
      <h4>04</h4>
      <img src="../../assets/newsimg/xw5/img9.png" alt />
      <h4>05</h4>
      <img src="../../assets/newsimg/xw5/img10.png" alt />
      <h4>06</h4>
      <img src="../../assets/newsimg/xw5/img11.png" alt />
      <p>▲图15、图16、图17</p>
      <p>注:初次使用时需要按上述步骤设置，可打开自动连接，后续在不改变账号信息的情况下，开机后，“CORS连接”会自动登录。</p>
      <p>*3</p>
      <p>CORS常见问题</p>
      <p>当我们使用网络CORS作业时，遇到连接失败、单点解等问题，该如何解决呢？以下是小科整理的一些问题，若有其他技术问题，欢迎朋友们拨打科力达热线020-22131700咨询。</p>
      <p>问题1:连接CORS时，手簿上提示连接失败是怎么回事？</p>
      <p>解决办法:</p>
      <p>1）检查手簿上是否安装手机卡或者是否连接手机WIFI。</p>
      <p>2）检查数据链选择的是否为手机网络。</p>
      <p>3）检查CORS账号是否过期。</p>
      <p>4）询问CORS站是否正常。</p>
      <p>
        问题2:手簿上安装了专门的流量卡，但是手簿连不上网，也连接不 上
        CORS是怎么回事 ？
      </p>
      <p>解决办法:</p>
      <p>
        1）把流量卡放入手簿之后，检查 手簿
        屏幕的右上角是否显示两个4G，如果显示一个4G需要进行如下设置: 设置 → 更多
        → 移动网络 → 接入点名称（APN） → 新建APN（右上角） →
        名称处输入APN，APN处输入cmiot → 保存（右上角） → 就可以上网。
      </p>
      <p>2）如果显示两个4G手簿还不能上网，就要返厂维修。</p>
      <p>问题3:手簿连接CORS显示登陆成功，但是手簿显示单点解是怎么回事？</p>
      <p>解决办法:</p>
      <p>1）检查仪器的工作模式是否正确，应该是移动站蓝牙模式，如果不是，点击配置→仪器设置→移动站设置→数据链选择手机网络。</p>
      <p>2）检查接入点是否正确，可以更换其他接入点试试。</p>
      <p>3 ）询 问CORS站是否正常。</p>

      <div class="xw-source">（来源:网络）</div>
      <hr />
      <div style="display: flex">
        <p>上一篇:</p>
        <router-link
          v-if="lastNews.path !== undefined"
          :to="lastNews.path === '/' ? '' : lastNews.path"
        >{{ lastNews.ntitle }}</router-link>
      </div>
      <div style="display: flex">
        <p>下一篇:</p>
        <router-link
          v-if="nextNews.path !== undefined"
          :to="nextNews.path === '/' ? '' : nextNews.path"
        >{{ nextNews.ntitle }}</router-link>
      </div>
    </div>
    <div class="xw-newest">
      <Newset ref="newsetdata" />
    </div>
  </div>
</template>

<script>
import { networkConfig } from "../../axios/networkConfig";
import Newset from "./component/newest.vue";
import { News, NewsDetail } from "../../axios/modules/officialwebsite.js";
export default {
  data() {
    return {
      to: "",
      timedata: "",
      hotdata: "",
      lastNews: {},
      nextNews: {},
      imgurl: networkConfig.serverUrl + "upload/",
      Newsname: []
    };
  },

  mounted() {
    this.Newsdata();
    this.NewsDetaildata("newsxw1");
  },
  methods: {
    // 页面数据接口
    async Newsdata() {
      let { code, data } = await News();
      var moment = require("moment");
      // console.log(data);
      if (code == 0) {
        data.list.map(item => {
          item.ndate = moment(Number(item.ndate) * 1000).format("YYYY-MM-DD");
          item.ntitle = item.ntitle.slice(0, 17) + "...";
        });
        this.$refs.newsetdata.Newsname = data.list;
        // console.log(this.$refs.newsetdata);
      }
    },
    // 上一篇下一篇
    async NewsDetaildata(path) {
      let { code, data } = await NewsDetail(path);
      // console.log(data);
      var moment = require("moment");
      if (code == 0) {
        // console.log(data);
        this.lastNews = data.lastNews;
        this.nextNews = data.nextNews;

        this.timedata = moment(Number(data.detail.ndate) * 1000).format(
          "YYYY-MM-DD"
        );
        this.hotdata = data.detail.hot;
      }
    }
  },
  components: {
    Newset
  }
};
</script>

<style lang="scss" scoped>
.xw {
  width: 1320px;
  margin: 0 auto;
  margin-top: 134px;

  display: flex;
}
/* 新闻内容 */
.xw-center {
  width: 912px;
  padding: 16px;
  min-height: 80vh;
  //   background: pink;
  text-align: center;

  //   > h2 {
  //     margin: 28px 0;
  //   }
  > hr {
    margin: 28px 0;
    border: 0.5px solid #e6e6e6;
  }
  > p {
    line-height: 32px;
    font-size: 16px;
    text-align: left;
    // text-indent: 2em;
    padding: 20px 0;
    > span {
      font-weight: bold;
    }
  }
  > img {
    width: 430px;
  }
  .xw-source {
    text-align: right;
    color: #999999;

    font-size: 14px;
  }
  > h4 {
    text-align: left;
    padding: 30px 0;
  }
}
.xw-time {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .xw-time__text {
    padding: 3px;
    font-size: 14px;

    color: #666666;
  }
}
.xw-newest {
  // position: fixed;
  right: 23vw;
  width: 365px;
  //   padding: 16px;
  height: 500px;
  margin: 16px;
  //   background: red;
  border: 1px solid #cccccc;
}
.router-link {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
</style>