<template>
  <div class="authentication-page">
    <div style="width: 1320px; margin: 0 auto">
      <el-button
        @click="gotogeren"
        type="primary"
        size="mini"
        class="authentication-return"
        >&lt;返回</el-button
      >
    </div>
    <sidebar></sidebar>
    <div class="authentication-center">
      <div class="authentication-head">
        <div
          v-for="(item, index) in headdata"
          :key="index"
          @click="onchange(index)"
          :class="[
            { active: authenticationhead_active == item.name },
            'authentication-headname',
          ]"
        >
          <div
            class="head-center"
            @click="authenticationhead_active = item.name"
          >
            <img :src="item.iconi" alt="" />
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
      <div v-if="authenticationdata == true">
        <Authenticationfrom1 />
      </div>
      <div v-if="authenticationdata == false">
        <Authenticationfrom2 />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/sidebar.vue";
import Authenticationfrom1 from "./authenticationfrom1.vue";
import Authenticationfrom2 from "./authenticationfrom2.vue";
export default {
  data() {
    return {
      checked: false,
      agree: false,
      authenticationdata: true,
      dialogTableVisible: false,
      authenticationhead_active: "个人认证",
      headdata: [
        {
          iconi: require("../../assets/personal_center/icon1.png"),
          name: "个人认证",
        },
        {
          iconi: require("../../assets/personal_center/icon2.png"),
          name: "企业认证",
        },
      ],
    };
  },
  methods: {
    gotogeren() {
      this.$router.push({ path: "/personal_center/index" });
    },
    onchange(index) {
      if (index == 0) {
        this.authenticationdata = true;
        this.headdata[
          index
        ].iconi = require("../../assets/personal_center/icon1.png");
        this.headdata[1].iconi = require("../../assets/personal_center/icon2.png");
      } else if (index == 1) {
        this.authenticationdata = false;
        this.headdata[
          index
        ].iconi = require("../../assets/personal_center/icon3.png");
        this.headdata[0].iconi = require("../../assets/personal_center/icon.png");
      }
    },
    // 获取手机验证码
    handleCaptcha(form) {
      this.$refs[form].validateField("phoneNum", async (valid) => {
        if (!valid) {
          // 获取验证码
          //   captcha(this.form.phoneNum)
          // 开启计时
          this.codeCd = false;
          const timer = setInterval(() => {
            this.long--;
            if (this.long <= 0) {
              this.long = 60;
              this.codeCd = false;
              clearInterval(timer);
            }
          }, 1000);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSubmit() {
      console.log("submit!");
    },
  },
  components: {
    Authenticationfrom1,
    Authenticationfrom2,
    //MyFooter,
    Sidebar,
  },
};
</script>

<style lang="scss" scoped>
.authentication-page {
  width: 100%;
  // height: 100%;

  background: #f5f5f5;
}
.authentication-return {
  font-size: 14px;
  line-height: 26px;
  // width: 1320px;
  margin: 20px auto;
  margin-top: 120px;
  // line-height: 80px;
}
.authentication-center {
  width: 1320px;
  // height: 690px;

  margin: 0 auto;

  background: #fff;
}
.authentication-head {
  display: flex;
  .active.authentication-headname {
    color: #fff;
    background: #004598;
  }
  .authentication-headname {
    flex: 1;
    border: 1px solid #cccccc;
    font-size: 16px;
    text-align: center;
    line-height: 50px;

    color: #afafaf;
    background: #f0f0f0;
    .head-center {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      > img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}
// 返回按钮
.el-button--primary {
  width: 66px;
  height: 25px;

  line-height: 0;
  background: #004598;

  border-radius: 2px 2px 2px 2px;
}
</style>