<template>
  <div class="logistics-page">
    <!-- 右侧栏 -->
    <sidebar></sidebar>
    <!--海报-->
    <div class="bg-logistics">
      <div class="logistics-title">
        <p class="logistics-cn">Smart Logistics</p>
        <p class="logistics-en">智慧物流解决方案</p>
      </div>
    </div>
    <h2>行业痛点</h2>
    <p class="text1">INDUSTRY PAIN POINTS</p>

    <div class="panorama">
      <div v-for="item in soft_imgs" :key="item.id">
        <div
          class="panorama-logistics"
          :style="{
            'background-image': `url(${item.softsrc})`,
            'background-size': `100% 100%`,
          }"
        >
          <div class="logistics-l">
            <p class="logistics-texts">{{ item.name2 }}</p>
            <p class="logistics-text">
              {{ item.name3 }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 方案框架 -->
    <h2>方案框架</h2>
    <p class="text1">INDUSTRY PAIN POINTS</p>
    <!-- 背景3 -->
    <div class="logistics-page">
      <div class="bj3-logistics">
        <div class="advantage"></div>
      </div>
    </div>

    <div class="contacty" @click="contactry">联系我们，了解更多></div>

    <h2>适用物流类型</h2>
    <p class="text1">INDUSTRY PAIN POINTS</p>
    <div class="logistics-page2">
      <div class="bj4-logistics">
        <div></div>
      </div>
    </div>
    <!-- 底部链接 -->
    <div class="fixed-footer">
      <MyFooter />
    </div>
  </div>
</template>

<script>
// import MyFooter from "../components/footer.vue";
// import { computed, components } from "vue";
import Sidebar from "../components/sidebar.vue";

import { networkConfig } from "../axios/networkConfig";
export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      soft_imgs: [
        {
          id: "1",

          name2: "平台难以支撑高并发接入",
          name3:
            "物流平台涉及海量数据同时并发接入，包括车辆状态信息、位置信息、环境感知，人车行为数据等；物流企业随着业务发展逐渐增大，难以承受高并发冲击。",
          softsrc: require("../assets/image/logistics/bj2_traffic.png"),
        },
        {
          id: "2",
          name2: "缺乏自主应用，开发效率低",
          name3:
            "物流企业缺乏或者开发自主应用成本高、效率低，无法实现快速开发、易于部署，简单管理而无须考虑基础设施、数据处理的能力。",
          softsrc: require("../assets/image/logistics/bj2_traffic.png"),
        },
        {
          id: "3",
          price: "$2000",
          name2: "海量数据难以挖掘",
          name3:
            "物流管理应用汇聚了海量数据，传统物流企业缺乏有效手段来充分挖掘数据的价值，让数据指导企业进行业务创新和提升运营效率。",
          softsrc: require("../assets/image/logistics/bj2_traffic.png"),
        },
        {
          id: "4",
          price: "$2000",
          name2: "商业决策缺乏数据依据",
          name3:
            "无法对物流企业进行决策支持，无法满足物流轨迹分析，需要生命周期的跟踪与分析，运输路径优化、运营分析等。",
          softsrc: require("../assets/image/logistics/bj2_traffic.png"),
        },
      ],
    };
  },
  components: {
    // MyFooter,
    Sidebar,
  },
  methods: {
    contactry() {
      this.$router.push({ path: "/contact" });
    },
  },
};
</script>
<style lang="scss" scoped>
// 头部背景
.bg-logistics {
  width: 100%;
  height: 100%;
  min-height: 5.8rem;
  margin-bottom: 0rem;
  background-image: url("../assets/image/logistics/bj1_traffic.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // margin-top: 0.7rem;
  .logistics-title {
    position: absolute;
    min-width: 3.6rem;
    min-height: 1.7rem;
    background: transparent;
    left: 4rem;
    top: 2.7rem;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 0.8rem;
    .logistics-cn {
      font-size: 0.9rem;
      font-weight: bold;
      line-height: 0.8rem;
      letter-spacing: 0.2rem;
      flex: 1;
      color: rgba(255, 255, 255, 0.9);
      margin-bottom: 0.5rem;
    }
    .logistics-en {
      font-size: 0.3rem;
      line-height: 0.18rem;
      color: #333333;
      letter-spacing: 0.1rem;
      flex: 1;
      color: #fff;
    }
    .text {
      font-size: 0.14rem;

      font-weight: 400;
      line-height: 0.28rem;
      color: #fff;
      flex: 1;
    }
  }
}

h2 {
  font-size: 0.3rem;

  font-weight: bold;
  line-height: 1rem;
  color: #333333;
  letter-spacing: 0.1rem;
  text-align: center;
  margin-top: 0.3rem;
}
.text1 {
  font-size: 0.2rem;

  color: #999595;
  letter-spacing: 0.1rem;
  text-align: center;
  margin-bottom: 0.3rem;
}
//行业痛点
.panorama {
  margin: 0.2rem 1.3rem;

  padding: 0.5rem;
  margin: 1rem, auto;

  .panorama-logistics {
    width: 100%;
    height: 1.8rem;
    border-radius: 0.2rem;
    // box-shadow: 0px 05px 15px rgba(0, 0, 0, 0.16);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    // flex-direction: column;
    align-items: center;
    margin-bottom: 0.7rem;
    .logistics-l {
      width: 100%;
      padding: 0.4rem;
      // border-radius: 0px 0px 0px 0.5rem;
      // margin: 0.3rem 0px 0rem 0.8rem;

      .logistics-texts {
        // display: inline-block;
        color: #fff;
        font-size: 0.18rem;
        margin-bottom: 0.3rem;
      }
      .logistics-text {
        color: #fff;
        font-size: 0.16rem;
        // display: inline-block;
        // margin: 0.18rem 0px 0.34rem 0rem;
      }
    }
  }
  //   }
  // }
}
//方案框架
.advantage {
  margin: 0.2rem auto;
  min-height: 5rem;
  padding: 0.1rem;
  display: flex;
  justify-content: space-between;
  width: 4rem;
  height: 1rem;
  background-color: rgba(0, 0, 0, 0.3);

  ul {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    li {
      list-style-type: none;
      margin-right: 0.2rem;
      img {
        width: 2.5rem;
        margin-left: 25%;
      }
      .title1 {
        font-size: 0.2rem;

        font-weight: bold;
        line-height: 0.4rem;
        color: #333333;
        text-align: center;
      }
      .title2 {
        font-size: 0.14rem;

        font-weight: 400;
        line-height: 0.28rem;
        color: #333333;
        text-align: center;
      }
    }
  }
}
.bj3-logistics {
  width: 100%;
  min-width: 9.4rem;
  height: 100%;
  min-height: 5.8rem;
  margin-bottom: 0rem;
  background-image: url("../assets/image/logistics/bj3_traffic.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 0.7rem;
}
.logistics-page {
  width: 100%;
}
.logistics-page2 {
  width: 10rem;
  height: 7.3rem;
  margin: auto;
  position: relative;
  margin-bottom: 2rem;
}
.bj4-logistics {
  width: 10rem;
  height: 7.3rem;
  position: absolute;
  top: 1rem;
  background-image: url("../assets/image/logistics/bj4_traffic.jpg");
  background-size: 100% 100%;
}
//链接
.contacty {
  background-image: url("../assets/image/contacty.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 3.62rem;
  text-align: center;
  font-size: 0.4rem;

  font-weight: bold;
  line-height: 2.7rem;
  color: #ffffff;
  letter-spacing: 20px;
}

.fixed-footer {
  width: 100%;
  min-height: 1rem;
  background-color: rgba(51, 51, 51, 1);
}
</style>