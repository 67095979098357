<template>
  <div class="traffic">
    <div class="xw-center">
      <h2>CORS账号设置完成后，RTK手簿显示的各种解状态含义、原因及解决办法</h2>
      <hr />
      <div class="xw-time">
        <div class="xw-time__text">发布时间:</div>
        <div class="xw-time__text">2022-09-26</div>
        <div class="xw-time__text">浏览量:</div>
        <div class="xw-time__text">162次</div>
      </div>
      <img src="./xw7/img1.jpg" alt />
      <div class="xw-source">（来源：网络）</div>
      <hr />
    </div>
    <div>
      <router-link to class="btn"></router-link>
    </div>
    <div>
      <router-link to="/newsdetail/newsxw6" class="btn3">上一篇: 欧洲计划建立低轨卫星星座，以实现超高精度定位服务</router-link>
    </div>
    <!-- <div class="btn1"></div>
    <div class="btn2"></div>-->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.traffic {
  margin-top: 20vw;
  position: relative;
  padding: 0 2%;
}
.imgaes {
  width: 100%;
}
.btn {
  font-size: 12px;
  width: 100%;
  height: 8vw;
  background: #fff;
  // position: absolute;
  // bottom: 5vw;
  // left: 0;
}
.btn3 {
  font-size: 12px;
  width: 100%;
  height: 15vw;
  background: #fff;
  // position: absolute;
  // bottom: 13vw;
  // left: 0;
}
// .btn1 {
//   width: 30vw;
//   height: 8vw;
//    // background: red;: red;
//   position: absolute;
//   bottom: 3vw;
//   left: 40vw;
// }
// .btn2 {
//   width: 30vw;
//   height: 8vw;
//    // background: red;: red;
//   position: absolute;
//   bottom: 55vw;
//   left: 40vw;
// }
.xw-center {
  width: 100%;

  min-height: 80vh;
  //   background: pink;
  text-align: center;

  //   > h2 {
  //     margin: 28px 0;
  //   }
  > hr {
    margin: 40px 0;
    border: 0.5px solid #e6e6e6;
  }
  > p {
    line-height: 32px;
    font-size: 16px;
    text-align: left;
    text-indent: 2em;
    padding: 20px 0;
  }
  > img {
    width: 100%;
  }
  .xw-source {
    text-align: right;
    color: #999999;

    font-size: 14px;
  }
  > h3 {
    text-align: left;
    padding: 15px 0;
  }
  > h4 {
    text-align: left;
    padding: 15px 0;
  }
}
.xw-time {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .xw-time__text {
    padding: 3px;
    font-size: 14px;

    color: #666666;
  }
}
.xw-newest {
  // position: fixed;
  right: 23vw;
  width: 365px;
  //   padding: 16px;
  height: 500px;
  margin: 16px;
  //   background: red;
  border: 1px solid #cccccc;
}
.router-link-active {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
.active.right-row--a {
  color: #2271f7;
}
</style>
