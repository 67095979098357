<template>
  <div class="product">
    <div class="product-head"></div>
    <PageStyle1 v-if="qtype==1" ref="pagestyle" />
    <PageStyle2 v-if="qtype==2" ref="pagestyle" />
    <PageStyle3 v-if="qtype==3" ref="pagestyle" />
    <PageStyle4 v-if="qtype==4" ref="pagestyle" />
  </div>
</template>

<script>
import { Api_product } from "../../axios/modules/product.js";
import router from "@/router/index.js";

import { PageStyle1, PageStyle2, PageStyle3, PageStyle4 } from "./index.js";

export default {
  data() {
    return {
      qpath: "",
      qtype: null
    };
  },
  components: { PageStyle1, PageStyle2, PageStyle3, PageStyle4 },

  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      this.qpath = this.$route.query.path;
      this.qtype = this.$route.query.type;
      this.Kinnamedata();
    }
  },
  mounted() {
    this.qpath = this.$route.query.path;
    this.qtype = this.$route.query.type;
    // console.log(this.$route.query.path, "23333____________");
    this.Kinnamedata();
  },
  methods: {
    async Kinnamedata() {
      let { code, data } = await Api_product(this.qpath, this.qtype);

      if (code == 0) {
        this.$refs.pagestyle.stationData(code, data);
        return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.product {
  width: 1320px;
  margin: 0 auto;
  .product-head {
    height: 100px;
  }
}
</style>
