import { render, staticRenderFns } from "./newsxw6.vue?vue&type=template&id=35edf2fc&scoped=true&"
import script from "./newsxw6.vue?vue&type=script&lang=js&"
export * from "./newsxw6.vue?vue&type=script&lang=js&"
import style0 from "./newsxw6.vue?vue&type=style&index=0&id=35edf2fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35edf2fc",
  null
  
)

export default component.exports