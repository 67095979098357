<template>
  <div class="head">
    <!-- <div class="head-path">111</div> -->
    <div class="head-name">
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "GGA",
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  width: 100%;
  height: 10vh;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  .head-path {
    // margin-right: auto;
  }

  .head-name {
  }
}
</style>
