<template>
  <!-- 右侧联系栏 -->
  <div class="contact-page">
    <!-- <sidebar></sidebar> -->
    <!-- 回到顶部 -->

    <!--海报-->
    <img class="images" src="@/assets/acs/组 623@3x.jpg" alt />

    <!--表单-->
    <div class="bg-form">
      <div class="form-cemter">
        <div>
          <h3>留下您的信息</h3>
          <p class="remind">请填写您的电话，将有助于我们尽快与您获得联系，帮您解决问题，也欢迎您拨打</p>
          <p class="tel">027-87888720</p>
        </div>

        <el-form :model="formlabelalign" :rules="rules" ref="formlabelalign" style>
          <div class="contact-form">
            <div>
              <el-form-item prop="fname" label="您的姓名">
                <el-input v-model="formlabelalign.fname" />
              </el-form-item>
              <el-form-item prop="fcorname" label="您的公司名称">
                <el-input v-model="formlabelalign.fcorname" />
              </el-form-item>
              <el-form-item prop="fmail" label="联系邮箱">
                <el-input v-model="formlabelalign.fmail" />
              </el-form-item>
            </div>
            <div>
              <el-form-item prop="ftel" label="您的手机号码">
                <el-input v-model="formlabelalign.ftel" />
              </el-form-item>
              <el-form-item prop="faddress" label="您的所在地址">
                <el-input v-model="formlabelalign.faddress" />
              </el-form-item>
              <el-form-item prop="fbeint" label="感兴趣的产品">
                <el-input v-model="formlabelalign.fbeint" />
              </el-form-item>
            </div>
          </div>
          <div style="text-align: center; flex: 1">
            <el-button type="primary" @click="submitForm" class="bg-btn">提交留言</el-button>
          </div>
        </el-form>
      </div>
    </div>

    <!-- 底部链接 -->
    <!-- <div class="fixed-footer">
      <MyFooter />
    </div>-->
  </div>
</template>

<script>
//  import MyFooter from "../components/footer.vue";
import { Psuggest } from "@/axios/modules/officialwebsite.js";
import Sidebar from "@/components/sidebar.vue";

export default {
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    var checkEmail = (rule, value, callback) => {
      const regEmail = /^\w+@\w+(\.\w+)+$/;
      if (regEmail.test(value)) {
        // 合法邮箱
        return callback();
      }
      callback(new Error("请输入合法邮箱"));
    };

    return {
      formlabelalign: {
        fname: "",
        ftel: "",
        fcorname: "",
        faddress: "",
        fmail: "",
        fbeint: ""
      },

      rules: {
        fname: [
          { required: true, message: "请输入您的姓名", trigger: "blur" }
          // { required: true, validator: equalToPassword, trigger: "blur" },

          // { min: 1, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        ftel: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          { required: true, validator: equalToPassword, trigger: "blur" }
        ],
        fmail: [
          { message: "请输入邮箱", trigger: "blur" },
          { validator: checkEmail, trigger: "blur" }
        ]
      }
    };
  },
  components: {
    //  MyFooter,
    // Sidebar,
  },
  methods: {
    // 数据接口
    async Psuggestdata(fname, ftel, fcorname, faddress, fmail, fbeint) {
      let { code } = await Psuggest(
        fname,
        ftel,
        fcorname,
        faddress,
        fmail,
        fbeint
      );

      if (code == 0) {
        return;
      }
    },
    submitForm() {
      if (this.formlabelalign.fname !== "" && this.formlabelalign.ftel !== "") {
        this.Psuggestdata(
          this.formlabelalign.fname,
          this.formlabelalign.ftel,
          this.formlabelalign.fcorname,
          this.formlabelalign.faddress,
          this.formlabelalign.fmail,
          this.formlabelalign.fbeint
        );

        this.formlabelalign.fname = "";
        this.formlabelalign.ftel = "";
        this.formlabelalign.fcorname = "";
        this.formlabelalign.faddress = "";
        this.formlabelalign.fmail = "";
        this.formlabelalign.fbeint = "";
      } else {
        this.$notify.error({
          title: "错误",
          message: "请输入您的姓名手机号",
          position: "bottom-right"
        });
      }
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style lang="scss" scoped>
.contact-page {
  width: 100%;
}
.images {
  width: 100%;
}
// 头部背景

// 表单
.contact-form {
  // margin: 0 auto;
  // flex: 4;
  // width: 1100px;
  // display: flex;
  // justify-content: space-between;
}
.form-cemter {
  width: 100%;
  margin: 0 auto;
}
.bg-form {
  // background-image: url("@/assets/acs/蒙版组 16@3x.jpg");

  margin: 0 auto;

  width: 100%;
  h3 {
    width: 100%;

    font-size: 24px;

    line-height: 13px;
    text-align: center;
    color: #333;
    padding-top: 10vw;
    margin-bottom: 3vw;
  }
  .remind {
    width: 80%;
    padding: 0 10%;
    font-size: 12px;

    line-height: 20px;
    // margin: 20px 0;
    text-align: center;
    color: #999;
  }
  .tel {
    padding-top: 3vw;

    width: 100%;

    font-weight: bold;

    line-height: 13px;
    text-align: center;
    color: #004598;

    font-size: 19px;
    // margin-top: 13px;
    margin-bottom: 20vw;
  }
}
::v-deep .el-form-item {
  display: flex;
  width: 100%;
  margin-bottom: 95px;
}
::v-deep .el-form-item__content {
  // display: flex;
}
::v-deep .el-form-item__label {
  font-size: 12px;
  width: 30vw;
  margin-left: 10vw;
  line-height: 29px;
  color: #333333;
  text-align: left;
}
::v-deep .el-input__inner {
  width: 50vw;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid #999;
  box-shadow: none;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid rgb(138, 138, 138);
  //box-shadow: none;
}
::v-deep .bg-btn {
  height: 10vw;
  border-radius: 10px;
  background: #004598;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  text-align: center;
  font-size: 12px;
  line-height: 0vw;
  margin-bottom: 20px;
}
// .fixed-footer {
//   width: 100%;
//   min-height: 1rem;
//   background-color: rgba(51, 51, 51, 1);
//   //position: fixed;
//   //bottom: 0;
// }

// #sticky1 {
//   position: sticky;
//   position: -webkit-sticky;
//   background: #0090ffff;
//   width: 1.3rem;
//   height: 1.32rem;
//   top: 6.7rem;

//   justify-content: center;
//   align-items: center;
//   box-shadow: 0 0 6px #000;
//   color: #fff;
//   margin: 0px 0rem -2rem 17.07rem;
//   border-radius: 10px;
//   .sticky-text {
//     font-size: 0.2rem;
//     z-index: 999;
//     margin: 0 0px 0px 10px;
//     padding: 0;
//   }
// }
// 回到顶部
</style>
