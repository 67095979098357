<template>
  <el-dialog
    title="保持登陆时间"
    :visible.sync="dialogTableVisible"
    width="20%"
    style="margin-top: 220px"
    center
  >
    <div class="dialog-center">
      <div class="dialog-name">
        <el-time-select
          v-model="value"
          :picker-options="{
            start: '00:00',
            step: '01:00',
            end: '72:00',
          }"
          placeholder="选择时间"
        >
        </el-time-select>
      </div>

      <div class="dialog-foot">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible">修改</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      value: "",

      dialogTableVisible: false,
    };
  },
  methods: {
    centerDialogVisible() {
      this.$emit("centerDialogVisible", this.value);
    },
  },
};
</script>

<style lang="scss">
.dialog-center {
  width: 280px;
  margin: 0 auto;
  .dialog-name {
    margin: 40px 0;
  }

  .dialog-foot {
    display: flex;
    justify-content: space-between;
    .el-button {
      width: 180px;
    }
    .el-button--primary {
      background: #004598;
    }
  }
}
</style>