<template>
  <div class="services_page">
    <!-- <sidebar></sidebar> -->
    <!--海报-->
    <div class="deskbao">
      <div class="desk">
        <img src="./imges/组 507@3x.jpg" alt />
        <!-- <div class="services">SUPPORT AND SERVICES</div>
        <div class="services_CN">服务与支持</div>-->
      </div>
    </div>
    <!--文件下载部分-->
    <div class="file">
      <h3 id="whydownload">资料下载</h3>
      <p class="enfile">DOWNLOAD INFORMATION</p>
      <!--选择器-->
      <div class="selectbao">
        <el-autocomplete
          class="inline-input"
          v-model="wallvalue"
          suffix-icon="el-icon-search"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          :trigger-on-focus="false"
          @change="searchposition"
          @keyup.enter="searchposition"
        ></el-autocomplete>
      </div>

      <!--下载文件展示区域-->
      <div class="file_1">
        <div class="file_box">
          <div class="fl-box" ref="flbox">
            <div class="ppppp">
              <div class="fl-item-name">{{ cyname }}</div>
              <div class="fl-item">
                <div :id="'flItem' + index" v-for="(item, index) in flconlist" :key="index">
                  <div class="fl-item-con">
                    <div class="fl-item__icon"></div>
                    <span class="icontext">{{ item.wtitle }}</span>

                    <div class="btnitem-text">
                      <div class="btnitem-atext">
                        <i class="el-icon-download">
                          <a
                            :href="imgurl + item.wdown"
                            :download="item.wtitle"
                            class="fl-item-a"
                          >下载</a>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="file_box">
          <div class="qwer">
            <div class="fl-box" ref="flbox">
              <div class="fl-item-name" id="shuomingshu">{{ smsname }}</div>
              <div class="fl-item">
                <div :id="'flItem' + index" v-for="(item, index) in instructions" :key="index">
                  <div class="fl-item-con">
                    <div class="fl-item__icon"></div>
                    <span class="icontext">{{ item.wtitle }}</span>

                    <div class="btnitem-text">
                      <div class="btnitem-atext">
                        <i class="el-icon-download">
                          <a
                            :href="imgurl + item.wdown"
                            :download="item.wtitle"
                            class="fl-item-a"
                          >下载</a>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="file_box">
          <div class="qwer">
            <div class="fl-box" ref="flbox">
              <div class="fl-item-name1" id="shuomingshu">{{ spname }}</div>
              <div class="fl-item">
                <div :id="'flItem' + index" v-for="(item, index) in videdata" :key="index">
                  <div class="fl-item-con">
                    <div class="fl-item__icon"></div>
                    <span class="icontext">{{ item.wtitle }}</span>

                    <div class="btnitem-text">
                      <div class="btnitem-atext">
                        <i class="el-icon-download">
                          <a
                            :href="imgurl + item.wdown"
                            :download="item.wtitle"
                            class="fl-item-a"
                          >下载</a>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="file_box">
          <!-- <div class="qwer">
            <div class="fl-box" ref="flbox">
              <div class="fl-item-name1" id="shuomingshu">软件</div>
              <div class="fl-item">
                <div
                  :id="'flItem' + index"
                  v-for="(item, index) in videdata"
                  :key="index"
                >
                  <div class="fl-item-con">
                    <div class="fl-item__icon"></div>
                    <span class="icontext">{{ item.wtitle }}</span>
                    <p class="fltext">
                      <span>{{ item.wform }}</span>
                      <span>{{ item.wsize }}</span>
                      <span>{{ item.wdate }}</span>
                    </p>
                    <div class="btnitem-text">
                      <div class="btnitem-atext">
                        <i class="el-icon-download">
                          <a
                            :href="
                              imgurl + item.wdown
                            "
                            :download="item.wtitle"
                            class="fl-item-a"
                            >下载</a
                          >
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
    <!-- <h3 id="whyquestion">常见问题</h3>

    <p class="enfile">COMMON PROBLEM</p>
    <SerVices></SerVices>-->
    <!-- 底部链接 -->
    <!-- <div class="fixed-footer">
      <MyFooter />
    </div>-->
  </div>
</template>

<script>
// import MyFooter from "../components/footer.vue";
// import { computed, components } from "vue";
import Sidebar from "@/components/sidebar.vue";
//import{search} from ele
import { Download, Config, Suggarea } from "@/axios/modules/officialwebsite.js";
import { networkConfig } from "@/axios/networkConfig";
// import SerVices from "./service.vue";

export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      cyname: "",
      smsname: "",
      spname: "",
      open: false,
      wtypevalue: "",
      wmodevalue: "",
      wallvalue: "",
      psrcimg: "",
      softwaredata: [],
      videdata: [],
      result: [],
      // url:'../assets/app.png',
      options: [],
      models: [],
      menuList: [{ name: "彩页" }, { name: "说明书" }, { name: "其他" }],

      flconlist: [],

      instructions: [],
      restaurants: [{ value: "" }]
    };
  },
  components: {
    // MyFooter,
    // Sidebar,
    // SerVices,
  },
  created() {},
  mounted() {
    // this.restaurants = this.loadAll();
    this.Suggareadata("download");
    this.Downloaddata();
    this.Configdata("dload");
    this.jump();
  },
  methods: {
    jump() {
      if (this.$route.query.text == 1) {
        setTimeout(() => {
          this.$el.querySelector("#whydownload").scrollIntoView({
            behavior: "smooth", // 平滑过渡
            block: "center" // 上边框与视窗顶部平齐。默认值
          });
        }, 300);

        // console.log(2222);
      } else if (this.$route.query.text == 2) {
        setTimeout(() => {
          this.$el.querySelector("#whyquestion").scrollIntoView({
            behavior: "smooth", // 平滑过渡
            block: "center" // 上边框与视窗顶部平齐。默认值
          });
        }, 300);
      }
    },
    // icon
    openicon() {
      this.open = true;
    },
    onopenicon() {
      this.open = false;
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    // 接口数据
    async Downloaddata(wtype, wmode, wall) {
      // console.log(wtype, wmode, wall);
      let { code, data } = await Download(wtype, wmode, wall);
      var moment = require("moment");

      if (code == 0) {
        console.log(data, "data_________________________");
        this.psrcimg = data.fpage.psrc;
        data.list.map(item => {
          if (item.wtype == "彩页") {
            item.wdate = moment(Number(item.wdate) * 1000).format(
              "YYYY/MM/DD "
            );
            // item.wsize = (Number(item.wsize) / 1024).toFixed(2) + "M";
            this.flconlist.push(item);
            this.cyname = this.flconlist[0].wtype;
            // new Date(item.)
          } else if (item.wtype == "说明书") {
            item.wdate = moment(Number(item.wdate) * 1000).format(
              "YYYY/MM/DD "
            );

            item.wsize = (Number(item.wsize) / 1024).toFixed(2) + "M";
            this.instructions.push(item);
            this.smsname = this.instructions[0].wtype;
            // console.log(this.instructions, 2222);
          } else if (item.wtype == "软件") {
            item.wdate = moment(Number(item.wdate) * 1000).format(
              "YYYY/MM/DD "
            );

            item.wsize = (Number(item.wsize) / 1024).toFixed(2) + "M";
            this.softwaredata.push(item);
            // this.smsname=this.softwaredata[0].wtype
            // console.log(this.softwaredata, 2222);
          } else if (item.wtype == "视频") {
            item.wdate = moment(Number(item.wdate) * 1000).format(
              "YYYY/MM/DD "
            );

            item.wsize = (Number(item.wsize) / 1024).toFixed(2) + "M";
            this.videdata.push(item);
            // console.log(this.instructions, 2222);
            this.spname = this.videdata[0].wtype;
            console.log(this.spneme, 777777);
          }
        });
        // this.right_list_arr[0] = data.config;
        // console.log(this.right_list_arr[0], 444);
        return;
      }
    },
    // 搜索查询接口
    async Configdata(local) {
      let { code, data } = await Config(local);
      // console.log(data, 5555);
      if (code == 0) {
        this.options = data.config.select[0];
        this.models = data.config.select[1];

        // console.log(this.options, 11111);

        return;
      }
    },
    // 模糊匹配
    async Suggareadata(area, key) {
      let { code, data } = await Suggarea(area, key);
      // console.log(data);
      if (code == 0) {
        // for (let i = 0; i <= data.length; i++) {
        //   this.restaurants[i].value = data[i];
        //   console.log(this.restaurants, 7777);
        // }
        this.restaurants = data.map(item => {
          return { value: item };
        });
        // console.log(this.restaurants, 3333);
        return;
      }
    },
    queryBtn() {
      if (this.wtypevalue == "全部") {
        this.wtypevalue = "";
        // console.log(this.wtypevalue, this.wmodevalue, this.wallvalue, 2222);
      }
      if (this.wmodevalue == "全部") {
        this.wmodevalue = "";
      }
      this.flconlist = [];
      this.instructions = [];
      this.softwaredata = [];
      this.videdata = [];
      (this.cyname = ""),
        (this.smsname = ""),
        (this.spname = ""),
        this.Downloaddata(this.wtypevalue, this.wmodevalue, this.wallvalue);
    },
    searchposition() {
      if (this.wtypevalue == "全部") {
        this.wtypevalue = "";
        // console.log(this.wtypevalue, this.wmodevalue, this.wallvalue, 2222);
      }
      this.flconlist = [];
      this.instructions = [];
      this.softwaredata = [];
      this.videdata = [];
      (this.cyname = ""),
        (this.smsname = ""),
        (this.spname = ""),
        this.Downloaddata(this.wtypevalue, this.wmodevalue, this.wallvalue);
    }
    // console.log(1111);
  }
};
</script>
<style lang="scss" scoped>
.services_page {
  width: 100vw;
  margin: 0 auto;
}

.desk {
  width: 100vw; //  min-width: 1200px;

  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  > img {
    width: 100vw;
  }
}

//   .services_CN {
//     font-size: 0.4rem;

//     font-weight: 400;
//     line-height: 1rem;
//     color: #ffffff;
//     letter-spacing: 50px;
//   }
// }
// 搜索框
.hot_text {
  width: 61px;
  padding-right: 10px;
  font-size: 20px;

  font-weight: 40;
  line-height: 40px;
}
.hot {
  padding-left: 10px;
  font-size: 16px;
  line-height: 40px;

  color: #ff6031;
}
.res {
}
//文件下载部分样式
h3 {
  font-size: 18px;
  font-weight: bold;
  // line-height: 100px;
  color: #333333;
  letter-spacing: 10px;
  text-align: center;
}
//英文小标题
.enfile {
  font-size: 10px;
  margin: 20px 0;
  // font-weight: 400;
  color: #999595;

  text-align: center;
}
.selectbao {
  margin: 0 2%;
  background: #f9f9f9;
  padding: 5vw 0;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.16);
}
.inline-input {
  width: 60%;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
//产品说明书展示样式

.file_1 {
  // height: 500px;
  // margin-bottom: 200px;
}
.file_box {
  margin: 0 auto;
  // margin-bottom: 20vw;
}
.fl-box {
}
//分类标题
.fl-item-name {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  color: #333333;
  padding: 20px;
}
.fl-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 5%;
}
// 鼠标选中后
.fl-item-con:hover {
  background-color: #004598ff;
  z-index: 999;
  color: #fff;
  .fl-item__icon {
    background-image: url("../../../icons/svg/pdfh.svg");
    // fill: red;

    // overflow: hidden;
    // // background: #004598ff;
    // filter: drop-shadow(#fff 0px 0);
  }
  .fltext {
    color: #fff;
  }
  .icontext {
    color: #fff;
  }
  .fl-item-a {
    color: #fff;
  }
  .btnitem-atext {
    border: 1px solid #fff;
  }
  .el-icon-download {
    color: #fff;
  }
  .flicon {
    background-image: url("../../../icons/svg/pdfh.svg");
  }
}
.fl-item-a {
  text-decoration: none;
  color: #999595;
}

.fl-item-con {
  width: 100%;

  margin-bottom: 30px;
  background: #fafafa;
  border-radius: 15px;

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  .fl-item__icon {
    width: 20vw;
    height: 20vw;
    background-image: url("../../../icons/svg/pdf.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 5vw auto;
  }
  //pdf图标

  //pdf图标
  .flicon {
    // padding: 50px 0;
  }
  //图标文件描述
  .icontext {
    font-size: 11px;
    width: 42vw;
    height: 16vw;
    font-weight: bold;

    line-height: 17px;
    color: #333333;
    text-align: center;
  }
  //文件类型大小时间
  .fltext {
    font-size: 9px;

    font-weight: 400;

    line-height: 18px;
    color: #999999;
    margin: 0 auto;
    margin-bottom: 5vw;
    span {
      padding: 4vw;
      //下载按钮
    }
    // 输入框文字大小样式
    ::v-deep .el-input__inner {
      font-size: 20px;
    }
  }
}

.fl-item-name1 {
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  color: #333333;
  padding: 20px;
}
// 按钮
.el-row {
  margin: 0 0 0 5vh;
}
.btnitem-text {
  width: 50%;
  height: 40px;

  margin: 0 auto;
  .btnitem-atext {
    border-radius: 5px;
    border: 1px solid #999999;

    font-size: 9px;
    width: 100%;

    text-align: center;
    line-height: 30px;
  }
  .el-icon-download {
    color: #999999;
  }
}
</style>
