<template>
  <div class="news_page">
    <sidebar></sidebar>
    <!--海报-->
    <div class="bg_news_bao">
      <div class="bg_news">
        <img v-lazy="imgurl + newsbj" alt="" />
      </div>
    </div>

    <!--新闻展示-->
    <div class="news_display">
      <el-tabs v-model="activeName" class="demo-tabs">
        <!--公司新闻-->
        <el-tab-pane label="公司新闻" name="one">
          <div class="one" v-for="(item, index) in items1" :key="item.index">
            <div
              v-if="index % 2 == 0"
              style="
                justify-content: flex-end;
                display: flex;
                margin-bottom: 30px;
              "
            >
              <div
                class="img_css"
                :style="{
                  background: `url(${imgurl + item.nimg})no-repeat`,
                  'background-size': `contain`,
                }"
              ></div>

              <router-link
                :to="item.path"
                class="name_css"
                style="margin-left: 0.9rem; text-align: left"
              >
                <p class="name_text1">{{ item.ntitle }}</p>
                <div class="name_text2">{{ item.ndetail }}</div>
              </router-link>
            </div>
            <div v-else style="justify-content: flex-end; display: flex">
              <router-link
                :to="item.path"
                class="name_css"
                style="margin-right: 0.9rem; text-align: right"
              >
                <p class="name_text1">{{ item.ntitle }}</p>
                <div class="name_text2">{{ item.ndetail }}</div>
              </router-link>
              <div
                class="img_css"
                :style="{
                  'background-image': `url(${imgurl + item.nimg})`,
                }"
              ></div>
            </div>
          </div>
        </el-tab-pane>

        <!--杂志-->
        <!-- <el-tab-pane label="杂志" name="two">
          <div class="one" v-for="(b, index) in items2" :key="b.index">
            <div
              v-if="index % 2 == 0"
              style="
                display: flex;
                justify-content: flex-start;
                margin-bottom: 0.8rem;
              "
            >
              <div
                class="img_css"
                :style="{ 'background-image': `url(${b.src})` }"
              ></div>
              <div class="name_css" style="margin-left: 1rem">
                <p class="name_text1">{{ b.text1 }}</p>
                <p class="name_text2">{{ b.text2 }}</p>
              </div>
            </div>
            <div v-else style="justify-content: flex-end; display: flex">
              <div
                class="name_css"
                style="margin-right: 0.9rem; text-align: right"
              >
                <p class="name_text1">{{ b.text1 }}</p>
                <p class="name_text2">{{ b.text2 }}</p>
              </div>
              <div
                class="img_css"
                :style="{ 'background-image': `url(${b.src})` }"
              ></div>
            </div>
          </div>
        </el-tab-pane> -->

        <!--行业新闻-->
        <!-- <el-tab-pane label="行业新闻" name="three">
          <div class="one" v-for="(b, index) in items3" :key="b.index">
            <div
              v-if="index % 2 == 0"
              style="
                display: flex;
                justify-content: flex-start;
                margin-bottom: 0.8rem;
              "
            >
              <div
                class="img_css"
                :style="{ 'background-image': `url(${b.src})` }"
              ></div>
              <div class="name_css">
                <p class="name_text1">{{ b.text1 }}</p>
                <p class="name_text2">{{ b.text2 }}</p>
              </div>
            </div>
            <div v-else style="justify-content: flex-end; display: flex">
              <div
                class="name_css"
                style="margin-right: 0.9rem; text-align: right"
              >
                <p class="name_text1">{{ b.text1 }}</p>
                <p class="name_text2">{{ b.text2 }}</p>
              </div>
              <div
                class="img_css"
                :style="{ 'background-image': `url(${b.src})` }"
              ></div>
            </div>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </div>

    <!-- 底部链接 -->
    <!--<div class="fixed-footer">
      <MyFooter />
    </div>-->
  </div>
</template>

<script>
//import MyFooter from "../components/footer.vue";
//import { computed, components } from "vue";
import Sidebar from "../components/sidebar.vue";
import { News } from "../axios/modules/officialwebsite.js";
import { networkConfig } from "../axios/networkConfig";
export default {
  data() {
    return {
      newsdetail: "/newsdetail",
      imgurl: networkConfig.serverUrl + "upload/",
      newsbj: "",
      activeName: "one",
      items1: [],
      items2: [
        {
          src: require("../assets/news/news1.jpg"),
          text1: "杂志1",
          text2: "介绍1",
        },
        {
          src: require("../assets/news/news2.jpg"),
          text1: "杂志2",
          text2: "介绍2",
        },
      ],
      items3: [
        {
          src: require("../assets/news/news1.jpg"),
          text1: "行业新闻1",
          text2: "介绍1",
        },
        {
          src: require("../assets/news/news2.jpg"),
          text1: "行业新闻2",
          text2: "介绍2",
        },
      ],
    };
  },
  methods: {
    async News(pcount) {
      let { code, data } = await News(pcount);
      console.log(data);
      if (code == 0) {
        this.newsbj = data.fpage.psrc;
        this.items1 = data.list;
        // console.log(this.newsbj);
        // console.log(this.right_list_arr[0], 444);
        return;
      }
    },
  },
  components: {
    // configlocal,
    Sidebar,
  },
  mounted() {
    this.News("4");
  },
};
</script>
<style lang="scss" scoped>
.news_page {
  width: 1320px;
  margin: 0 auto;
}
.bg_news_bao {
  height: 973px;
  // position: relative;
}
.bg_news {
  width: 1900px;
  // height: 100%;
  // min-height: 8rem;
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  > img {
    width: 100%;
    // background-size: cover;
    // background-repeat: no-repeat;
  }
}
.news_display {
  width: 100%;
  margin-bottom: 100px;
  .el-tabs {
  }
}

::v-deep .el-tabs__nav-wrap::after {
  background: #fff;
}
::v-deep.el-tabs__header {
  margin-bottom: 0.5rem;
}
::v-deep.el-tabs__nav-wrap::after {
  width: 0px;
}
::v-deep.el-tabs__item {
  font-size: 0.16rem;
}
.one {
  width: 100%;
}
.name_css {
  display: inline-block;
  vertical-align: top;
  flex: 1;

  height: 500px;
  .name_text1 {
    font-size: 40px;

    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -webkit-ellipsis-lastline;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .name_text2 {
    margin-top: 47px;

    font-size: 24px;
    line-height: 40px;
    // text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -webkit-ellipsis-lastline;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
.img_css {
  flex: 1;

  background-size: contain;
  background-repeat: no-repeat;
}
//.fixed-footer {
//  width: 100%;
//  min-height: 1rem;
//  background-color: rgba(51, 51, 51, 1);
//}
.router-link-active {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
</style>