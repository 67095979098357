<template>
  <div class="traffic">
    <img class="imgaes" src="@/assets/acs/组 597@3x.jpg" alt />
    <!-- <div class="btn"></div>
    <div class="btn1"></div>
    <div class="btn2"></div>-->
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.traffic {
  width: 100%;
  position: relative;
}
.imgaes {
  width: 100vw;
}
.btn {
  width: 100vw;
  height: 28vw;
  // background: red;
  position: absolute;
  bottom: 128vw;
  left: 0vw;
}
.btn1 {
  width: 20vw;
  height: 8vw;
  // background: red;
  position: absolute;
  bottom: 3vw;
  left: 40vw;
}
.btn2 {
  width: 20vw;
  height: 8vw;
  // background: red;
  position: absolute;
  bottom: 50vw;
  left: 40vw;
}
</style>
