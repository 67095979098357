<template>
  <div class="traffic">
    <div class="xw-center">
      <h2>看懂RTK定位，这一篇就够啦！</h2>
      <hr />
      <div class="xw-time">
        <div class="xw-time__text">发布时间:</div>
        <div class="xw-time__text">2022-08-06</div>
        <div class="xw-time__text">浏览量:</div>
        <div class="xw-time__text">182次</div>
      </div>
      <p>
        RTK技术经过多年的积累，已经变得越来越成熟。它的高精度、高速度、高稳定性特点，使得其被测绘、无人机、车载、安防等领域广泛应用。
      </p>

      <img
        style="width: 100%"
        src="./image/微信图片_20221104112906@3x(1).jpg"
        alt=""
      />

      <p>
        说到定位，相信大家一定不会觉得陌生。如今我们所处的信息时代，人人都有手机。每天，我们都会用到与地图和导航有关的APP。
      </p>
      <p>这些APP，就是基于定位技术的。</p>
      <p>
        说到定位技术呢，大家又肯定会想到GPS、北斗这些名词。是的，这些都属于全球导航卫星系统，也就是GNSS（Global
        Navigation Satellite System）。
      </p>
      <p>
        正是这些在太空中飞行的卫星，帮助我们的手机具备了定位能力，并为我们提供导航服务。
      </p>
      <img style="width: 100%" src="./image/2.jpg" alt="" />

      <p>
        上面都是大家知道的。接下来，小枣君要给大家介绍一个可能比较陌生的概念。它也和卫星有关，是目前行业最常用的定位技术之一，为我们的工作和生活提供了很大的帮助。它就是——RTK。
      </p>
      <p>
        究竟什么是RTK？有了卫星，为什么还需要它？它有什么特点，又是如何工作的？
      </p>
      <p>别急，且让小枣君一一道来。</p>

      <h4>什么是RTK</h4>
      <p>
        RTK:英文全名叫做Real-time
        kinematic，也就是实时动态。这是一个简称，全称其实应该是RTK（Real-time
        kinematic，实时动态）载波相位差分技术。（为了方便阅读，接下来，我还是会简写为RTK。）
      </p>
      <p>不要慌！这个技术虽然看上去很专业，但实际原理并不复杂。</p>
      <p>RTK是一个对GNSS进行辅助的技术。</p>
      <p>为什么要对GNSS进行辅助？当然是因为GNSS自身存在不足啦！</p>
      <p>
        大家都知道，GNSS卫星之所以能够对地球上的终端（例如手机、汽车、轮船、飞机等）进行定位，依靠的是三维坐标系。
      </p>
      <img src="./image/3.jpg" alt="" />
      <P
        >找至少4颗卫星，分别计算各个卫星与终端之间的距离△L（这个距离也被称为“伪距”），就可以列出4个方程组。</P
      >
      <P
        >计算之后，就能得出终端的四个参数，分别是经度、纬度，高程（海拔高度）和时间。</P
      >
      <P
        >通过单位时间的位置变化，还能算出终端的速度。三维坐标、速度、时间信息，我们通常称之为PVT（Position
        Velocity and Time）。</P
      >
      <P>仅靠卫星，我们可以得到PVT。但是，注意了，卫星定位是存在误差的。</P>
      <P
        >误差既来自系统的内部，也来自外部。例如卫星信号穿透电离层和对流层时产生的</P
      >
      <P
        >误差，还有卫星高速移动产生的多普勒效应引起的误差，以及多径效应误差、通道误差、卫星钟误差、星历误差、内部噪声误差，等等。</P
      >

      <P
        >这些误差，有些可以完全消除，有些无法消除或只能部分消除。它们影响了系统的准确性和可靠性。</P
      >

      <P>好啦，我们的主角终于要登场了</P>
      <P
        >为了更好地消除误差、提高定位精度，行业专家们研究出了一个更厉害的定位技术，那就是RTK。</P
      >
      <P>RTK的工作原理</P>
      <P>我们直接来看RTK的工作原理。</P>
      <img src="./image/4.jpg" alt="" />
      <p>如上图所示，这是一个标准的传统RTK组网。（是不是超简单？）</p>
      <p>其中，除了卫星之外，RTK系统包括两个重要组成部分——基准站和流动站。</p>
      <p>
        两个站都带有卫星接收机，可以观测和接收卫星数据。顾名思义，基准站是提供参
      </p>
      <p>
        考基准的基站。而流动站，是可以不断移动的站。流动站其实就是要测量自身三维坐标的那个对象目标，也就是用户终端。
      </p>
      <p>
        大家经常在户外看到一些扛着三脚架设备进行测量的人。其中一部分人，扛的可能就是RTK基准站或流动站。
      </p>
      <p>我们来仔细看看定位过程。</p>
      <p>
        首先，基准站作为测量基准，一般会固定放在开阔且视野良好的地方。基准站的三维坐标信息，一般是已知的。
      </p>
      <img src="./image/5.jpg" alt="" />
      <p>第①步，基准站先观测和接收卫星数据。</p>
      <p>
        第②步，基准站通过旁边的无线电台（数据链），将观测数据实时发送给流动站（距离一般不超过20公里）。
      </p>
      <p>第③步，流动站收到基准站数据的同时，也观测和接收了卫星数据。</p>
      <p>
        第④步，流动站在基准站数据和自身数据的基础上，根据相对定位原理，进行实时差分运算，从而解算出流动站的三维坐标及其精度，其定位精度可达1cm~2cm。至此，测量完成。
      </p>
      <p>
        如大家所见，RTK技术具有观测站之间无需通视（无需在视线范围内）、定位精度高、操作简单、全天候作业等优点，是非常不错的定位技术。
      </p>
      <h4>网络RTK vs 传统RTK</h4>

      <p>刚才我们所说的，是RTK的早期模型，我们称为传统RTK技术。</p>
      <p>
        传统RTK技术实施简单，成本低廉。但是，它也存在一个很大的问题，那就是流动站和基准站之间存在距离限制。
      </p>
      <p>
        距离越远，误差因素差异变大，定位精度就会下降。而且，距离远了，超过了无线电台的通信范围，也就无法工作了。
      </p>
      <p>
        为了克服传统RTK技术的缺陷，在20世纪90年代中期，人们提出了网络RTK技术。
      </p>
      <p>
        在网络RTK技术中，在一个较大的区域内，均匀分散设置多个基准站（3个或以上），构成一个基准站网。
      </p>
      <img src="./image/6.jpg" alt="" />
      <p>那么，这种情况下，流动站需要和每个基准站进行对比和测算吗？</p>
      <p>当然不会，那样太费事了。</p>
      <p>
        网络RTK相比传统RTK，其实是用区域型的GNSS网络误差模型取代了单点GNSS误差模型。多个基准站组成的基准站网，它们将数据发给中央服务器。中央服务器会根据数据，模拟出一个“虚拟基准站”。（所以，网络RTK也被称为“虚拟基准站技术”或“虚拟参考站技术”。）
      </p>
      <img src="./image/7.jpg" alt="" />
      <p>
        对于流动站来说，它只会“看到”这个“虚拟基准站”。基于这个“虚拟基准站”发来的数据，流动站完成最终的测量运算。
      </p>
      <p>网络RTK的优势是非常明显的。</p>
      <p>
        大家应该都看出来了，我们平时看到的移动通信基站，其实就可以兼职“基准站”。
      </p>
      <p>我们身边到处都是基站，也就意味着，网络RTK基本上实现了无缝覆盖。</p>
      <p>
        流动站与中央服务器的通信，也可以通过流动站（终端）内置的无线通信模组来完成。这些高精度定位模组，集成了RTK技术，且本身也是移动通信模组，可以实现上述功能。
      </p>
      <p>
        其次，对于用户来说，不需要自建基准站，节约了大量成本（只需要支付一些通讯费用）。
      </p>
      <p>
        第三，精度和可靠性更高。毕竟基准站多了嘛，就算坏了一两个，也影响不大。
      </p>
      <img src="./image/8.jpg" alt="" />
      <p>
        值得一提的是，网络RTK的模型中，网络的稳定性对定位精度影响极大。必须保证网络通信稳定，从而确保差分数据稳定下发，才能实现超高定位精度。
      </p>
      <h4>结语</h4>
      <p>
        RTK技术经过多年的积累，已经变得越来越成熟。它的高精度、高速度、高稳定性特点，使得其被测绘、无人机、车载、安防等领域广泛应用。
      </p>
      <p>
        未来，RTK技术将会向更远距离、更高精度、多频多模、更高稳定性的方向发展。让我们拭目以待！
      </p>

      <div class="xw-source">（来源：网络）</div>
      <hr />
    </div>
    <div>
      <router-link to="/newsdetail/newsxw5" class="btn">
        下一篇:全国CORS账号，在新疆哪里覆盖，哪里不覆盖啊？
      </router-link>
    </div>
    <div>
      <router-link to="/newsdetail/newsxw3" class="btn3">
        上一篇:工信部细化民用无人机管理政策
        暂行办法再次公开征求意见</router-link
      >
    </div>

    <!-- <div class="btn1"></div>
    <div class="btn2"></div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.traffic {
  margin-top: 20vw;
  position: relative;
  padding: 0 2%;
}
.imgaes {
  width: 100%;
}
.btn {
  font-size: 12px;
  width: 100%;
  height: 8vw;
  background: #fff;
  // position: absolute;
  // bottom: 5vw;
  // left: 0;
}
.btn3 {
  font-size: 12px;
  width: 100%;
  height: 15vw;
  background: #fff;
  // position: absolute;
  // bottom: 13vw;
  // left: 0;
}
// .btn1 {
//   width: 30vw;
//   height: 8vw;
//    // background: red;: red;
//   position: absolute;
//   bottom: 3vw;
//   left: 40vw;
// }
// .btn2 {
//   width: 30vw;
//   height: 8vw;
//    // background: red;: red;
//   position: absolute;
//   bottom: 55vw;
//   left: 40vw;
// }
.xw-center {
  width: 100%;
  // margin: 0 2%;

  min-height: 80vh;
  //   background: pink;
  text-align: center;

  //   > h2 {
  //     margin: 28px 0;
  //   }
  > hr {
    margin: 40px 0;
    border: 0.5px solid #e6e6e6;
  }
  > p {
    line-height: 32px;
    font-size: 16px;
    text-align: left;
    text-indent: 2em;
    padding: 20px 0;
  }
  > img {
    width: 100%;
  }
  .xw-source {
    text-align: right;
    color: #999999;

    font-size: 14px;
  }
  > h3 {
    text-align: left;
    padding: 15px 0;
  }
  > h4 {
    text-align: left;
    padding: 15px 0;
  }
}
.xw-time {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .xw-time__text {
    padding: 3px;
    font-size: 14px;

    color: #666666;
  }
}
.xw-newest {
  // position: fixed;
  right: 23vw;
  width: 365px;
  //   padding: 16px;
  height: 500px;
  margin: 16px;
  //   background: red;
  border: 1px solid #cccccc;
}
.router-link-active {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
.active.right-row--a {
  color: #2271f7;
}
</style>
