<template>
  <div class="security-setting">
    <div class="security-setting__name">
      <div class="security-setting__securitysetting">安全设置</div>
      <div class="security-setting__grade">
        <p>安全等级：</p>

        <p :style="{ color: gradedata.colordata }">{{ gradedata.name }}</p>
      </div>
    </div>
    <div
      class="security-setting__center"
      v-for="(item, index) in cs"
      :key="index"
    >
      <div class="security-setting__head">
        <div
          class="security-setting__notset"
          v-if="footdata[item.id]?.length == 0 || footdata[item.id] == -1"
        >
          <i class="el-icon-warning-outline"></i>
          <p class="security-setting__text">[未设置]</p>
        </div>
        <div class="security-setting__upset" v-else>
          <i class="el-icon-success"></i>
        </div>

        <div class="security-setting__information">{{ item.name }}</div>
      </div>
      <div class="security-setting__middle">
        <div class="security-setting__title">
          <!-- <div class="security-setting__null"></div> -->
          <div class="security-setting__proposal">
            <div
              v-if="footdata[item.id]?.length != '' || footdata[item.id] == '0'"
            >
              {{ item.title1 }}{{ footdata[item.table] }}{{ item.title2 }}
            </div>
          </div>
          <div class="security-setting__modify" @click="open(index)">修改</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      set: "1",
      grade: "高",
      gradedata: {},
      footdata: {},
      cs: [
        {
          id: "fpassstatus",
          table: "",
          name: "登录密码",
          title1:
            "安全性高的密码可以使帐号更安全。建议您定期更换密码，设置长度超过6位的密码",
          title2: "",
        },
        {
          id: "ftel",
          table: "ftel",
          name: "手机绑定",
          title1: "您已绑定了手机  ",
          title2:
            "[您的手机为安全手机，可用于接收消息，账号核身（如找回密码、变更安全设置等）",
        },

        {
          id: "fvaldate",
          table: "fvaldate",
          name: "登录保持时间",
          title1:
            "您可以设置保持登录的时间长度（大于等于1小时，小于等于72小时），超过该时间系统会退出登录，目前的设置为 ",
          title2: " 小时（系统默认是72小时",
        },
        // {
        //   id: "femail",
        //   table: "femail",
        //   name: "邮箱绑定",
        //   title1: "您已绑定了邮箱",
        //   title2:
        //     " [备用邮箱绑定后可用来接收开普勒发送的各类系统、营销、服务通知]",
        // },
        // {
        //   id: "1",
        //   name: "操作保护",
        //   title:
        //     "你已设置关键操作（如：修改密码等）时，通过设置保护强度和验证方式再次确认您的身份，进一步提高账号安全性，有效保护您安全使用开普勒服务",
        // },w
      ],
    };
  },
  methods: {
    open(index) {
      this.$emit("opendia", index);
      // console.log(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.security-setting {
  width: 100%;
  height: 100%;
  background: #fff;

  .security-setting__name {
    display: flex;
    padding: 20px;

    font-size: 16px;

    color: #333333;
    .security-setting__securitysetting {
      padding-right: 20px;

      line-height: 30px;

      // line-height: 40px;
    }
    .security-setting__grade {
      display: flex;
      justify-content: center;
      width: 113px;
      height: 28px;
      background: #e6e6e6;
      font-size: 14px;

      color: #666666;
      text-align: center;
      line-height: 28px;
    }
  }
  .security-setting__center {
    margin-bottom: 50px;
    .security-setting__head {
      padding: 10px 20px;
      display: flex;

      //   justify-content: center;

      .security-setting__notset {
        display: flex;

        color: #ff6031;
        > i {
          font-size: 20px;
        }
        .security-setting__text {
          height: 20px;
          line-height: 20px;
          font-size: 16px;
          padding: 0 10px;
        }
      }
      .security-setting__upset {
        color: #8fc63a;
        > i {
          font-size: 20px;
          padding-right: 10px;
        }
      }
    }
    .security-setting__middle {
      .security-setting__null {
        width: 50px;
        height: 1px;
      }
      .security-setting__title {
        display: flex;
        justify-content: space-between;
        .security-setting__proposal {
          width: 827px;
          font-size: 14px;

          font-weight: 400;
          color: #999999;
          line-height: 28px;
          padding: 0 50px;
        }
        .security-setting__modify {
          padding-right: 200px;
          font-size: 14px;
          cursor: pointer;
          font-weight: 400;
          color: #004598;
        }
      }
    }
  }
}
</style>