<template>
  <div class="xw-newest__center">
    <div class="xw-newest__head">
      <p>最新新闻</p>
      <el-button round size="small" @click="gotonews">查看更多</el-button>
    </div>

    <div class="xw-newest__title">
      <div
        class="xw-newest__text"
        v-for="(item, index) in Newsname"
        :key="index"
      >
        <hr />
        <router-link :to="item.path" class="xw-newest__name">
          {{ item.ntitle }}</router-link
        >

        <div class="xw-newest__time">
          {{ item.ndate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Newsname: [],
    };
  },
  mounted() {
    // console.log(this.Newsname);
  },

  methods: {
    // 跳转到新闻资讯页面
    gotonews() {
      this.$router.push({ path: "/news" });
    },
  },
};
</script>

<style lang="scss" scoped>
.xw-newest__center {
  width: 329px;
  margin: 0 auto;

  .xw-newest__head {
    height: 50px;
    //   background: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .xw-newest__title {
    .xw-newest__text {
      padding-top: 10px;
      line-height: 37px;
      .xw-newest__name {
        // padding-top: 10px;
        font-size: 18px;
      }
      .xw-newest__time {
        color: #999999;
        font-size: 12px;
        // padding-bottom: 10px;
      }
    }
  }
}
.router-link-active {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
.active.right-row--a {
  color: #2271f7;
}
</style>