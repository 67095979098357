<template>
  <div class="from2">
    <el-form ref="form" :rules="rules" :model="form" label-width="110px">
      <div class="authentication-title">完成实名认证可获取更多权益</div>
      <el-form-item label="用户名：" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入用户名，可以用于登录"
        ></el-input>
        <div class="authentication-tips">用户名可用于登录，比如：abc123</div>
      </el-form-item>

      <el-form-item label="公司名称：" prop="company">
        <el-input
          v-model="form.company"
          placeholder="请输入企业公司名称，同营业执照"
        ></el-input>
        <div class="authentication-tips">
          请输入公司名称，比如：开普勒卫星科技（武汉）有限公司
        </div>
      </el-form-item>

      <el-form-item label="企业邮箱：" prop="mailbox" ref="phone">
        <el-input
          placeholder="请输入企业邮箱"
          v-model="form.mailbox"
          class="input-with-select"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="服务用途" prop="value">
        <el-select
          v-model="form.value"
          placeholder="请选择"
          @blur="productSelect"
          allow-create
          filterable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系人：" prop="contact">
        <el-input
          v-model="form.contact"
          placeholder="请输入联系人姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="联系地址：" prop="address">
        <el-input
          v-model="form.address"
          placeholder="请输入联系地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="营业执照号：" prop="license">
        <el-input
          v-model="form.license"
          placeholder="请输入营业执照"
        ></el-input>
        <div class="authentication-tips">
          营业执照号码（或统一社会信用代码）
        </div>
        <div class="authentication-upload">
          <div class="authentication-upload__head">
            <img :src="unimg" alt="" />
            <div class="authentication-upload__trxt">
              请上传清晰地照片或扫描件，企业请上传《营业执照》，事业单位请上传《事业单位法人证书
              》
              <div style="display: flex">
                <p>其他类型主体若无法提供请</p>
                <a href="#">联系客服</a>
              </div>
            </div>
          </div>
          <div class="authentication-upload__foot">
            <el-upload
              class="upload-demo"
              :action="`https://s.kplgnss.com/sso/sso/uploadOne?token=${token}&ftype=1`"
              accept=".jpg,.png,"
              :on-success="onProgress"
              :show-file-list="false"
              :limit="10"
            >
              <p class="authentication-upload__btn" size="small" type="primary">
                点击上传
              </p>
            </el-upload>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="法人身份证：" prop="id_ka">
        <div class="authentication-upload">
          <div class="authentication-upload__head">
            <img :src="onimg" alt="" />
            <div class="authentication-upload__trxt">
              请上传清晰的法人或经办人的身份证照片或扫描件
              <div style="display: flex">
                <p>若都无法提供请</p>
                <a href="#">联系客服</a>
              </div>
            </div>
          </div>
          <div class="authentication-upload__foot">
            <el-upload
              class="upload-demo"
              :action="`https://s.kplgnss.com/sso/sso/uploadOne?token=${token}&ftype=2`"
              accept=".jpg,.png,"
              :on-success="OnSuccess"
              :show-file-list="false"
              :limit="10"
            >
              <p class="authentication-upload__btn" size="small" type="primary">
                点击上传
              </p>
            </el-upload>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div style="display: flex">
          <el-checkbox v-model="agree"> </el-checkbox>
          <div style="display: flex">
            <p class="left-title">我同意并接受</p>
            <a href="#">《开发者认证协议》</a>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交认证</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { networkConfig } from "../../axios/networkConfig";
import { getCookie } from "@/utils/auth";
import { UploadOne } from "../../axios/modules/personals.js";
import { Authentication } from "../../axios/modules/personals";
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空"));
      }
      // 使用正则表达式进行验证手机号码
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确"));
      }
      // 自定义校验规则 需要调用callback()函数！
      callback();
    };
    const validatemailbox = (rule, value, callback) => {
      if (!value) {
        callback(new Error("邮箱号不能为空"));
      }
      // 使用正则表达式进行验证手机号码
      if (
        !/^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/.test(
          value
        )
      ) {
        callback(new Error("邮箱格式不正确"));
      }
      // 自定义校验规则 需要调用callback()函数！
      callback();
    };
    return {
      token: "",
      imgurl: networkConfig.personal + "sso/personal",
      // 登录状态
      loggingStatus: false,
      // 验证码是否CD
      codeCd: false,
      // CD长度
      long: 60,
      agree: false,
      input: "",
      unimg: require("../../assets/image/personal_center/icon.svg"),
      onimg: require("../../assets/image/personal_center/icon2.svg"),
      form: {
        // phoneNum: null,
        checkCode: null,

        name: "",
        company: "",
        mailbox: "",
        // purpose: "",
        contact: "",
        phone: "",
        address: "",
        license: "",
        value: "",
      },
      //   验证表单
      rules: {
        value: [{ required: true, message: "请选择服务用途", trigger: "blur" }],
        contact: [{ required: true, message: "请上传身份证", trigger: "blur" }],
        id_ka: [{ required: true, message: "请上传身份证", trigger: "blur" }],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入联系地址", trigger: "blur" },
        ],
        license: [
          { required: true, message: "请输入营业执照", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        mailbox: [
          { required: true, validator: validatemailbox, trigger: "blur" },
        ],
        // checkCode: [
        //   { required: true, message: "验证码不能为空哦！", trigger: "blur" },
        // ],
      },
      // 服务用途
      options: [
        {
          value: "选项1",
          label: "高精度导航",
        },
        {
          value: "选项2",
          label: "车辆船舶监控",
        },
        {
          value: "选项3",
          label: "智能驾考/驾培",
        },
        {
          value: "选项4",
          label: "汽车自动驾驶",
        },
        {
          value: "选项6",
          label: "物流配送",
        },
        {
          value: "选项7",
          label: "资产管理",
        },
        {
          value: "选项8",
          label: "测量工程",
        },
        {
          value: "选项9",
          label: "电力巡检",
        },
        {
          value: "选项10",
          label: "管线巡检",
        },
        {
          value: "选项11",
          label: "建筑场地巡查",
        },
        {
          value: "选项12",
          label: "矿产勘测",
        },
        {
          value: "选项13",
          label: "林业勘测",
        },
        {
          value: "选项14",
          label: "大型人工建筑监测",
        },
        {
          value: "选项15",
          label: "工程施工",
        },
        {
          value: "选项16",
          label: "环境保护",
        },
        {
          value: "选项17",
          label: "危房监测",
        },
        {
          value: "选项18",
          label: "地质灾害监测",
        },
        {
          value: "选项19",
          label: "水利巡检",
        },
        {
          value: "选项20",
          label: "铁轨监测",
        },
        {
          value: "选项21",
          label: "专业无人机",
        },
        {
          value: "选项22",
          label: "植保无人机",
        },
        {
          value: "选项23",
          label: "消费无人机",
        },
        {
          value: "选项24",
          label: "土地普查",
        },
        {
          value: "选项25",
          label: "精准农业",
        },
        {
          value: "选项26",
          label: "金融保险",
        },
        {
          value: "选项27",
          label: "运动健身",
        },
        {
          value: "选项28",
          label: "测绘测量",
        },
        {
          value: "选项29",
          label: "自定义服务用途",
        },
      ],
      value: "",
    };
  },
  methods: {
    productSelect(e) {
      let value = e.target.value; // 输入框值
      if (value) {
        // 你输入才有这个值 不为空，如果你下拉框选择的话 这个值为空
        this.form.value = value;
      }
    },
    // 上传图片
    onProgress(response, file) {
      // console.log(response);
      this.unimg = response.servercard;
    },
    OnSuccess(response, file) {
      // console.log(response);
      this.onimg = response.idcard;
    },
    async UploadOnedata() {
      let { code, idcard, servercard } = await UploadOne();

      if (code == 0) {
        this.unimg = idcard;
        console.log(this.unimg, 1111);
        if (idcard != "") {
          console.log(1);
        } else if (servercard != "") {
          this.onimg = servercard;
        }
        return;
      }
    },
    onSubmit() {
      //   console.log(this.value);
      if (this.agree == true) {
        const { company, mailbox, contact, phone, address, license } =
          this.form;
        if (
          this.unimg !=
            require("../../assets/image/personal_center/icon.svg") &&
          this.onimg != require("../../assets/image/personal_center/icon2.svg")
        ) {
          const sendobj = {
            fcomname: company,
            fcommai: mailbox,
            fserpur: this.value,
            fcon: contact,
            fconphone: phone,
            fconaddress: address,
            fbuslicense: license,
            ftype: 2,
            idcard: this.unimg,
            servercard: this.onimg,
          };
          this.Authenticationget(sendobj);
        } else {
          this.$message.error({
            offset: 150,
            title: "错误",
            message: "请输入完整信息",
            type: "success",
          });
        }

        this.sendobj = [];
      } else {
        this.$message.error({
          offset: 150,
          title: "错误",
          message: "请勾选协议",
          type: "success",
        });
      }
    },
    //   提交认证接口
    async Authenticationget(sendobj) {
      let { code } = await Authentication(sendobj);

      if (code == 0) {
        this.$message({
          offset: 150,
          message: "实名认证成功，即将跳转个人中心",
          type: "success",
        });
        this.timer = setTimeout(() => {
          //设置延迟执行
          this.$router.push({ path: "/personal_center/index" });
        }, 3000);
        return;
      } else if (code == -1) {
        this.$message.error({
          offset: 150,
          title: "错误",
          message: "请填写完整信息",
          type: "success",
        });
      }
    },
  },
  mounted() {
    this.token = getCookie(getCookie("username"));
  },
};
</script>

<style lang="scss" scoped>
.from2 {
  width: 1320px;
}
.left-title {
  padding-left: 10px;
  padding-top: 8px;
  color: #333333;
  font-size: 12px;
  line-height: 24px;
}
.el-button--primary {
  width: 276px;
  height: 36px;
  background: #004598;
  border-radius: 4px 4px 4px 4px;
}
.el-form {
  width: 440px;
  margin: 0 auto;
  .authentication-title {
    text-align: center;
    padding: 30px 0;
  }
}
// 提示文字
.authentication-tips {
  color: #999999;
  font-size: 12px;
  line-height: 28px;
}
// 上传
.authentication-upload {
  width: 343px;
  height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .authentication-upload__head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > img {
      width: 130px;
      height: 97px;
    }
    .authentication-upload__trxt {
      width: 192px;

      line-height: 21px;
      color: #999999;
      font-size: 12px;
    }
  }
  .authentication-upload__btn {
    width: 130px;
    height: 27px;
    background: #ffffff;

    color: #333333;
    font-size: 12px;
    text-align: center;
    line-height: 27px;
    border: 1px solid #e6e6e6;
  }
}
</style>