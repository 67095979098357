<template>
  <div class="block">
    <div class="taxt-name">流水记录</div>
    <el-timeline>
      <el-timeline-item
        timestamp="2018/4/12"
        placement="top"
        icon="el-icon-time"
        size="large"
      >
        <el-card>
          <h4>更新 Github 模板</h4>
          <p>王小虎 提交于 2018/4/12 20:46</p>
        </el-card>
      </el-timeline-item>
      <el-timeline-item timestamp="2018/4/3" placement="top">
        <el-card>
          <h4>更新 Github 模板</h4>
          <p>王小虎 提交于 2018/4/3 20:46</p>
        </el-card>
      </el-timeline-item>
      <el-timeline-item timestamp="2018/4/2" placement="top">
        <el-card>
          <h4>更新 Github 模板</h4>
          <p>王小虎 提交于 2018/4/2 20:46</p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.taxt-name {
  font-size: 16px;
  padding: 40px 20px;
}
</style>