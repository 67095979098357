<template>
  <div class="personal_information">
    <div class="center">
      <div class="left">
        <div class="left_image">
          <div class="left_image__block">
            <el-avatar :size="100" :src="headdata.furl"></el-avatar>
          </div>
          <el-upload
            class="upload-demo"
            :action="`https://s.kplgnss.com/sso/sso/uploadPic?token=${token}`"
            accept=".jpg,.png,"
            :on-success="onProgress"
            :show-file-list="false"
            :limit="1"
          >
            <p class="authentication-upload__btn" size="small" type="primary">
              修改
            </p>
          </el-upload>
        </div>
        <div class="left_information">
          <div class="left_title" v-for="(item, index) in cs" :key="index">
            <div class="left_name">{{ item.name }}</div>
            <div class="left-information" v-if="item.id != 4">
              {{ headdata[item.id] == "" ? "--" : headdata[item.id] }}
            </div>
            <div v-else-if="item.id == 4" class="left-information">
              <div v-if="headdata.fwxstatus == -1" style="margin-right: 10px">
                -
              </div>
              <div v-if="headdata.fwxstatus == 0" class="left-icon"></div>
            </div>
            <div
              class="left-modifyname"
              v-if="index == 0"
              :style="{ background: modifydata.colordata }"
            >
              {{ modifydata.name }}
            </div>
            <div
              class="left-modify"
              v-if="index != 0 && index != 3"
              @click="modify(index)"
            >
              修改
            </div>
          </div>
        </div>
        <div
          @click="goauthentication"
          :style="{ background: authentication.colordata }"
          class="left-authentication"
        >
          {{ authentication.name }}
        </div>
      </div>
      <div class="right">
        <div class="right-head" v-for="(item, index) in cs2" :key="index">
          <div class="right-head__name">{{ item.name }}</div>
          <div
            style="display: flex; justify-content: space-between; width: 153px"
          >
            <div class="right-head__time">{{ headdata[item.time] }}</div>
            <div
              class="copy"
              v-if="index == 2"
              @click="copyOrderId2(headdata[item.time])"
            ></div>
          </div>

          <!-- <div class="right-head__time">{{ item.time }}</div> -->
        </div>

        <div class="right-foot">
          <el-button plain v-if="headdata.ftype?.name != '普通用户'">
            <a :href="headdata['redirect-url'] + '?token=' + token">账号管理</a>
          </el-button>
        </div>
        <div class="right-foot__retreat" @click="signout">退出登录</div>
      </div>
    </div>
  </div>
</template>
<script>
import { networkConfig } from "../../axios/networkConfig";
import { UploadPic } from "../../axios/modules/officialwebsite.js";
import Clipboard from "clipboard";
import { getCookie } from "../../utils/auth";
export default {
  data() {
    return {
      imgurl: networkConfig.personal + "sso/uploadPic",
      headdata: [],
      username: "",
      authentication: {},
      modifydata: {},
      token: "",
      cs: [
        {
          id: "fid",
          name: "账号ID：",
        },
        {
          id: "ftel",
          name: "手机号：",
        },
        {
          id: "fname",
          name: "昵称：",
        },
        {
          id: "4",
          name: "微信：",
        },
      ],
      cs2: [
        {
          name: "注册时间：",
          time: "fcreatetime",
        },
        {
          name: "上次登陆时间：",
          time: "flogintime",
        },
        {
          name: "邀请码:",
          time: "fpool",
        },
      ],
    };
  },
  mounted() {
    this.token = getCookie(getCookie("username"));
    // this.username = getCookie("username");
    // console.log();
  },
  methods: {
    // 上传按钮
    onProgress(response, file) {
      // console.log(this.headdata.furl);
      // const { fpicurl } = await response;

      this.headdata.furl = response.fpicurl;

      // console.log(URL.createObjectURL(file.raw));
      //设置延迟执行
      // this.UploadPicass();
    },
    // async UploadPicass() {
    //   let { code, fpicurl } = await UploadPic();

    //   if (code == 0) {
    //     // this.imageUrl = fpicurl;
    //     return;
    //   }
    // },
    // 跳转认证
    goauthentication() {
      if (this.authentication.name == "未认证") {
        this.$router.push({ path: "/personal_center/authentication" });
      }
    },
    modify(index) {
      this.$emit("modify", index);
    },
    copyOrderId2(orderId) {
      var input = document.createElement("input"); // 创建input对象
      input.value = orderId; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
      this.$message({
        offset: 150,
        message: "复制成功",
        type: "success",
      });
    },

    signout() {
      this.$emit("signout");
    },
  },
};
</script>
<style lang="scss" scoped>
.personal_information {
  width: 100%;
  height: 100%;

  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}
.center {
  width: 100%;
  height: 210px;
  position: relative;
  // background: red;
  display: flex;

  .left {
    flex: 1;
    display: flex;
    // 上传头像
    .left_image {
      text-align: center;
      .left_image__block {
        margin: 33px 30px 12px 40px;
      }
    }
    .left_information {
      display: flex;
      flex-direction: column;
      margin: auto 0;
      justify-content: space-between;
      // align-items: center;
      height: 155px;
    }
    .left_title {
      display: flex;
      align-items: center;
      height: 22px;
      .left_name {
        padding-left: 30px;
        width: 60px;
        font-size: 14px;

        color: #333333;
      }
      .left-information {
        padding: 7px 20px;
        width: 135px;
        font-size: 14px;

        font-weight: 400;
        color: #333333;
      }
      .left-icon {
        height: 10px;
        background: url("../../assets/grwxicon.png") no-repeat;
        background-size: contain;
        padding: 7px 20px;
        width: 135px;
        font-size: 14px;

        font-weight: 400;
        color: #333333;
      }
      .left-modify {
        // padding: 7px 20px;
        width: 64px;
        height: 28px;
        font-size: 14px;

        font-weight: 400;
        color: #004598;
        cursor: pointer;
      }
      .left-modifyname {
        width: 64px;
        height: 28px;
        font-size: 14px;
        text-align: center;
        line-height: 28px;
        font-weight: 400;
        color: #fff;

        border-radius: 1px 1px 1px 1px;
      }
    }
  }

  .left-authentication {
    width: 64px;
    height: 28px;
    font-size: 14px;
    text-align: center;
    line-height: 28px;
    font-weight: 400;
    color: #fff;
    border-radius: 1px 1px 1px 1px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 25px;
  }
  .right {
    margin-top: 15px;
    flex: 1;
    .right-head {
      width: 251px;
      // padding: 15px 80px;
      display: flex;
      // justify-content: space-between;
      .right-head__name {
        width: 98px;
        font-size: 14px;
        padding: 7px 0;
      }
      .right-head__time {
        font-size: 14px;
        padding: 7px 0;
      }
      .copy {
        margin: 7px 0;
        width: 20px;
        height: 20px;
        background: url("../../assets/copy.png") no-repeat;
        background-size: contain;
        cursor: pointer;
      }
    }
  }
  .right-foot {
    width: 375px;
    padding-top: 7px;

    .el-button {
      width: 166px;
      height: 44px;
      background: #ff6031;
      font-size: 18px;
      color: #fff;
    }
  }
  .right-foot__retreat {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 56px;

    color: #004598;
    font-size: 14px;
    cursor: pointer;
  }
}
// 去除样式
a {
  text-decoration: none;
  color: #fff;
}
// 鼠标放上去
.authentication-upload__btn {
  cursor: pointer;
}
</style>