<template>
  <div class="DialogChange">
    <el-dialog
      title="修改昵称"
      :visible.sync="dialogTableVisible"
      width="20%"
      :show-close="false"
      :close-on-click-modal="false"
      style="margin-top: 220px"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="新昵称">
          <el-input v-model="usename" placeholder="请输入昵称" />
        </el-form-item>

        <el-form-item>
          <div class="dialog-foot">
            <el-button @click="dialogTableVisible = false">取 消</el-button>
            <el-button type="primary" @click="ChangePassword">修改</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import store from "../../store";
export default {
  data() {
    return {
      // 版本号
      usename: "",
      dialogTableVisible: false,
    };
  },
  methods: {
    ChangePassword(usename) {
      // this.$store.dispatch("changeNikeName", this.usename);
      // console.log(this.usename, 222);
      if (this.usename != "") {
        this.$emit("ChangePassword", this.usename);
      }
    },
  },
};
</script>

<style lang="scss">
.el-button--primary {
  background: #004598;
}
.dialog-foot {
  display: flex;
  justify-content: space-between;
  .el-button {
    width: 180px;
  }
  .el-button--primary {
    background: #004598;
  }
}
</style>