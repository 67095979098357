<template>
  <div class="news">
    <div class="news-center">
      <div v-for="(item, index) in newsdata" :key="index">
        <div class="news-center__image">
          <div class="news-center__ku">
            <img :src="imgurl + item.nimg" alt class="image" />
          </div>

          <div class="news-center__name">
            <div class="news-center__text">{{ item.ntitle }}</div>
            <div>
              <div class="news-center__time">{{ item.ndate }}</div>
              <div class="news-center__time1">{{ item.nid }}</div>
            </div>
          </div>
          <div class="news-center__title">{{ item.ndetail }}</div>
          <hr />
          <router-link :to="item.path" class="news-center__btn">
            <p>阅读详情</p>
            <i class="el-icon-arrow-right"></i>
          </router-link>
        </div>
      </div>
    </div>
    <div class="news-detaill">
      <div v-for="(item, index) in Newsname" :key="index">
        <router-link :to="item.path" class="news-detaill__name">
          {{
          item.ntitle
          }}
        </router-link>
        <p>{{ item.ndate }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { networkConfig } from "@/axios/networkConfig";
import { News, NewsDetail } from "../../axios/modules/officialwebsite.js";
export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      // newssrc: "/newsdetail",
      newsdata: "",
      timedd: [],
      Newsname: []
    };
  },

  mounted() {
    this.Newsdata();
    // this.NewsDetaildata("newsxw1");
  },
  methods: {
    // 页面数据接口
    async Newsdata() {
      let { code, data } = await News();
      var moment = require("moment");
      // console.log(data);
      if (code == 0) {
        data.list.map(item => {
          item.ndate = moment(Number(item.ndate) * 1000).format("YYYY.MM.DD");
          //   item.ntitle = item.ntitle.slice(0, 17) + "...";
        });
        this.Newsname = data.list;
        // console.log(this.Newsname);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.news {
  width: 1320px;
  height: 522px;
  margin: 0 auto;
  //   background: red;
  display: flex;
}
// 两条新闻
.news-center {
  flex: 1;
  display: flex;
  //   background: #000;
  .news-center__image {
    width: 427px;
    margin-right: 20px;
    height: 522px;
    background: #f8f8f8;

    .news-center__ku {
      width: 427px;
      height: 291px;
      border: 1px solid #ddd;
      position: relative;
      overflow: hidden;
      > img {
        width: 100%;
        height: 291px;
        position: absolute;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
    }

    .news-center__name {
      display: flex;
      justify-content: space-between;
      padding: 24px 23px 17px 18px;
      .news-center__text {
        width: 333px;

        color: #333333;
        font-size: 25px;
      }
      .news-center__time {
        color: #004598;
        font-size: 14px;
        padding: 5px 0;
      }
      .news-center__time1 {
        color: #004598;
        text-align: right;
        font-size: 30px;
      }
    }
    .news-center__title {
      width: 389.5px;
      margin: 0 auto;

      color: #999999;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-overflow: -webkit-ellipsis-lastline;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    > hr {
      width: 389.5px;
      margin: 12px auto;

      color: #999999;
      // margin:  0;
    }
    .news-center__btn {
      display: flex;
      justify-content: space-between;
      width: 398.5px;
      margin: 0 auto;
      color: #999999;
      font-size: 14px;
    }
  }
}
.news-center__ku:hover {
  .image {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    // overflow: hidden;
  }
}
// 最新新闻
.news-detaill {
  width: 426px;
  height: 100%;
  background: #f8f8f8;
  > div {
    width: 365px;
    height: 71px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 28px;

    .news-detaill__name {
      color: #333333;
      font-weight: bold;
      font-size: 16px;
    }
    > p {
      color: #999999;
      font-size: 14px;
    }
    .news-detaill__name:hover {
      color: #004598;
    }
  }
}
.router-link-active {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
</style>