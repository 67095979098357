<template>
  <div class="DialogChange">
    <el-dialog
      title="修改密码"
      :visible.sync="dialogTableVisible"
      width="20%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form
        class="phone-form"
        ref="form"
        :model="form"
        label-position="left"
        size="small"
        :rules="rules"
        v-if="!loggingStatus"
      >
        <el-form-item prop="phoneNum" :rules="rules.phoneNum" ref="phone">
          <el-input
            size="small"
            placeholder="请输入邮箱号"
            v-model="form.phoneNum"
            class="input-with-select"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="checkCode">
          <el-input
            class="check-code-box"
            size="small"
            v-model.number="form.checkCode"
            placeholder="请输入验证码"
          >
            <el-button
              :disabled="codeCd"
              size="small"
              slot="append"
              @click="handleCaptcha('form')"
              >获取验证码
              <span v-if="codeCd">({{ long }})</span>
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="custom-button"
            @click="onSubmit('form')"
            type="primary"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("邮箱号不能为空"));
      }
      // 使用正则表达式进行验证手机号码
      if (
        !/^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/.test(
          value
        )
      ) {
        callback(new Error("邮箱格式不正确"));
      }
      // 自定义校验规则 需要调用callback()函数！
      callback();
    };
    return {
      dialogTableVisible: false,
      // 登录状态
      loggingStatus: false,
      // 验证码是否CD
      codeCd: false,
      // CD长度
      long: 60,

      input: "",
      form: {
        phoneNum: null,
        checkCode: null,
      },
      rules: {
        phoneNum: [
          { required: true, validator: validatePhone, trigger: "blur" },
        ],
        checkCode: [
          { required: true, message: "验证码不能为空哦！", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 获取手机验证码
    handleCaptcha(form) {
      this.$refs[form].validateField("phoneNum", async (valid) => {
        if (!valid) {
          // 获取验证码
          //   captcha(this.form.phoneNum)
          // 开启计时
          this.codeCd = false;
          const timer = setInterval(() => {
            this.long--;
            if (this.long <= 0) {
              this.long = 60;
              this.codeCd = false;
              clearInterval(timer);
            }
          }, 1000);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
</style>