<template>
  <div class="application">
    <swiper class="swiper" ref="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in applicationdata" :key="index">
        <img :src="imgurl+item.pic_h5" class="image" />
        <div class="apptext-title">
          <img :src="imgurl+item.picon" alt class="appsvg" />
          <div class="apptext">{{ item.ptitle }}</div>
        </div>
        <div class="home-product__zg"></div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    </swiper>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { networkConfig } from "@/axios/networkConfig";
export default {
  name: "swiper-example-responsive-breakpoints",
  title: "Responsive breakpoints",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      applicationdata: [],
      swiperOption: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        // centeredSlides: true,
        spaceBetween: 20,
        // pagination: {
        //   clickable: false,
        // },
        freeMode: true,
        watchSlidesVisibility: true,
        freeModeSticky: true,
        // slidesOffsetAfter: 20,
        // slidesOffsetBefore: 10,
        // virtual: true,
        breakpoints: {
          // 1320: {
          //   slidesPerView: 4,
          //   spaceBetween: 30,
          // },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          // resize: () => {
          //   this.$refs.swiper.$swiper.changeDirection(getDirection());
          // },
        }
      }
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.application {
  width: 100%;
  margin: 0 auto;
}
.swiper-slide {
  width: 100%;
  height: 40vw;
  border: 1px solid #ddd;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  .image {
    width: 100%;

    position: absolute;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}
.swiper-slide:hover {
  .image {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);

    // overflow: hidden;
  }
  .home-product__zg {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
  }
}
.apptext-title {
  width: 100%;
  height: 100%;

  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  .appsvg {
    width: 10vw;
    height: 10vw;
    // height: 80px;
    // width: 82px;
    // height: 80px;
    // margin: 0 auto;
    // margin-top: 120px;
    margin-bottom: 5vw;
    // z-index: 99;
  }
  .apptext {
    font-size: 12px;
    color: #fff;
    text-align: center;
    z-index: 99;
  }
}

.swiper-button-prev {
  width: 45px;
  height: 45px;
  z-index: 99;

  background-image: url("../../../icons/svg/left.svg");
  background-size: contain;
}
.swiper-button-next {
  width: 45px;
  height: 45px;
  z-index: 99;
  transform: rotate(180deg);
  background-image: url("../../../icons/svg/left.svg");

  background-size: contain;
}
.swiper-button-prev:hover {
  width: 45px;
  height: 45px;
  background-image: url("../../../icons/svg/right.svg");

  transform: rotate(180deg);
  background-size: contain;
}
.swiper-button-next:hover {
  width: 45px;
  height: 45px;
  background-image: url("../../../icons/svg/right.svg");
  transform: rotate(0deg);
  background-size: contain;
}
</style>
