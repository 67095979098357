<template>
  <div class="traffic_page">
    <sidebar></sidebar>
    <!--海报-->
    <div class="bg_traffic__bao">
      <img v-lazy="imgurl + trafficimg" alt="" />
    </div>

    <h2>解决方案全景</h2>
    <p class="text1">SOLUTION PANORAMA</p>

    <div class="panorama">
      <div
        class="home-programme-one"
        v-for="(item, index) in soft_imgs"
        :key="index"
      >
        <!--    :style="{
            background: `url(${imgurl + item.purl})no-repeat`,
            'background-size': `contain`,
          }" -->
        <div>
          <div class="home-programme-img">
            <img :src="imgurl + item.psrc" alt="" />
            <div class="home-product__title">
              <!-- <div class="home-product__typename">{{ item.typename }}</div>
            <div class="home-product__bor"></div> -->
              <div class="home-product__ptitlezh">{{ item.ptitle }}</div>
              <!-- <div class="home-product__ptitleen">{{ item.ptitleen }}</div> -->
            </div>
            <P class="home-product__rut">{{ item.pdetail }}</P>
            <div class="home-product__zg"></div>
          </div>
        </div>
      </div>
    </div>
    <h2>方案优势</h2>
    <p class="text1">PROGRAM ADVANTAGES</p>
    <div class="traffc-icon"></div>

    <Contact />
    <ContactUs />

    <!-- 底部链接 -->
  </div>
</template>

<script>
import Sidebar from "../components/sidebar.vue";
import { Resolve } from "../axios/modules/officialwebsite.js";
import Contact from "../components/contact.vue";
import ContactUs from "../components/contactus.vue";
import { networkConfig } from "../axios/networkConfig";
export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      soft_imgs: [],
      trafficimg: "",
    };
  },
  components: {
    Sidebar,
    Contact,
    ContactUs,
  },
  mounted() {
    this.Resolvedata();
  },
  methods: {
    // 页面数据接口
    async Resolvedata() {
      let { code, data } = await Resolve();
      console.log(data);
      if (code == 0) {
        this.soft_imgs = data.list;
        this.trafficimg = data.fpage.psrc;
        // console.log(this.right_list_arr[0], 444);
        return;
      }
    },
    contactry() {
      this.$router.push({ path: "/contact" });
    },
  },
};
</script>
<style lang="scss" scoped>
.traffic_page {
  // margin: 0 auto;
}
.bg_traffic__bao {
  margin: 100px 0;
  height: 580px;
  // height: 30vw;
  > img {
    // margin-top: 100px;
    width: 1900px;
    height: 580px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
// .bg_traffic {
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   // margin-top: 100px;
//   width: 100%;
//   height: 65vh;

//   background: url("../assets/image/traffic/bj01.png");
//   background-size: cover;
//   background-repeat: no-repeat;
// }

h2 {
  // margin-top: 100px;
  font-size: 40px;

  font-weight: bold;

  color: #333333;
  // letter-spacing: 10px;
  text-align: center;
  margin-bottom: 25px;
}
.text1 {
  font-size: 20px;

  color: #999595;
  letter-spacing: 10px;
  text-align: center;
  margin-bottom: 61px;
}
//全景分类
.panorama {
  width: 1320px;
  margin: 0 auto;
  margin-bottom: 184px;
  display: flex;
  justify-content: space-between;

  .home-programme-img {
    width: 315px;
    height: 400px;
    // border: 1px solid #ddd;
    position: relative;
    overflow: hidden;
    border-radius: 20px;

    > img {
      position: absolute;
      width: 315px;
      height: 400px;
      -webkit-transition: 1s;
      transition: 1s;
      // overflow: hidden;
      //   -webkit-transition: 1s;
      //   transition: 1s;
      //   overflow: hidden;
    }
    .home-product__ptitlezh {
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
.home-programme-img:hover {
  > img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);

    // overflow: hidden;
  }
  .home-product__title {
    position: absolute;
    left: 30px;
    bottom: 140px;
    z-index: 99;
    animation: fadenum 1s;
  }
  // .home-product__ptitlezh {
  //   line-height: 0;
  // }
  .home-product__rut {
    position: absolute;
    left: 30px;
    bottom: 24px;
    z-index: 99;
    animation: fadenum 1s;
  }
  .home-product__zg {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 315px;
    height: 400px;
    border-radius: 20px 20px 0px 0px;
    background: rgba(0, 0, 0, 0.5);
    animation: fadenum 1s;
  }
  @keyframes fadenum {
    0% {
      transform: translateY(20px);
    }
    //   50% {
    //     transform: translateY(50px);
    //   }
  }
}
.home-product__title {
  position: absolute;
  left: 30px;
  bottom: 15px;
  animation: fadnum 1s;
  z-index: 99;
  color: #fff;
}
.home-product__rut {
  font-size: 14px;
  z-index: 99;
  color: #fff;
}
.home-product__zg {
  position: absolute;
  height: 44.43px;
  width: 100%;
  left: 0px;
  bottom: 0px;

  border-radius: 0px 0px 20px 20px;
  background: rgba(0, 0, 0, 0.5);
}
@keyframes fadnum {
  0% {
    transform: translateY(-20px);
  }
}
//方案优势
.traffc-icon {
  margin-top: 100px;
  margin: 0 auto;
  width: 1174px;
  height: 244px;
  background: url("../assets/image/traffic/traffc-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 160px;
}
// .advantage {
//   margin: 0.2rem 1.3rem;
//   min-height: 5rem;
//   padding: 0.1rem;
//   display: flex;
//   justify-content: space-between;
//   ul {
//     display: flex;
//     justify-content: space-between;
//     height: 100%;
//     width: 100%;
//     li {
//       list-style-type: none;
//       margin-right: 0.2rem;
//       img {
//         width: 2.5rem;
//         margin-left: 25%;
//       }
//       .title1 {
//         font-size: 0.2rem;
//         font-family: Microsoft YaHei;
//         font-weight: bold;
//         line-height: 0.4rem;
//         color: #333333;
//         text-align: center;
//       }
//       .title2 {
//         font-size: 0.14rem;
//         font-family: Microsoft YaHei;
//         font-weight: 400;
//         line-height: 0.28rem;
//         color: #333333;
//         text-align: center;
//       }
//     }
//   }
// }
//链接

.contacty {
  background-image: url("../assets/image/traffic/contacty.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 3.62rem;
  text-align: center;
  font-size: 0.4rem;

  font-weight: bold;
  line-height: 2.7rem;
  color: #ffffff;
  letter-spacing: 20px;
}
//相关产品推荐
// .produc {
//   margin: 0.2rem 1.3rem;
//   min-height: 5rem;
//   padding: 0.1rem;
//   display: flex;
//   justify-content: space-between;
//   ul {
//     display: flex;
//     justify-content: space-between;
//     height: 100%;
//     width: 100%;
//   }
// }
// .fixed-footer {
//   width: 100%;
//   min-height: 1rem;
//   background-color: rgba(51, 51, 51, 1);
// }
// #sticky {
//   position: sticky;
//   position: -webkit-sticky;

//   width: 0.5rem;
//   height: 1.5rem;
//   top: 4.7rem;
//   background: #0090ffff;

//   justify-content: center;
//   align-items: center;

//   color: #fff;
//   margin: -1.5rem 0 0px 18.4rem;
//   border-radius: 10px;
//   .sticky-text {
//     font-size: 0.3rem;

//     margin: 0 0px 0px 4px;
//     padding: 0;
//   }
//   .el-divider--horizontal {
//     display: block;
//     height: 1px;
//     width: 100%;
//     margin: 0px 0;
//   }

//   .sticky_Btm {
//     width: 0.4rem;
//     height: 0.4rem;
//     background-color: rgba(0, 0, 0, 0);
//     font-size: 0.2rem;
//     margin: 0 0 0.1rem 0;
//     border: none;
//     border-radius: 0px;
//     border-bottom: 1px solid #fff;
//     color: #fff;
//   }
//   .el-icon-phone-outline {
//     margin: 0px -0 0px -0.1rem;
//   }
//   .el-icon-chat-dot-square {
//     margin: 0px -0 0px -0.1rem;
//   }
// }
</style>