<template>
  <div class="center">
    <div class="topbar">
      <p @click="downLoad">下载</p>
    </div>
    <div class="app" v-for="(item, index) in ggaList" :key="index">
      <div class="app-head">
        <p>{{ item.fuptime }}</p>
        <p>第{{ item.num }}条</p>
        <p>{{ item.fhz }}Hz</p>
        <p></p>
      </div>
      <div class="bottom">{{ itemGGA(item.gga) }}</div>
    </div>
  </div>
</template>

<script>
import Head from "../components/head.vue";
import { selectdata, savemkexcel } from "@/axios/modules/ggah5.js";
import { dateFtt } from "@/utils/dateFtt";

export default {
  data() {
    return {
      ggaList: [],
      num: 0,
      id: 1,
      webSock: null,
      lockReconnect: false, //避免重复连接
      sendData: {
        __topic: "/cmd",
        cmd: "subscribe",
        channels: "/cmd/" + "36z049",
      },
    };
  },
  mounted() {
    // 调取websocket方法 写在mounted方法中
    this.initWebSocket();
    // this.VerificationCodeftel();
  },
  methods: {
    downLoad() {
      let settime = dateFtt("yyyy-MM-dd 00:00:00", new Date());
      let endtime = dateFtt("yyyy-MM-dd hh:mm:ss", new Date());
      let tiems = [settime, endtime];
      console.log(tiems);
      this.VerificationCodeftel(tiems);
    },
    itemGGA(gga) {
      const arr = gga.split("E");
      return `${arr[0]}\n E${arr[1]}`;
    },
    async VerificationCodeftel(fuptime) {
      let { url, code } = await savemkexcel(fuptime);

      if (code == 0) {
        console.log(url);
        let urls = "http://119.96.80.54:29100/";
        // this.ggaList = data;
        fetch(urls + url)
          .then((res) => res.blob())
          .then((blob) => {
            // 将链接地址字符内容转变成blob地址
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            //测试链接console.log(a.href)
            a.download = "fileName"; // 下载文件的名字
            document.body.appendChild(a);
            a.click();
          });

        return;
      }
    },
    // 发送websockwt请求
    initWebSocket() {
      let websocketUrl = "wss://s.kplgnss.com/zhgltest/ws/gga/service";
      // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
      this.webSock = new WebSocket(websocketUrl);
      this.webSock.onopen = this.webSocketOnOpen;
      this.webSock.onerror = this.webSocketOnError;
      this.webSock.onmessage = this.webSocketOnMessage;
      this.webSock.onclose = this.webSocketClose;
    },
    webSocketOnOpen() {
      console.log("WebSocket连接成功");
      //  传递参数  不需要传参就不传
      var data = JSON.stringify(this.sendData);
      console.log(data);
      this.webSocketSend(data);
      //   console.log(this.sendData);
    },
    webSocketOnMessage(e) {
      //接收数据

      //   console.log(e);
      // 将接收的数据转为json格式
      var jsonObj = JSON.parse(e.data);
      let ggalen = this.ggaList.length;

      if (ggalen > 0 && jsonObj?.list[0].gga === this.ggaList[0].gga) {
        return;
      }
      this.ggaList.splice(0, 0, jsonObj.list[0]);
      //   console.log(this.ggaList);

      jsonObj.list[0].num = ++this.num;

      if (ggalen >= 200) {
        this.ggaList.length = 200;

        // this.ggaList.splice(0, 0, jsonObj?.list[0]);
      }
    },
    webSocketClose(e) {
      console.log("断开连接", e);
      this.lockReconnect = false;
      this.reconnect();
    },
    webSocketOnError(e) {
      console.log("报错信息", e);
    },
    webSocketSend(Data) {
      //发送数据发送

      this.webSock.send(Data);
    },
    // 断开重连操作
    reconnect() {
      if (this.lockReconnect) return;
      this.lockReconnect = true;
      let _this = this;
      //没连接上会一直重连，设置延迟避免请求过多
      setTimeout(function () {
        _this.initWebSocket();
        _this.lockReconnect = false;
        // _this.isOne = 1;
      }, 2000);
    },
  },
  created() {
    console.log(this.$route.query.roleName);
  },
};
</script>

<style lang="scss" scoped>
.center {
  position: relative;
}
.app {
  padding: 16rpx 24rpx;
  border-bottom: 1px solid #9cbbdc;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  box-sizing: border-box;
}

.app-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  .bottom {
    word-break: break-word;
    font-size: 24rpx;
  }
}
.topbar {
  color: #fc4544;
  font-size: 14px;

  font-weight: 400;

  position: fixed;
  position: -webkit-sticky;
  top: 10vh;
  right: 10px;

  p {
    &:first-child {
      border-right: 1px solid #fff;
    }
    flex: 1;
    text-align: right;
  }
}
</style>
