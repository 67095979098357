<template>
  <div class="accoun_balance">
    <div class="content">
      <div class="head">
        <div class="headleft">
          <p>账户余额:</p>
          <p>账户余额:</p>
          <p>余额:</p>
        </div>
        <div class="headmiddle">
          <p>2222222</p>
          <p>222</p>
          <p>22222</p>
        </div>
        <div class="headright">
          <p>元</p>
          <p>元</p>
          <p>元</p>
        </div>
      </div>
      <div class="foot" v-if="open == false">
        <el-button size="small" round>小型按钮</el-button>
        <el-button size="small" round>小型按钮</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.accoun_balance {
  width: 100%;
  height: 100%;

  background: #fff;
}
.content {
  width: 100%;
  height: 100%;
  // background: #000;
  // padding: 20px;
  display: flex;
  flex-direction: column;
  .head {
    flex: 2.5;
    display: flex;
    // margin: 0 auto;
    margin-left: 50px;
    // background: blue;
    .headleft {
      margin-right: 40px;
      font-size: 14px;

      > p {
        margin-top: 40px;

        color: #333333;
        text-align: right;
      }
    }
    .headmiddle {
      font-size: 14px;
      margin-right: 40px;

      > p {
        margin-top: 40px;

        font-weight: bold;
        color: #333333;
      }
    }
    .headright {
      font-size: 14px;

      font-weight: bold;
      color: #333333;
      > p {
        margin-top: 40px;
      }
    }
  }
  .foot {
    flex: 1;
    margin: 0 auto;
    // background: red;
    font-size: 16px;
    margin-top: 30px;
  }
}
</style>