<template>
  <div class="xw">
    <div class="xw-center">
      <h2>CORS账号设置完成后，RTK手簿显示的各种解状态含义、原因及解决办法</h2>
      <hr />
      <div class="xw-time">
        <div class="xw-time__text">发布时间:</div>
        <div class="xw-time__text">{{ timedata }}</div>
        <div class="xw-time__text">浏览量:</div>
        <div class="xw-time__text">{{ hotdata }}次</div>
      </div>
      <img src="../../assets/newsimg/xw7/img1.jpg" alt="" />

      <div class="xw-source">（来源：网络）</div>
      <hr />
      <div style="display: flex">
        <p>上一篇:</p>
        <router-link
          v-if="lastNews.path !== undefined"
          :to="lastNews.path === '/' ? '' : lastNews.path"
          >{{ lastNews.ntitle }}</router-link
        >
      </div>
      <div style="display: flex">
        <p>下一篇:</p>
        <router-link
          v-if="nextNews.path !== undefined"
          :to="nextNews.path === '/' ? '' : nextNews.path"
          >{{ nextNews.ntitle }}</router-link
        >
      </div>
    </div>
    <div class="xw-newest">
      <Newset ref="newsetdata" />
    </div>
  </div>
</template>

<script>
import { networkConfig } from "../../axios/networkConfig";
import Newset from "./component/newest.vue";
import { News, NewsDetail } from "../../axios/modules/officialwebsite.js";
export default {
  data() {
    return {
      to: "",
      timedata: "",
      hotdata: "",
      lastNews: {},
      nextNews: {},
      imgurl: networkConfig.serverUrl + "upload/",
      Newsname: [],
    };
  },

  mounted() {
    this.Newsdata();
    this.NewsDetaildata("newsxw7");
  },
  methods: {
    // 页面数据接口
    async Newsdata() {
      let { code, data } = await News();
      var moment = require("moment");
      // console.log(data);
      if (code == 0) {
        data.list.map((item) => {
          item.ndate = moment(Number(item.ndate) * 1000).format("YYYY-MM-DD");
          item.ntitle = item.ntitle.slice(0, 17) + "...";
        });
        this.$refs.newsetdata.Newsname = data.list;
        // console.log(this.$refs.newsetdata);
      }
    },
    // 上一篇下一篇
    async NewsDetaildata(path) {
      let { code, data } = await NewsDetail(path);
      // console.log(data);
      var moment = require("moment");
      if (code == 0) {
        // console.log(data);
        this.lastNews = data.lastNews;
        this.nextNews = data.nextNews;

        this.timedata = moment(Number(data.detail.ndate) * 1000).format(
          "YYYY-MM-DD"
        );
        this.hotdata = data.detail.hot;
      }
    },
  },
  components: {
    Newset,
  },
};
</script>

<style lang="scss" scoped>
.xw {
  width: 1320px;
  margin: 0 auto;
  margin-top: 134px;

  display: flex;
}
/* 新闻内容 */
.xw-center {
  width: 912px;
  padding: 16px;
  min-height: 80vh;

  //   background: pink;
  text-align: center;

  //   > h2 {
  //     margin: 28px 0;
  //   }
  > hr {
    margin: 28px 0;
    border: 0.5px solid #e6e6e6;
  }
  > p {
    line-height: 32px;
    font-size: 16px;
    text-align: left;
    text-indent: 2em;
    padding: 20px 0;
  }
  > img {
    width: 100%;
  }
  .xw-source {
    text-align: right;
    color: #999999;

    font-size: 14px;
  }
}
.xw-time {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .xw-time__text {
    padding: 3px;
    font-size: 14px;

    color: #666666;
  }
}
.xw-newest {
  //   position: fixed;
  //   right: 13vw;
  width: 365px;
  //   padding: 16px;
  height: 500px;
  margin: 16px;
  //   background: red;
  border: 1px solid #cccccc;
}
.router-link {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
</style>