<template>
  <div>
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in imgList" :key="index">
        <img :src="imgurl + item.pic_h5" class="image" @mouseover="open" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
// import contact from "../components/contact.vue";
import { networkConfig } from "@/axios/networkConfig";

export default {
  name: "",
  title: "Autoplay",
  data() {
    return {
      imgList: [
        {
          url: require("./image/蒙版组 35@3x.jpg"),
          path: "/h5/UAV/m6"
        }
      ],
      imgurl: networkConfig.serverUrl + "upload/",

      swiperOptions: {
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 0,
        slidesPerGroup: 1,
        lazy: { loadPrevNext: true },
        pagination: {
          // spaceBetween: 0,
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true //轮播按钮支持点击
        },
        autoplay: {
          delay: 5000,
          pauseOnMouseEnter: true
        },
        // autoplayStopOnLast: true,
        on: {}
      }
    };
  },
  components: {
    swiper,
    swiperSlide
    // contact,
  },
  mounted() {
    this.$refs.mySwiper.$el.onmouseover = () => {
      console.log(this.$refs.mySwiper);
      this.$refs.mySwiper.swiper.autoplay.stop();
    };
    this.$refs.mySwiper.$el.onmouseout = () => {
      this.$refs.mySwiper.swiper.autoplay.start();
    };
  },
  methods: {
    open() {
      // console.log(this.swiperOptions.autoplay);
    }
  }
};
</script>

<style lang="scss">
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;

  .image {
    width: 100%;
    height: 100%;
    // background-size: cover;
  }
}
// 轮播图按钮
.swiper-pagination {
  // background: red;
  .swiper-pagination-bullet {
    background: #fff;

    border-radius: 50%;
    width: 15px;
    height: 15px;
  }
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 10px;
}
</style>
