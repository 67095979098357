<template>
  <div class="full-screen">
    <div class="personal_center">
      <div class="head">个人中心</div>

      <div class="center">
        <div class="left" v-if="close">
          <div class="left_head">
            <AccountBalance />
          </div>
          <div class="lett_foot">
            <RunningRecord />
          </div>
        </div>
        <div class="right">
          <div class="right_head">
            <PersonalInformation ref="PersonalInformation" @signout="signout" @modify="modify" />
          </div>
          <div class="right_foot">
            <SecuritySetting @opendia="SecuritySettingdia" ref="SecuritySetting" />
          </div>
        </div>
      </div>
    </div>
    <DialogChange ref="DialogChange" @Passwordmodification="Passwordmodification" />
    <PhoneBinding ref="PhoneBinding" @onSubmit="onSubmit" @handleCaptcha="handleCaptcha" />
    <OperationProtection ref="OperationProtection" />
    <MailboxBinding ref="MailboxBinding" />
    <RetentionTime ref="RetentionTime" @centerDialogVisible="centerDialogVisible" />
    <NickNamedia ref="NickNamedia" @ChangePassword="ChangePassword" />
  </div>
</template>

<script>
// import PersonalInformation from "./personal-information.vue";
import AccountBalance from "./accoun-balance.vue";
import PersonalInformation from "./personal-information.vue";
import RunningRecord from "./running-record.vue";
import SecuritySetting from "./security-setting.vue";
import DialogChange from "./dialogchange-password.vue";
import PhoneBinding from "./Mobile-phone-binding.vue";
import OperationProtection from "./operation-protection.vue";
import MailboxBinding from "./mailbox-binding.vue";
import RetentionTime from "./retention-time.vue";
import NickNamedia from "./nicknamedia.vue";
import {
  Personal,
  Password,
  VerificationCode
} from "../../axios/modules/personals.js";
import { setCookie, getCookie } from "@/utils/auth";
import base from "@/utils/base64";
import store from "../../store";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      close: false,
      nikeName: "",
      MailboxBindingvisible: false
    };
  },
  methods: {
    // 获取验证码
    handleCaptcha(phoneNum) {
      // console.log(phoneNum);
      this.VerificationCodeftel(phoneNum);
    },
    async VerificationCodeftel(ftel) {
      let { code } = await VerificationCode(ftel);

      if (code == 0) {
        return;
      }
    },
    // 修改昵称
    ChangePassword(usename) {
      // console.log(usename);

      this.Passwordpass("", "", "", "", usename);
      this.timer = setTimeout(() => {
        //设置延迟执行
        this.Personaldata();
      }, 500);

      this.$refs.NickNamedia.dialogTableVisible = false;
    },
    // 更换手机号
    onSubmit(phoneNum, checkCode) {
      this.Passwordpass("", "", phoneNum, checkCode);
      this.timer = setTimeout(() => {
        //设置延迟执行
        this.Personaldata();
      }, 500);
      this.$refs.PhoneBinding.dialogTableVisible = true;
    },
    // 修改限制登录时常
    centerDialogVisible(value) {
      // console.log(value);
      this.Passwordpass("", value, "", "");

      this.timer = setTimeout(() => {
        //设置延迟执行
        this.Personaldata();
      }, 500);

      this.$refs.RetentionTime.dialogTableVisible = false;
    },
    // 修改密码
    Passwordmodification(newPassword, confirmPassword) {
      var base1 = new base();
      // console.log(newPassword, 1);
      newPassword = base1.encode(newPassword);
      // console.log(newPassword, 2);
      this.Passwordpass(newPassword, "", "", "");
      this.ChangePassword();
    },
    // 退出登录
    signout() {
      setCookie(getCookie("username"), "");

      this.$router.push({ path: "/home/index" });
    },
    // 个人信息
    async Personaldata() {
      let res = await Personal();
      if (res.code == 0) {
        if (res.ftype == 0) {
          res.ftype = { name: "普通用户", colordata: "#004598" };
        } else if (res.ftype == 1) {
          res.ftype = { name: "管理员", colordata: "#FF6031" };
        } else if (res.ftype == 5) {
          res.ftype = { name: "超级用户", colordata: "#FFAD41" };
        } else if (res.ftype == 2) {
          res.ftype = { name: "代理商", colordata: "#999999" };
        }
        if (res.fente == 0) {
          res.fente = { name: "认证中", colordata: "#004598" };
        } else if (res.fente == 1) {
          res.fente = { name: "已认证", colordata: "#0080FF" };
        } else if (res.fente == -1) {
          res.fente = { name: "未认证", colordata: "#999999" };
        }
        if (res.flevel == 1) {
          res.flevel = { name: "低", colordata: "#FF6031" };
        } else if (res.flevel == 2) {
          res.flevel = { name: "中", colordata: "#FFAD41" };
        } else if (res.flevel == 3) {
          res.flevel = { name: "高", colordata: "#8FC63A" };
        }
        res.fvaldate = Number(res.fvaldate) / 60;
        // console.log(res.fvaldate);
        // console.log(res);
        this.$refs.PersonalInformation.headdata = res;
        this.$refs.PersonalInformation.modifydata = res.ftype;
        this.$refs.PersonalInformation.authentication = res.fente;
        this.$refs.SecuritySetting.gradedata = res.flevel;
        this.$refs.SecuritySetting.footdata = res;
        this.nikeName = res.fname;
        // console.log(nikeName);
        this.$store.commit("set_nikeName", this.nikeName);
        // console.log(this.$refs.DialogChange.gradedata);
      }
      // else if (res.code == -1) {
      //   setCookie(getCookie("username", ""));
      //   this.$router.push({ path: "/homefirst" });
      // }
      // console.log(fid);
    },
    SecuritySettingdia(index) {
      if (index == 0) {
        this.$refs.DialogChange.dialogTableVisible = true;
      } else if (index == 1) {
        this.$refs.PhoneBinding.dialogTableVisible = true;
      } else if (index == 2) {
        this.$refs.RetentionTime.dialogTableVisible = true;
      } else if (index == 3) {
        this.$refs.MailboxBinding.visible = true;
      } else if (index == 4) {
        this.$refs.OperationProtection.dialogTableVisible = true;
      }
    },
    // 修改密码

    async Passwordpass(fpass, fvaldate, ftel, fcode, fname) {
      let { code } = await Password(fpass, fvaldate, ftel, fcode, fname);

      if (code == 0) {
        //   setCookie(getCookie("username"), "");
        this.$refs.DialogChange.dialogTableVisible = false;
        //   return;
      }
    },

    // 头部两个修改
    modify(index) {
      if (index == 1) {
        this.$refs.PhoneBinding.dialogTableVisible = true;
      } else if (index == 2) {
        this.$refs.NickNamedia.dialogTableVisible = true;
      }
    }
  },
  mounted() {
    this.Personaldata();
  },

  components: {
    AccountBalance,
    PersonalInformation,
    SecuritySetting,
    RunningRecord,
    DialogChange,
    PhoneBinding,
    OperationProtection,
    MailboxBinding,
    RetentionTime,
    NickNamedia
  }
};
</script>
<style lang="scss" scoped>
.full-screen {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: #f5f5f5;
}
.personal_center {
  width: 1320px;
  // height: 98vh;

  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}

.head {
  flex: 1;
  font-size: 14px;
  line-height: 80px;
}
.center {
  flex: 20;
  display: flex;
  //   background: red;

  //   左侧
  .left {
    flex: 1;

    display: flex;
    flex-direction: column;

    .left_head {
      flex: 1;
    }
    .lett_foot {
      flex: 3.5;
      background: #fff;
      margin-top: 30px;
    }
  }
  //   右侧
  .right {
    flex: 3.5;

    display: flex;
    flex-direction: column;
    margin-left: 20px;
    .right_head {
      flex: 1;
    }
    .right_foot {
      flex: 3.5;
      margin-top: 30px;
    }
  }
}
</style>
