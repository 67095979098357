<template>
  <div class="EV260">
    <Sidebar />
    <!-- 头部盒子产品名 -->
    <div class="EV260-bottom">
      <div class="EV260-head">
        <div class="EV260-head__title">
          <p class="EV260-head__text1">{{ uavname }}</p>
          <p class="EV260-head__text2">{{ uavpdec }}</p>
        </div>
      </div>
    </div>

    <!-- 产品展示页面 -->
    <div class="EV260-introduction">
      <div class="EV260-introduction__head">
        <div class="EV260-introduction__name">
          <div class="EV260-introduction__headimg"></div>
          <div class="EV260-introduction__headtitle">
            <p class="EV260-introduction__text1">{{ Subtitlehead.text1 }}</p>
            <p class="EV260-introduction__text2">{{ Subtitlehead.text2 }}</p>
          </div>
        </div>
      </div>
      <!-- 产品展示图片 -->
      <div class="EV260-image">
        <div>
          <div class="EV260-small__img">
            <img v-lazy="imgurl + uavsm_pic" alt />
          </div>
          <!-- <div class="EV260-small__img" @click="switchimg((pic = 1))">
              <img v-lazy="imgurl + uavsm_pic[1]" alt="" />
          </div>-->
        </div>

        <div class="EV260-big__img">
          <img v-lazy="imgurl + uavlg_pic" alt />
        </div>
        <!-- <div class="EV260-big__img" v-else-if="picimg == false">
            <img v-lazy="imgurl + uavlg_pic[1]" alt="" />
        </div>-->
        <div class="EV260-introduction__text">{{ uavpdetail }}</div>
      </div>
    </div>
    <!-- 产品参数 -->
    <div style="height: 520px">
      <div class="EV260-product">
        <div class="EV260-product__center">
          <div class="EV260-introduction__head">
            <div class="EV260-introduction__name">
              <div class="EV260-introduction__headimg"></div>
              <div class="EV260-introduction__headtitle">
                <p class="EV260-introduction__text1">{{ Subtitlecharacteristic.text1 }}</p>
                <p class="EV260-introduction__text2">{{ Subtitlecharacteristic.text2 }}</p>
              </div>
            </div>
          </div>
          <img v-lazy="imgurl + uavpchar" alt />
        </div>

        <!-- 参数图片 -->
      </div>
    </div>

    <div class="EV260-center__image">
      <div class="EV260-introduction__head">
        <div class="EV260-introduction__name">
          <div class="EV260-introduction__headimg"></div>
          <div class="EV260-introduction__headtitle">
            <p class="EV260-introduction__text1">{{ Subtitlepps.text1 }}</p>
            <p class="EV260-introduction__text2">{{ Subtitlepps.text2 }}</p>
          </div>
        </div>
      </div>
      <img v-lazy="imgurl + uavppara" alt />
    </div>
    <div class="EV260-center__image"></div>
    <!-- 应用场景 -->
    <!-- <div>
        
        <div class="EV260-scenario">
          <div v-for="(item, index) in uavpapl" :key="index">
            <img style="width: 99%" :src="imgurl + item.pic" alt="" />
            <p style="text-align: center; margin: 20px 0">{{ item.name }}</p>
          </div>
        </div>
    </div>-->

    <!-- 相关文档 -->
    <div class="EV260-related" v-if="uavpword!==undefined&&uavpword.length>0">
      <div>
        <div class="EV260-introduction__head">
          <div class="EV260-introduction__name">
            <div class="EV260-introduction__headimg"></div>
            <div class="EV260-introduction__headtitle">
              <p class="EV260-introduction__text1">{{ Subtitlefile.text1 }}</p>
              <p class="EV260-introduction__text2">{{ Subtitlefile.text2 }}</p>
            </div>
          </div>
        </div>
        <div class="EV260-related__center">
          <div class="EV260-related__buy" v-for="(item, index) in uavpword" :key="index">
            <div class="EV260-related__text">{{ item.name }}</div>

            <div class="EV260-related__bt">
              <i class="el-icon-download">
                <a :href="imgurl + item.src" class="fl-item-a">立即下载</a>
              </i>
            </div>
          </div>
          <!-- <div class="EV260-related__download">
              <div class="EV260-related__text">售后文档</div>
              <el-button><i class="el-icon-download">立即下载</i></el-button>
          </div>-->
        </div>
      </div>
    </div>
    <!-- 如何购买 -->
    <div class="EV260-how">
      <div class="EV260-introduction__head">
        <div class="EV260-introduction__name">
          <div class="EV260-introduction__headimg"></div>
          <div class="EV260-introduction__headtitle">
            <p class="EV260-introduction__text1">{{ Subtitlebuy.text1 }}</p>
            <p class="EV260-introduction__text2">{{ Subtitlebuy.text2 }}</p>
          </div>
        </div>
      </div>
      <buy />
    </div>

    <!-- 底部链接 -->
    <!-- <div class="fixed-footer">
        <MyFooter />
    </div>-->
  </div>
</template>
  
  <script>
// import MyFooter from "../components/footer.vue";
// import { computed, components } from "vue";
import { networkConfig } from "../../axios/networkConfig";

import buy from "../../components/buy.vue";
import Sidebar from "../../components/sidebar.vue";
export default {
  data() {
    return {
      picimg: true,
      uavname: "",
      uavpdec: "",
      uavsm_pic: "",
      uavlg_pic: "",
      uavppara: "",
      uavpword: "",
      uavpdetail: "",
      uavpchar: "",
      uavpli: "",
      uavpapl: [],
      Subtitlehead: {
        text1: "简介",
        text2: "BRIEF INTRODUCTION"
      },
      Subtitlecharacteristic: {
        text1: "功能介绍",
        text2: "FUNCTION INTRODUCTION"
      },
      Subtitlepps: {
        text1: "产品购买服务",
        text2: "PRODUCT PURCHASE SERVICE"
      },
      Subtitlebuyiey: {
        text1: "产品清单",
        text2: "INVENTORY"
      },
      Subtitlefile: {
        text1: "相关文档",
        text2: "RELATED DOCUMENTS"
      },
      Subtitlebuy: {
        text1: "如何购买",
        text2: "HOW TO BUY"
      },
      imgurl: networkConfig.serverUrl + "upload/"
    };
  },
  methods: {
    // switchimg(pic) {

    stationData(code, data) {
      if (code == 0) {
        // console.log(data);
        this.uavname = data.ptitle;
        this.uavpdec = data.pdec;
        this.uavsm_pic = data.sm_pic;
        this.uavlg_pic = data.lg_pic;
        this.uavpword = data.pword;
        this.uavppara = data.pserve;
        this.uavpdetail = data.pdetail;
        this.uavpchar = data.pfunc;
        // this.uavpapl = data.papl.list;
        this.uavpli = data.plist;
        console.log(data.plist);

        return;
      }
    }
  },
  mounted() {},
  components: {
    buy,
    Sidebar
  }
};
</script>
  <style lang="scss" scoped>
// 页面整体

.EV260 {
  width: 1320px;
  margin: 0 auto;
}
// 产品名底层盒子

.EV260-bottom {
  height: 390px;
}
// 产品头部产品名
.EV260-head {
  width: 1900px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background: linear-gradient(180deg, #ffffff 0%, #f2f6ff 100%);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  .EV260-head__title {
    width: 1320px;
    margin: auto;
    text-align: left;
    padding: 100px 0;
    .EV260-head__text1 {
      font-size: 60px;
      font-weight: bold;
      color: #333333;
      line-height: 150px;
    }
    .EV260-head__text2 {
      font-size: 30px;

      // font-weight: 400;
      color: #333333;
      // line-height: 30px;
    }
  }
}
// 产品展示
.EV260-introduction {
  width: 100%;
  margin-top: 100px;
}
.EV260-image {
  width: 100%;

  // margin: auto;
  display: flex;

  align-items: center;

  margin-top: 40px;
  margin-bottom: 101px;

  .EV260-small__img {
    width: 108px;

    margin-right: 20px;

    margin-top: 10px;
  }
  .EV260-big__img {
    // width: 100%;
  }
  .EV260-introduction__text {
    padding: 40px;
    line-height: 44px;
    font-size: 22px;
  }
}
// 产品参数图
.EV260-product {
  width: 1900px;
  margin: 0 auto;

  height: 520px;
  position: absolute;
  left: 50%;
  // align-items: center;

  transform: translateX(-50%);
  .EV260-product__center {
    width: 1320px;
    height: 100%;

    margin: 0px auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
      width: 900px;
    }
  }
}
.EV260-center__image {
  width: 1320px;
  margin: 0px auto;
  background-size: contain;

  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    margin: 0px auto;
    width: 930px;
  }
}
// 应用场景
.EV260-scenario {
  display: flex;
  margin-top: 96px;
  justify-content: space-between;
}
// 相关文档
.EV260-related {
  width: 1320px;

  .EV260-related__center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .EV260-related__buy {
    width: 49%;
    margin: 50px 0;
    border: 1px solid #cccccc;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .EV260-related__text {
      padding: 40px;
      font-size: 16px;
    }
    .EV260-related__bt {
      width: 15%;
      height: 30%;
      padding: 20px;
      margin-right: 20px;
      text-align: center;
      // color: #004598;
      border: 1px solid #004598;
    }
    .fl-item-a {
      font-size: 20px;
      text-decoration: none;
      color: #004598;
    }
  }
}
.EV260-how {
}
.EV260-introduction__head {
  width: 100%;
  // height: 130px;
  margin: 40px 0;
}

.EV260-introduction__name {
  display: flex;
  // align-items: center;
  // margin-top: 250px;

  .EV260-introduction__headimg {
    width: 18px;
    height: 66px;
    background-image: url(../../assets/image/aircraft/bt1.jpg);
    background-size: 100% 100%;
  }
  .EV260-introduction__headtitle {
    margin-left: 23px;
    text-align: left;
    .EV260-introduction__text1 {
      font-size: 25px;
      margin-bottom: 14px;
      // font-weight: 400;
      color: #333333;
      // line-height: 28px;
    }
    .EV260-introduction__text2 {
      font-size: 14px;

      // font-weight: 400;
      color: #666666;
      // line-height: 28px;
      
    }
  }
}
</style>