<template>
  <div class="contacty-nul">
    <div class="contacty" @click="contactry">联系我们，了解更多></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    contactry() {
      this.$router.push({ path: "/contact" });
    }
  }
};
</script >
<style lang="scss" scoped>
.contacty-nul {
  // width: 1900px;
  height: 264px;
  margin-top: 180px;
}
.contacty {
  width: 1900px;
  height: 264px;
  background-image: url("../assets/image/contacty.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;

  font-size: 50px;

  font-weight: bold;
  line-height: 264px;
  color: #ffffff;
  letter-spacing: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>