<template>
  <div class="home-programme">
    <div class="home-programme-one" v-for="(item, index) in resolvedata" :key="index">
      <router-link :to="'/h5'+item.path">
        <div class="home-product__center">
          <el-image lazy class="images" :src="imgurl + item.pic_h5" alt />
          <div class="home-product__title">
            <div class="home-product__typename">{{ item.typename }}</div>
            <div class="home-product__bor"></div>
            <div class="home-product__ptitlezh">{{ item.ptitlezh }}</div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import CarouseLimg from "./Carouselimg.vue";
import { networkConfig } from "@/axios/networkConfig";

export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",

      resolvedata: []
    };
  },
  components: {
    // swiper,
    // swiperSlide,
    // contact,
  }
};
</script>
<style lang="scss" scoped>
// 推荐产品
// 解决方案
.home-programme {
  width: 100%;
  margin: 0 auto;
}
.home-product__one {
  width: 100%;
}
.images {
  width: 100%;
  // margin: 2%;
}
.home-product__center {
  position: relative;
  width: 100%;
}
.home-product__title {
  position: absolute;
  bottom: 6px;
  left: 2%;
  color: #ffffff;
}
.home-product__bor {
  margin: 3px 0;
  border: 1px solid #ffff;
  background: #fff;

  border-radius: 4px;
  width: 30px;
}
.home-product__typename {
  font-size: 12px;
  color: #ffffff;
}
.home-product__ptitlezh {
  color: #ffffff;
  font-size: 10px;
}
</style>
