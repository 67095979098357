<template>
  <div class="xw">
    <div class="xw-center">
      <h2>欧洲计划建立低轨卫星星座，以实现超高精度定位服务</h2>
      <hr />
      <div class="xw-time">
        <div class="xw-time__text">发布时间:</div>
        <div class="xw-time__text">{{ timedata }}</div>
        <div class="xw-time__text">浏览量:</div>
        <div class="xw-time__text">{{ hotdata }}次</div>
      </div>
      <p>
        普通人可能对不同轨道之间的距离很难理解。例如，国际空间站距离地球约400公里，而一些卫星，如星链（Starlink），轨道约550公里。这样做通常是有意为之的，因为在这些轨道上的物体最终会退化它们的轨道，并在地球大气层中烧毁。然而，许多系统的轨道要高出几个数量级
        ——
        例如，构成欧盟卫星导航网络主干的伽利略（Galileo）卫星。在大约23000公里的轨道上，它与悬挂较低的卫星相比有一些优势，但也有很多缺点。现在，欧盟将通过发布一套全新的低轨道导航卫星来消除这些劣势。
      </p>
      <p>
        其中的一些优势是非常明显的。虽然，伽利略和类似的 GNSS
        系统几乎可以覆盖地球上的任何地方，但它们并不是特别准确。当你只是使用手机应用程序导航时，几米左右的误差距离并不是什么大问题。但对于智能城市，甚至是真正的无人驾驶汽车等应用来说，精确度必须达到厘米量级，而不是米，才能有效。
      </p>

      <img src="../../assets/newsimg/xw1/img1.png" alt="" />
      <p>
        大型 GNSS
        卫星的另一个缺点是它们的工作频率。它使用L波段，在电磁波谱上介于1-2
        GHz之间。然而，在某些情况下，例如在混凝土建筑中，这些波段并不管用。
      </p>

      <p>
        例如，在工业物联网的很多场景中，机器人需要知道自己在混凝土建筑中的位置。因此，拥有一组工作频率范围更广的小型卫星，就可以在这些情况下从较低的卫星子集获得精确的定位数据。
      </p>
      <p>
        更重要的是，卫星技术最近有了很大的进步，可以让伽利略等导航星座的基础设施支持这些新星座，甚至会进一步增加它们的技术优势。例如，任何新的卫星都不需要安装昂贵而笨重的原子钟，因为它们可以像任何其他GPS接收器一样，只需从在其上方运行的地球同步卫星接收准确的时间戳。
      </p>
      <p>
        消除其中的一些冗余，并为特定的频率和应用制造专门的设备，将使较低轨道的卫星系统的大小仅为伽利略的十分之一，伽利略的重量为700公斤。由于发射成本是许多此类技术商业采用的一个重大限制，降低卫星发射重量是任何设计团队的主要目标之一。
      </p>
      <p>
        商业适用性似乎是开发这些新系统的努力的核心焦点之一。欧盟在卫星系统开发方面已经处于领先地位，而且显然希望保持这一地位。随着这一新的努力将一系列改进的导航卫星送入轨道，该区块及其太空机构欧洲航天局（ESA）
      </p>
      <div class="xw-source">（来源：网络）</div>
      <hr />
      <div style="display: flex">
        <p>上一篇:</p>
        <router-link
          v-if="lastNews.path !== undefined"
          :to="lastNews.path === '/' ? '' : lastNews.path"
          >{{ lastNews.ntitle }}</router-link
        >
      </div>
      <div style="display: flex">
        <p>下一篇:</p>
        <router-link
          v-if="nextNews.path !== undefined"
          :to="nextNews.path === '/' ? '' : nextNews.path"
          >{{ nextNews.ntitle }}</router-link
        >
      </div>
    </div>
    <div class="xw-newest">
      <Newset ref="newsetdata" />
    </div>
  </div>
</template>

<script>
import { networkConfig } from "../../axios/networkConfig";
import Newset from "./component/newest.vue";
import { News, NewsDetail } from "../../axios/modules/officialwebsite.js";
export default {
  data() {
    return {
      to: "",
      timedata: "",
      hotdata: "",
      lastNews: {},
      nextNews: {},
      imgurl: networkConfig.serverUrl + "upload/",
      Newsname: [],
    };
  },

  mounted() {
    this.Newsdata();
    this.NewsDetaildata("newsxw6");
  },
  methods: {
    // 页面数据接口
    async Newsdata() {
      let { code, data } = await News();
      var moment = require("moment");
      // console.log(data);
      if (code == 0) {
        data.list.map((item) => {
          item.ndate = moment(Number(item.ndate) * 1000).format("YYYY-MM-DD");
          item.ntitle = item.ntitle.slice(0, 17) + "...";
        });
        this.$refs.newsetdata.Newsname = data.list;
        // console.log(this.$refs.newsetdata);
      }
    },
    // 上一篇下一篇
    async NewsDetaildata(path) {
      let { code, data } = await NewsDetail(path);
      // console.log(data);
      var moment = require("moment");
      if (code == 0) {
        // console.log(data);
        this.lastNews = data.lastNews;
        this.nextNews = data.nextNews;

        this.timedata = moment(Number(data.detail.ndate) * 1000).format(
          "YYYY-MM-DD"
        );
        this.hotdata = data.detail.hot;
      }
    },
  },
  components: {
    Newset,
  },
};
</script>

<style lang="scss" scoped>
.xw {
  width: 1320px;
  margin: 0 auto;
  margin-top: 134px;

  display: flex;
}
/* 新闻内容 */
.xw-center {
  width: 912px;
  padding: 16px;
  min-height: 80vh;
  //   background: pink;
  text-align: center;

  //   > h2 {
  //     margin: 28px 0;
  //   }
  > hr {
    margin: 28px 0;
    border: 0.5px solid #e6e6e6;
  }
  > p {
    line-height: 32px;
    font-size: 16px;
    text-align: left;
    text-indent: 2em;
    padding: 20px 0;
  }
  > img {
    width: 100%;
  }
  .xw-source {
    text-align: right;
    color: #999999;

    font-size: 14px;
  }
}
.xw-time {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .xw-time__text {
    padding: 3px;
    font-size: 14px;

    color: #666666;
  }
}
.xw-newest {
  // position: fixed;
  right: 23vw;
  width: 365px;
  //   padding: 16px;
  height: 500px;
  margin: 16px;
  //   background: red;
  border: 1px solid #cccccc;
}
.router-link {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
</style>