<template>
  <div class="xw">
    <div class="xw-center">
      <h2>「技术」GPS RTK技术在工程施工放样中的应用</h2>
      <hr />
      <div class="xw-time">
        <div class="xw-time__text">发布时间:</div>
        <div class="xw-time__text">{{ timedata }}</div>
        <div class="xw-time__text">浏览量:</div>
        <div class="xw-time__text">{{ hotdata }}次</div>
      </div>
      <p>施工放样是指工程中将设计图纸上的建筑物，按照一定的方法，在实地中测设出来的测量工作，实际过程是将图纸上的方案具体定位到实际现场。在实际放样的过程中，工程建筑物具有复杂多样的特点，常常需要将多种方法综合应用，才能将对应建筑物的点、线定位出来。放样方法需要根据工程建筑物的类型、施工方法、施工部位、施工条件以及控制点的分布和放样的精度要求来选择。因此，放样人员需要丰富的经验来选择放样点的测设方法和测站点的不同组合，在这个过程中，需要考虑精度要求、计算工具、现有仪器、控制点分布、现场条件等。</p>
      <p>放样数据在传统的工程放样中十分重要，需放出施工图中的放样点或线相对于原有建筑的几何关系，即求出对应的距离、高程、角度，再通过传统水准仪、经纬仪等仪器把点位和高程测出。传统放样方法精度不高、效率低下，这种方法无法满足日益发展的工程建设需要。随着测绘科技的发展，发明出全站仪，测角和量距可以同时进行。坐标放样法出现后，连接计算机即可计算点位坐标。坐标放样法可直接获取放样点的坐标，这样就省去了求取放样数据这道麻烦程序。坐标放样法精度和稳定性都有所提高，降低了过程误差。然而RTK技术的出现，无疑是工程放样史上的一次巨大变革。RTK是GPS发展的最新成果，它弥补GPS原有的不足之处，具有耗时短，精度高等优点，在作业过程可实时获得厘米级定位精度，而且它省去了布置控制点这一环节，为工程节省了大量经费，精力，时间，作业过程中无需通视，而且还可以单人作业，为放样工作带来极大的方便。</p>

      <p>
        如今，GPS
        RTK技术已经广泛应用于工程施工放样，为测绘工作者带来极大的便利，同时RTK技术的缺陷也开始暴露出来。比如RTK放样在12:00～13:00时间段易受电离层干扰，设备初始化时间过长甚至无法初始化，且在遮挡物环境下（如城镇密楼区、山区、林区）作业时，GPS卫星信号强度低；稳定性不高，容易受卫星状况、天气状况、数据链传输状况影响；相对于全站仪等传统放样技术，其精度还有待提高等。发现RTK技术的缺陷以及针对这些缺陷作出改进，是目前RTK研究的主要任务。
      </p>
      <h3>一全站仪和RTK技术的比较</h3>
      <h4>1. 工程放样方法的原理</h4>
      <p>全站仪能够很快测量出点与点之间的方位角和距离，因此很容易通过已知点和待测点的相对关系，将带放样点在实地放样出来，RTK主要有三部分组成，即基准站、流动站和软件包。RTK作业模式主要是实现坐标信息与观测值在基准站接收机与流动站接收机间的传递，流动站接收机将接收数据与自身采集的GPS观测数据组成差分观测值，对基线进行实时求解，解算出流动站相对放样点的位置关系，指导我们进行放样。</p>
      <h4>2. 放样方法过程的比较</h4>
      <p>放样的过程，就是将设计图纸上的点位房屋建筑，按照一定的方法，在实地中测设出来的测量工作。不管采取的是哪一种放样技术和方法，都是要达到将点位在实地标识出来的目的。全站仪放样时要求在放样点周围有两个或以上相互通视的控制点。如果控制点缺失，则需要用在放样点周围测设出两个相互通视的已知控制点，将全站仪架设于一个已知控制点上，并以另一个已知控制点作为后视方向点，将全站仪水平角读数设置为0，然后才可根据放样数据实施放样。在放样过程中，调节前视的距离和方向，拨定水平夹角，最终确定放样点的点位。</p>
      <p>RTK技术放样是在整个测区周围相对安全易于发射基准站信号的地方选择一个比较适宜的控制点架设基准站。基准站架设之后，根据准备工作阶段的情况进行数据匹配，计算确定七个转换参数，这样就可以进行点位的放样了，因此不需要已知控制点。这时，根据基准站发射的卫星信号和数据，GPS流动站实时差分解算出GPS流动站与放样点的地理方位和距离，并显示在GPS电子手簿上，通过不断调节地理方位和距离，最终放样出其点位。</p>
      <h4>3. 两种放样方法优缺点的比较</h4>
      <p>全站仪作为长期使用的放样仪器，固然有其成熟的技术支撑，在测量领域有着重要的地位。但随着科技的发展，RTK技术相对而言，更具有优势，与全站仪相比较，RTK技术对于通视不作要求，全站仪则有前后通视的苛刻要求，在一些环境复杂的地区，比如高楼林立的城市或者森林里，RTK可以很好地在这些复杂环境下作业，但我们也要考虑遮挡物造成GPS卫星信号被阻挡；在测量精度方面，RTK则更好地实现大范围测量，而全站仪只能作为小范围控制测量，如全国的地质灾害调查，RTK技术可能更有益于工作的进行；工作效率更不言而喻，RTK技术可以实现单兵作战，在这一点上更能适应市场需求，目前，RTK技术的飞速发展，已经在很多领域得到广泛的应用，比如地质灾害调查、矿山修复等，其中高效的效率有着重要的原因（两种放样方法对比见表1）。</p>
      <h5>表1 全站仪和RTK技术的比较</h5>
      <img src="../../assets/newsimg/xw3/img1.png" alt />
      <h3>二GPS RTK技术</h3>
      <h4>1. RTK GPS技术在公路放样中的应用</h4>
      <p>
        GPS
        RTK放样技术在诸多领域都有着极大的贡献，在公路路线方面用的最广泛的是放样点的平面位置。设置好基准站和流动站之后，选择“放样”功能，输入需要放样的点位坐标，选择点放样功能，在输入界面输入预设的放样点点号，点击解算功能，计算出导航数据，并在导航图中央显示放样点的位置，当放样点的实际位置与RTK天线位置重合时，即得到放样点的位置。
      </p>
      <h4>2. RTK GPS技术在建筑轴线放样中的应用</h4>
      <p>(1）选定测区范围内控制网。科学选择观测时段，由于12:00～13:00间受电离层影响严重，尽量避开该时间段测量。利用GPS静态定位，创建高等级控制网，联测附近不少于3个已知控制点，用于坐标转换，同时保证GPS网平差后精度的均匀性。</p>
      <p>(2）计算建筑轴线位置相关点坐标。根据详规图、施工图及规划总图中的首层平面图等资料计算好待放轴线上相关点的坐标</p>
      <p>(3)RTK的定位应选择地势高且附近无高大障碍物的空旷位置，是基准站选择的良好位置，并保证基准站尽量靠近待放样点。另外还需要在测区附近找至少三个控制点，进行三参数校正，才能使测点达到厘米级精度</p>
      <p>(4)GPS静态采样点检测。将仪器分别架设在作为起算点的更高等级的已知点及待测点上，同时开机，观测时间设置为30分钟。最后将接收机中的观测数据传输到电脑中，并与建筑轴线上相关点坐标进行对比，统计分析放样结果的精度。</p>
      <h3>三GPS RTK技术的应用前景</h3>
      <h4>1.“GPS现代化”的实现</h4>
      <p>近年来，GPS系统的在轨卫星数量不断增加，6个轨道平面中的每个平面就有5颗卫星均匀分布，大大提高了卫星的可见性，使全球范围内时间和空间上都不存在盲区。同时增加了第三个民用频道（L3C）发播非涉密的民用信号。对GPS测量和RTK测量来说，再无需解算整周相位模糊度值，将大大提高GPS测量成果的可靠性和困难地段RTK初始化能力。</p>
      <h4>2. 永久参考站网的建设</h4>
      <p>随着GPS技术的飞跃发展和应用普及，RTK在城市测量中的作用已越来越重要。目前连续运行的GPS参考站网已成为城市GPS应用的发展热点之一。它是利用多基站网络RTK技术，在一个国家、地区建立的长年连续运行卫星定位服务综合系统，利用互联网技术、数据通信设备将参考站和数据中心建立连接，经过参考站网的软件处理数据后，原始GPS数据和RTK改正数等就形成了一个网络系统，为RTK提供全天候地测量、定位、放样等作业。它具有全天候、全自动、实时导航定位功能，作业时只需一台RTK，就可以进行毫米级、厘米级、分米级、米级的实时快速定位、导航定位或事后定位等野外作业；可以满足覆盖区域内各种陆地、水上和空中交通工具的自动识别、导航、调度以及安全监控等功能需求，同时也可服务于高精度中短期天气状况的数值预报。参考站网技术的应用将会有效解决RTK有效测程问题、长距离数据通信问题、成果的可靠性问题。</p>
      <h3>四结语</h3>
      <p>
        GPS
        RTK技术弥补了传统放样的不足，应用于工程施工放样中为工程带来极大的便利。但RTK依然存在着许多的缺陷，对工程施工的进度、精度产生影响。RTK技术放样精度由于受到电离层干扰，共用卫星数会减少，因而导致初始化时间过长甚至可能出现不能初始化的情况。同时易受对空通视环境影响，在存在遮挡物时，GPS卫星信号强度低，稳定性不高，容易受卫星状况、天气状况、数据链传输状况影响，相对于全站仪等传统放样技术，其精度还有待提高。
      </p>
      <p>针对RTK技术存在的缺陷，首先平时应该多练习RTK仪器，熟悉仪器的特性。其次作业时注重基准站和移动站位置的选择，应选择视野开阔、地势较高的地方，远离高大障碍物、大面积水塘以及高压输电线。同时合理安排作业时间，避免多路径效应与无线电干扰等不利因素，作业前计划好合理的作业流程，将RTK技术与传统放样方法相结合，联合作业，充分发挥RTK与全站仪各自的优势。</p>
      <div class="xw-source">（来源：网络）</div>
      <hr />
      <div style="display: flex">
        <p>上一篇:</p>
        <router-link
          v-if="lastNews.path !== undefined"
          :to="lastNews.path === '/' ? '' : lastNews.path"
        >{{ lastNews.ntitle }}</router-link>
      </div>
      <div style="display: flex">
        <p>下一篇:</p>
        <router-link
          v-if="nextNews.path !== undefined"
          :to="nextNews.path === '/' ? '' : nextNews.path"
        >{{ nextNews.ntitle }}</router-link>
      </div>
    </div>
    <div class="xw-newest">
      <Newset ref="newsetdata" />
    </div>
  </div>
</template>

<script>
import { networkConfig } from "../../axios/networkConfig";
import Newset from "./component/newest.vue";
import { News, NewsDetail } from "../../axios/modules/officialwebsite.js";
export default {
  data() {
    return {
      to: "",
      timedata: "",
      hotdata: "",
      lastNews: {},
      nextNews: {},
      imgurl: networkConfig.serverUrl + "upload/",
      Newsname: []
    };
  },

  mounted() {
    this.Newsdata();
    this.NewsDetaildata("newsxw2");
  },
  methods: {
    // 页面数据接口
    async Newsdata() {
      let { code, data } = await News();
      var moment = require("moment");
      // console.log(data);
      if (code == 0) {
        data.list.map(item => {
          item.ndate = moment(Number(item.ndate) * 1000).format("YYYY-MM-DD");
          item.ntitle = item.ntitle.slice(0, 17) + "...";
        });
        this.$refs.newsetdata.Newsname = data.list;
        // console.log(this.$refs.newsetdata);
      }
    },
    // 上一篇下一篇
    async NewsDetaildata(path) {
      let { code, data } = await NewsDetail(path);
      // console.log(data);
      var moment = require("moment");
      if (code == 0) {
        // console.log(data);
        this.lastNews = data.lastNews;
        this.nextNews = data.nextNews;

        this.timedata = moment(Number(data.detail.ndate) * 1000).format(
          "YYYY-MM-DD"
        );
        this.hotdata = data.detail.hot;
      }
    }
  },
  components: {
    Newset
  }
};
</script>

<style lang="scss" scoped>
.xw {
  width: 1320px;
  margin: 0 auto;
  margin-top: 134px;

  display: flex;
}
/* 新闻内容 */
.xw-center {
  width: 912px;
  padding: 16px;
  min-height: 80vh;
  //   background: pink;
  text-align: center;

  //   > h2 {
  //     margin: 28px 0;
  //   }
  > hr {
    margin: 40px 0;
    border: 0.5px solid #e6e6e6;
  }
  > p {
    line-height: 32px;
    font-size: 16px;
    text-align: left;
    text-indent: 2em;
    padding: 20px 0;
  }
  > img {
    width: 100%;
  }
  .xw-source {
    text-align: right;
    color: #999999;

    font-size: 14px;
  }
  > h3 {
    text-align: left;
    padding: 15px 0;
  }
  > h4 {
    text-align: left;
    padding: 15px 0;
  }
}
.xw-time {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .xw-time__text {
    padding: 3px;
    font-size: 14px;

    color: #666666;
  }
}
.xw-newest {
  // position: fixed;
  right: 23vw;
  width: 365px;
  //   padding: 16px;
  height: 500px;
  margin: 16px;
  //   background: red;
  border: 1px solid #cccccc;
}
.router-link {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
</style>