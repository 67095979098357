<template>
  <div>
    <div class="sticky">
      <div class="sticky-center">
        <div class="sticky-icon"></div>
        <div class="sticky-text">联系我们</div>
      </div>
      <div class="sticky-left">
        <div
          class="sticky-left__center"
          v-for="(item, index) in imglist"
          :key="index"
        >
          <img :src="item.icon" alt="" />
          <div>
            <p>{{ item.name }}</p>
            <div style="display: flex">
              <p style="color: #6666">{{ item.txet }}</p>
              <p style="color: #32a6ff">{{ item.phone }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-backtop :bottom="171" :visibility-height="-1" :right="20">
      <div
        style="
           {
            height: 49px;
            width: 49px;
            background-color: #0090ff;
            /* box-shadow: 0 0 6px rgba(0, 0, 0, 0.12); */
            text-align: center;
            line-height: 40px;
          }
        "
      >
        <div class="sticky-top">
          <div class="sticky-top__icon"></div>
          <div class="sticky-top__text">顶部</div>
        </div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imglist: [
        {
          name: "客服热线",
          phone: "027-87888720",
          icon: require("../icons/svg/sidebar3.svg"),
        },
        {
          name: "邮箱",
          phone: "service@kplgnss.com",
          icon: require("../icons/svg/sidebar2.svg"),
        },
        {
          name: "专业解答",
          txet: "专业服务",
          phone: "7×24小时",
          icon: require("../icons/svg/sidebar1.svg"),
        },
      ],
    };
  },
  methods: {
    tophead() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  position: fixed;
  position: -webkit-sticky;
  width: 49px;
  height: 138px;
  bottom: 220px;
  right: 15px;
  background: #0090ff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;

  color: #fff;
  cursor: pointer;
  //  margin: 0 0 0px 1855px;
  // border-radius: 10px;
  .sticky-center {
    width: 24px;
    padding: 10px 12.5px;
    .sticky-icon {
      width: 24px;
      height: 21px;

      background: url(../assets/image/foot/footicon.png);
    }
    .sticky-text {
      margin-top: 4px;
      text-align: center;
    }
  }
}
.sticky-left {
  position: absolute;
  width: 0;
  height: 177px;
  top: 0px;
  right: 55px;
  font-size: 14px;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  z-index: 998;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // justify-content: center;
  // align-items: center;
  color: #333333;
  .sticky-left__center {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    > img {
      // color: #9999;
      width: 23px;
      height: 27px;
      margin-right: 12px;
    }
  }
}
.sticky:hover {
  .sticky-left {
    width: 172px;
    background: #eef7ff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    padding: 15px 15px 0;
  }
}

// 回到顶部
.sticky-top {
  width: 49px;
  padding-top: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  .sticky-top__icon {
    width: 20px;
    height: 15px;
    background: url("../assets/image/foot/footicon4.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .sticky-top__text {
    color: #ffffff;
    font-size: 12px;
    line-height: 25px;
  }
}
</style>