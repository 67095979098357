import { requestService } from "../request.js";
const VITE_APP_API = "api/v2/";
import { getCookie } from "@/utils/auth";


export function get_pro_list() {
    const data = {

    };
    return requestService({
        url: `${VITE_APP_API}pro/list?token=${getCookie(getCookie("username"))}`,
        method: "post",
        data,
    });
}

export function get_pro_title() {
    const data = {

    };
    return requestService({
        url: `${VITE_APP_API}pro/title?token=${getCookie(getCookie("username"))}`,
        method: "post",
        data,
    });
}