import { render, staticRenderFns } from "./running-record.vue?vue&type=template&id=6d436b40&"
import script from "./running-record.vue?vue&type=script&lang=js&"
export * from "./running-record.vue?vue&type=script&lang=js&"
import style0 from "./running-record.vue?vue&type=style&index=0&id=6d436b40&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports