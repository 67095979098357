<template>
  <div class="app">
    <Head />
    <Table />
    <div class="app-center"></div>
  </div>
</template>

<script>
import Head from "../components/head.vue";
import Table from "./table.vue";
// import { selectdata } from "@/axios/modules/ggah5.js";
import { socketHandler } from "@/utils/webSocket";

// var sendData = {
//   __topic: "/cmd",
//   cmd: "subscribe",
//   channels: "/cmd/" + "36z049",
// };
// sendSock(JSON.stringify(sendData));

export default {
  components: {
    Head,
    Table,
  },
};
</script>

<style lang="scss" scoped>
.app {
  width: 100%;

  display: flex;
  flex-direction: column;
}

.app-center {
  flex: 1;
  margin: 30vh auto;
}
</style>
