<template>
  <div class="container">
    <div class="warp">
      <!-- 导航路径区域 -->
      <div class="conPoin">
        <span>产品</span>
        <span>高精度定位服务</span>
        <span @click="$router.go(-1)">{{ pageInfo.ptitle }}</span>
      </div>

      <!-- 产品区 -->
      <div class="main">
        <!-- 左侧图片区 -->
        <div class="img_box">
          <!-- 大图 -->
          <!-- <img src="./images/资源 94.png" alt="" /> -->
          <img :src="imgurl + bigPic[picIndex]" alt />

          <!-- 小图 -->
          <div class="samllList">
            <imgList :smallPic="smallPic" @changeIndex="changeIndex"></imgList>
          </div>
        </div>

        <!-- 右侧商品信息 -->
        <div class="goodsInfo">
          <div class="top">
            <p class="top_title">{{ pageInfo.ptitle }}</p>
            <p class="sub_title">{{ pageInfo.bdec }}</p>
          </div>
          <div class="main_content">
            <p>商品分类：{{ pageInfo.type }}</p>
            <p>精度等级：{{ pageInfo.accu }}</p>
          </div>
          <div class="bottom">
            <div class="chooseArea">
              <dl>
                <dt>规格型号：</dt>
                <dd class="active">{{ pageInfo.bconfig[0].title }}</dd>
              </dl>
              <dl>
                <dt>选择时长：</dt>
                <dd
                  changepirce="0"
                  v-for="(item, index) in timeList"
                  :class="{ active: curActive == index }"
                  :key="index"
                  @click="changeCur(item, index)"
                >{{ item.during }}</dd>
              </dl>
              <dl>
                <dt>交付方式：</dt>
                <dd :class="{ active: payType == 1 }" @click="payType = 1">兑换码</dd>
                <dd :class="{ active: payType == 2 }" @click="payType = 2">直接服务</dd>
              </dl>

              <div class="buyNum gap">
                <span class="title">购买数量：</span>
                <el-input-number
                  v-model="num"
                  size="small"
                  @change="handleChange"
                  :min="1"
                  :max="10"
                  label="描述文字"
                ></el-input-number>
              </div>
              <div class="price gap">
                <span class="title">官方标价：</span>
                <span class="money">￥{{ amount }}</span>
              </div>
            </div>
            <button class="btn">已售罄</button>
          </div>
        </div>
      </div>
    </div>

    <contact></contact>

    <sidebar></sidebar>
    <div class="goodsDetail">
      <img v-lazy="imgurl + cppic" alt />
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar";
import contact from "@/components/contact";
import imgList from "./imgList/index.vue";
import { reqGoodsInfo } from "../../axios/modules/officialwebsite";
import { networkConfig } from "../../axios/networkConfig";
export default {
  data() {
    return {
      //商品时长
      timeList: [],
      curActive: "0",
      // 购买数量
      num: 1,
      path: "",
      table: "",
      // 页面信息
      pageInfo: {},
      price: "",
      // 大图列表
      bigPic: [],
      // 小图列表
      smallPic: [],
      imgurl: networkConfig.serverUrl + "upload/",
      // 当前图片下标
      picIndex: 0,
      payType: 1,
      cppic: "",
      amount: 0
    };
  },
  components: {
    sidebar,
    contact,
    imgList
  },
  methods: {
    handleChange(value) {
      this.num = value;
      this.amount = this.price * value;
    },
    // 切换时长
    changeCur(item, index) {
      this.curActive = index;
      this.num = 1;
      this.price = this.num * item.price;
      this.amount = this.price;
    },

    // 获取页面信息
    async getGoodsInfo() {
      const { code, data } = await reqGoodsInfo(this.table, this.path);
      if (code == 0) {
        this.pageInfo = data;
        this.timeList = this.pageInfo.bconfig[0].config;
        this.price = this.timeList[0].price;
        this.bigPic = this.pageInfo.blgpic;
        this.smallPic = this.pageInfo.bsmpic;
        this.cppic = this.pageInfo.bdetail;
        this.amount = this.price;
      }
      console.log(this.cppic);
      console.log(this.bigPic);
    },
    // 根据小图切换大图
    changeIndex(val) {
      this.picIndex = val;
    }
  },
  watch: {
    num: function(val) {
      // this.price = this.price * val;
    }
  },
  mounted() {
    this.path = this.$route.query.path;
    this.table = this.$route.query.table;
    this.getGoodsInfo();
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin-right: 15px;
  float: left;
  font-size: 14px;
  font-family: "Microsoft YaHei-Regular", " Microsoft YaHei";
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
.gap {
  margin: 13px 0;
}
.container {
  width: 100%;
  .warp {
    width: 1320px;

    margin: 100px auto;
    .conPoin {
      padding: 29px 0;
      & > span + span:before {
        content: ">";
        padding: 0 5px;
        color: #333333;
      }
    }
    .main {
      display: flex;
      .img_box {
        width: 404px;
        height: 404px;
        border: 1px dotted #ccc;
        margin-right: 70px;
        img {
          width: 100%;
          height: 100%;
        }
        .samllList {
          margin: 10px 0;
        }
      }
      .goodsInfo {
        flex: 1;
        .top {
          .top_title {
            font-size: 32px;
            font-family: "PingFang SC-Bold", "PingFang SC";
            font-weight: bold;
            color: #333333;
            line-height: 28px;
          }
          .sub_title {
            padding: 20px 0;
            font-size: 14px;
            font-family: "Microsoft YaHei-Regular", "Microsoft YaHei";
            font-weight: 400;
            color: #666666;
            line-height: 28px;
          }
        }
        .main_content {
          font-size: 14px;
          font-family: "Microsoft YaHei-Regular", "Microsoft YaHei";
          font-weight: 400;
          color: #999999;
          line-height: 28px;
          padding: 20px 0;
          border-top: 1px solid #e6e6e6;
          border-bottom: 1px solid #e6e6e6;
        }

        .chooseArea {
          dl {
            overflow: hidden;
            margin: 13px 0;

            dt {
              margin-right: 15px;
              float: left;
              font-size: 14px;
              font-family: "Microsoft YaHei-Regular", " Microsoft YaHei";
              font-weight: 400;
              color: #666666;
              line-height: 28px;
            }

            dd {
              float: left;
              margin-right: 5px;
              text-align: center;
              padding: 0 8px;
              height: 25px;
              font-size: 14px;
              font-family: "Microsoft YaHei-Regular", " Microsoft YaHei";
              font-weight: 400;
              color: #999999;
              line-height: 28px;
              border: 1px solid #999999;
              cursor: pointer;

              &.active {
                color: #ff6031;
                border: 1px solid #ff6031;
              }
            }
          }
          .buyNum {
            .el-input-number--small {
              width: 110px;
            }
          }
          .price {
            .money {
              font-size: 22px;
              font-family: "Source Han Sans CN-Regular", "Source Han Sans CN";
              font-weight: 400;
              color: #ff6031;
            }
          }
        }

        .btn {
          width: 220px;
          height: 45px;
          background: #999999;
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
          border-radius: 0px 0px 0px 0px;
          border: none;

          font-size: 22px;
          font-family: "Source Han Sans CN-Regular", "Source Han Sans CN";
          color: #ffffff;
        }
      }
    }
  }

  .goodsDetail {
    width: 1125px;
    margin: 50px auto 0;

    img {
      width: 100%;
    }
  }
}
</style>
