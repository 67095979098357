<template>
  <el-dialog title="提示" :visible.sync="visible" width="20%" center>
    <div class="dialog-center">
      <div class="dialog-name">
        关键操作（如：修改密码等）时，通过设置保
        护强度和验证方式再次确认您的身份，进一步 提高账号安全性
      </div>
      <el-switch
        class="main-switch"
        width="60"
        :active-value="1"
        :inactive-value="0"
        v-model="switchValue1"
        inactive-text="关"
        active-text="开"
      >
      </el-switch>
      <div class="dialog-foot">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="visible = false">修改</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      switchValue1: 0,
      visible: false,
    };
  },
};
</script>

<style lang="scss">
.dialog-center {
  width: 280px;
  margin: 0 auto;
  .dialog-name {
    color: #23cb4a;
    font-size: 14px;
  }
  .el-switch {
    margin: 20px 0;
  }
  .dialog-foot {
    display: flex;
    justify-content: space-between;
    .el-button {
      width: 180px;
    }
    .el-button--primary {
      background: #004598;
    }
  }
}

.main-switch {
  position: relative;
}
.main-switch .el-switch__core {
  height: 24px;
  border-radius: 12px;
}
.main-switch .el-switch__core:after {
  left: 4px;
}
.main-switch.el-switch.is-checked .el-switch__core::after {
  margin-left: -20px;
}
.main-switch .el-switch__core:after {
  top: 3px;
}
.main-switch .el-switch__label {
  position: absolute;
  top: 0;
}
.main-switch .el-switch__label--left {
  right: 0;
  color: #999;
  z-index: 1;
  margin-right: 8px;
}
.main-switch .el-switch__label--right {
  left: 0;
  color: #fff;
  opacity: 0;
  margin-left: 8px;
}
.main-switch.is-checked .el-switch__label--left {
  opacity: 0;
}
.main-switch.is-checked .el-switch__label--right {
  opacity: 1;
}
</style>