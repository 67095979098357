<template>
  <div class="product">
    <div class="product-head"></div>
    <img :src="imgurl+productdata.pic_h5" alt class="image" />
    <div class="traffic" v-if="qtype!=3">
      <div class="document" v-if=" productdata.pword!== undefined &&productdata.pword.length!=0">
        <img src="./image/组 589@2x.png" alt class="images" />
        <a class="btn" v-down="imgurl+prosrc"></a>
        <div class="product_text">
      (部分机型不支持展示,请使用office查看)
    </div>
      </div>
      <div class="purchase">
        <img src="./image/组 588@2x.png" alt class="images" />
      </div>
      <a class="btn1" href="tel:027-87888720">027-87888720</a>
      <a class="btn2" href="tel:027-87888720">027-87888720</a>
      
    </div>
  
    <div class="contact" v-else>
      <img src="./image/组 527@2x.png" alt class="images" />
    </div>
  </div>
</template>

<script>
import { Api_product } from "@/axios/modules/product.js";
import router from "@/router/index.js";
import { networkConfig } from "@/axios/networkConfig";

// import { PageStyle1 } from "./index.js";

export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      url: "",
      qpath: "",
      qtype: null,
      productdata: [],
      prosrc: ""
    };
  },
  // components: { PageStyle1 },

  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      // console.log(to, "23333____________");
      this.qpath = this.$route.query.path;
      this.qtype = this.$route.query.type;
      this.Kinnamedata();
    }
  },
  mounted() {
    this.qpath = this.$route.query.path;
    this.qtype = this.$route.query.type;
    // console.log(this.$route, "23333____________");
    this.Kinnamedata();
  },
  methods: {
    async Kinnamedata() {
      let { code, data } = await Api_product(this.qpath, this.qtype);

      if (code == 0) {
        this.productdata = data;
        if (data.pword !== undefined && data.pword.length > 0) {
          this.prosrc = data.pword[0].src_h5;
        }
        return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.product {
  width: 100vw;
  margin: 0 auto;
  position: relative;
  .product-head {
    height: 100px;
  }
}
.image {
  width: 100vw;
  // height: 100px;
}
.document {
  width: 100vw;
  .images {
    width: 100vw;
  }
}
.contact {
  position: absolute;
  top: 185vh;
  width: 100vw;
  .images {
    width: 100vw;
  }
}
.purchase {
  width: 100vw;
  // height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .images {
    width: 80vw;
  }
}
.btn {
  width: 30vw;
  height: 8vw;
  // background: red;
  position: absolute;
  bottom: 118vw;
  right: 5vw;
  text-align: center;
  line-height: 8vw;
}

.btn1 {
  width: 30vw;
  height: 8vw;
  // background: red;
  position: absolute;
  bottom: 1vw;
  left: 40vw;
  text-align: center;
  line-height: 8vw;
}
.btn2 {
  width: 30vw;
  height: 8vw;
  // background: red;
  position: absolute;
  bottom: 45vw;
  left: 40vw;
  text-align: center;
  line-height: 8vw;
}
.product_text{
  position: absolute;
  bottom: 113vw;
  left: 10vw;
  text-align: center;
  line-height: 8vw;
  font-size: 12px;
}
</style>
