<template>
  <div class="traffic">
    <div class="xw-center">
      <h2>全国CORS账号，在新疆哪里覆盖，哪里不覆盖啊？</h2>
      <hr />
      <div class="xw-time">
        <div class="xw-time__text">发布时间:</div>
        <div class="xw-time__text">2022-08-17</div>
        <div class="xw-time__text">浏览量:</div>
        <div class="xw-time__text">203次</div>
      </div>
      <p>
        最近有小伙伴问“全国账号，在新疆哪里覆盖，哪里不覆盖啊？”这个问题，为了方便大家了解，下面做一个详细介绍。
        新疆范围很大，不同地区覆盖范围不同，有的市区全部覆盖，有的则部分覆盖。
      </p>

      <p>全市覆盖区域：</p>

      <p>乌鲁木齐、克拉玛依市、博尔塔拉蒙古自治州、石河子市、阿拉尔市、图木舒克市、五家渠市、北屯市、铁门关市、双河市、可克达拉市</p>

      <img src="./image/xw4.png" alt />

      <p>部分覆盖区域：</p>
      <p>巴音郭楞蒙古自治州——博湖县、焉者回族自治县、和硕县、库尔勒市、轮台县</p>
      <p>塔城地区——除裕民县以外，其他地区已覆盖</p>
      <p>昌吉回族自治州——昌吉市、阜市、呼图壁县、玛纳斯县、吉木萨尔县奇台县</p>
      <p>喀什地区——喀什市、疏附县、疏勒县、岳普湖县、伽师县、巴楚县、泽普县、莎车县、巴楚县、英吉沙县</p>
      <p>伊犁哈萨克自治州——伊宁市、奎屯市、伊宁县、察布查尔锡伯自治县、霍城县、巩留县、新源县、尼勒克县、霍尔果斯市、特克斯县、昭苏县</p>
      <p>阿勒泰地区——吉木乃县、阿勒泰市、布尔津县、哈巴河县、青河县、富蕴县</p>
      <p>阿克苏地区——阿克苏市、新和县、柯坪县、乌什县、拜城县、库车市、温宿县</p>
      <p>和田地区——和田市</p>
      <p>克孜勒苏柯尔克孜自治州——阿图什市、乌恰县</p>
      <p>吐鲁番市——托克逊县、高昌区</p>
      <p>以上就是关于全国CORS账号，在新疆哪里覆盖，哪里不覆盖啊的全部解答，这是此时的覆盖范围，当然，随着时间的变化，其覆盖范围可能会更新。更多信息，请关注CORS账号。</p>
      <div class="xw-source">（来源：网络）</div>
      <hr />
    </div>
    <div>
      <router-link to="/newsdetail/newsxw6" class="btn">下一篇:欧洲计划建立低轨卫星星座，以实现超高精度定位服务</router-link>
    </div>
    <div>
      <router-link to="/newsdetail/newsxw4" class="btn3">上一篇:看懂RTK定位，这一篇就够啦！</router-link>
    </div>

    <!-- <div class="btn1"></div>
    <div class="btn2"></div>-->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.traffic {
  margin-top: 20vw;
  position: relative;
  padding: 0 2%;
}
.imgaes {
  width: 100%;
}
.btn {
  font-size: 12px;
  width: 100%;
  height: 8vw;
  background: #fff;
  // position: absolute;
  // bottom: 5vw;
  // left: 0;
}
.btn3 {
  font-size: 12px;
  width: 100%;
  height: 15vw;
  background: #fff;
  // position: absolute;
  // bottom: 13vw;
  // left: 0;
}
// .btn1 {
//   width: 30vw;
//   height: 8vw;
//    // background: red;: red;
//   position: absolute;
//   bottom: 3vw;
//   left: 40vw;
// }
// .btn2 {
//   width: 30vw;
//   height: 8vw;
//    // background: red;: red;
//   position: absolute;
//   bottom: 55vw;
//   left: 40vw;
// }
.xw-center {
  width: 100%;
  // margin: 0 2%;

  min-height: 80vh;
  //   background: pink;
  text-align: center;

  //   > h2 {
  //     margin: 28px 0;
  //   }
  > hr {
    margin: 40px 0;
    border: 0.5px solid #e6e6e6;
  }
  > p {
    line-height: 32px;
    font-size: 16px;
    text-align: left;
    text-indent: 2em;
    padding: 20px 0;
  }
  > img {
    width: 100%;
  }
  .xw-source {
    text-align: right;
    color: #999999;

    font-size: 14px;
  }
  > h3 {
    text-align: left;
    padding: 15px 0;
  }
  > h4 {
    text-align: left;
    padding: 15px 0;
  }
}
.xw-time {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .xw-time__text {
    padding: 3px;
    font-size: 14px;

    color: #666666;
  }
}
.xw-newest {
  // position: fixed;
  right: 23vw;
  width: 365px;
  //   padding: 16px;
  height: 500px;
  margin: 16px;
  //   background: red;
  border: 1px solid #cccccc;
}
.router-link-active {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: #333;
}
.active.right-row--a {
  color: #2271f7;
}
</style>
