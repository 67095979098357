var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logistics-page"},[_c('sidebar'),_vm._m(0),_c('h2',[_vm._v("行业痛点")]),_c('p',{staticClass:"text1"},[_vm._v("INDUSTRY PAIN POINTS")]),_c('div',{staticClass:"panorama"},_vm._l((_vm.soft_imgs),function(item){return _c('div',{key:item.id},[_c('div',{staticClass:"panorama-logistics",style:({
          'background-image': `url(${item.softsrc})`,
          'background-size': `100% 100%`,
        })},[_c('div',{staticClass:"logistics-l"},[_c('p',{staticClass:"logistics-texts"},[_vm._v(_vm._s(item.name2))]),_c('p',{staticClass:"logistics-text"},[_vm._v(" "+_vm._s(item.name3)+" ")])])])])}),0),_c('h2',[_vm._v("方案框架")]),_c('p',{staticClass:"text1"},[_vm._v("INDUSTRY PAIN POINTS")]),_vm._m(1),_c('div',{staticClass:"contacty",on:{"click":_vm.contactry}},[_vm._v("联系我们，了解更多>")]),_c('h2',[_vm._v("适用物流类型")]),_c('p',{staticClass:"text1"},[_vm._v("INDUSTRY PAIN POINTS")]),_vm._m(2),_c('div',{staticClass:"fixed-footer"},[_c('MyFooter')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-logistics"},[_c('div',{staticClass:"logistics-title"},[_c('p',{staticClass:"logistics-cn"},[_vm._v("Smart Logistics")]),_c('p',{staticClass:"logistics-en"},[_vm._v("智慧物流解决方案")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logistics-page"},[_c('div',{staticClass:"bj3-logistics"},[_c('div',{staticClass:"advantage"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logistics-page2"},[_c('div',{staticClass:"bj4-logistics"},[_c('div')])])
}]

export { render, staticRenderFns }