<template>
  <div class="home">
    <!-- <sidebar></sidebar> -->

    <CarouseLimg ref="carouselimg" />
    <div class="home-pro">
      <div class="home-title">推荐信息</div>
      <!-- 产品推荐 -->
      <Product ref="product" />
      <Programme ref="programme" />
    </div>
    <div class="home-swi">
      <div class="home-title">应用领域</div>
      <Application ref="application" />
    </div>
    <div class="home-pro">
      <div class="home-title">新闻资讯</div>
      <Newsname ref="newsname" />
    </div>
  </div>
</template>

<script>
// import Sidebar from "@/components/sidebar.vue";
import { get_home } from "@/axios/modules/officialwebsite.js";
import CarouseLimg from "./Carouselimg.vue";
import Product from "./product.vue";
import Programme from "./programme.vue";
import Application from "./application.vue";
import Newsname from "./news.vue";
// import contact from "../components/contact.vue";
import { networkConfig } from "@/axios/networkConfig";
export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      tiemdd: []
    };
  },
  components: {
    // swiper,
    // swiperSlide,
    CarouseLimg,
    Product,
    Programme,
    Application,
    Newsname
    // Sidebar,
    // contact,
  },
  mounted() {
    this.Get_home();
  },
  methods: {
    // 首页轮播图
    async Get_home() {
      let res = await get_home();
      var moment = require("moment");

      if (res.code == 0) {
        this.$refs.carouselimg.imgList = res.head;

        this.$refs.application.applicationdata = res.appArea;
        this.$refs.product.productdata = res.recom.product;
        this.$refs.programme.resolvedata = res.recom.resolve;
        res.newsAndInfo.details.map(item => {
          // console.log(item.ndate);
          let time = item.ndate;
          item.ndate = moment(Number(time) * 1000).format("YYYY.MM");
          // item.ndate = moment().add(Number(item.ndate)).format("YYYY.MM.DD");
          item.nid = moment(Number(time) * 1000).format("DD");
          // item.nid = moment(item.ndate).date();
          // console.log(item.nid);
          // item.ndata = item.ndata.slice(0, 5);
          item.ntitle = item.ntitle.slice(0, 20) + "...";
        });
        this.$refs.newsname.newsdata = res.newsAndInfo.details;
        res.newsAndInfo.basicdata.map(item => {
          item.ndate = moment(Number(item.ndate) * 1000).format("YYYY.MM.DD");
          //   item.ntitle = item.ntitle.slice(0, 17) + "...";
        });
        // this.$refs.newsname.newsdata = this.timedd;
        this.$refs.newsname.Newsname = res.newsAndInfo.basicdata;
        return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  font-size: 18px;
}
.home-swi {
  background: #f5f5f5;
  padding: 6% 2%;
}
.home-pro {
  padding: 0 2%;
}
.home-title {
  width: 100%;
  text-align: center;

  font-weight: bold;
  font-size: 18px;
  line-height: 50px;
}
</style>
