<template>
  <div class="from1">
    <el-form ref="form" :rules="rules" :model="form" label-width="100px">
      <div class="authentication-title">完成实名认证可获取更多权益</div>
      <el-form-item label="服务用途：" prop="value">
        <el-select
          v-model="form.value"
          placeholder="请选择"
          @blur="productSelect"
          allow-create
          filterable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="真实姓名：" prop="name">
        <el-input v-model="form.name" placeholder="请输入真实姓名"></el-input>
      </el-form-item>
      <el-form-item label="身份证号：" prop="id_card">
        <el-input
          maxlength="18"
          v-model="form.id_card"
          placeholder="请输入证件号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <div style="display: flex">
          <el-checkbox v-model="agree"> </el-checkbox>
          <div style="display: flex">
            <p class="left-title">我同意并接受</p>
            <a href="#">《开发者认证协议》</a>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div style="display: flex">
          <el-checkbox v-model="checked"> </el-checkbox>
          <div class="left-title" style="width: 310px">
            根据相关测量测绘法规，您需要完成实名认证，否则将无法使用开普勒卫星科技提供的产品及/或服务，您同意提供您的真实姓名身份证号码等个人敏感信息。
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交认证</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getCookie } from "@/utils/auth";
import { Authentication } from "../../axios/modules/personals.js";
export default {
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "高精度导航",
        },
        {
          value: "选项2",
          label: "车辆船舶监控",
        },
        {
          value: "选项3",
          label: "智能驾考/驾培",
        },
        {
          value: "选项4",
          label: "汽车自动驾驶",
        },
        {
          value: "选项6",
          label: "物流配送",
        },
        {
          value: "选项7",
          label: "资产管理",
        },
        {
          value: "选项8",
          label: "测量工程",
        },
        {
          value: "选项9",
          label: "电力巡检",
        },
        {
          value: "选项10",
          label: "管线巡检",
        },
        {
          value: "选项11",
          label: "建筑场地巡查",
        },
        {
          value: "选项12",
          label: "矿产勘测",
        },
        {
          value: "选项13",
          label: "林业勘测",
        },
        {
          value: "选项14",
          label: "大型人工建筑监测",
        },
        {
          value: "选项15",
          label: "工程施工",
        },
        {
          value: "选项16",
          label: "环境保护",
        },
        {
          value: "选项17",
          label: "危房监测",
        },
        {
          value: "选项18",
          label: "地质灾害监测",
        },
        {
          value: "选项19",
          label: "水利巡检",
        },
        {
          value: "选项20",
          label: "铁轨监测",
        },
        {
          value: "选项21",
          label: "专业无人机",
        },
        {
          value: "选项22",
          label: "植保无人机",
        },
        {
          value: "选项23",
          label: "消费无人机",
        },
        {
          value: "选项24",
          label: "土地普查",
        },
        {
          value: "选项25",
          label: "精准农业",
        },
        {
          value: "选项26",
          label: "金融保险",
        },
        {
          value: "选项27",
          label: "运动健身",
        },
        {
          value: "选项28",
          label: "测绘测量",
        },
        {
          value: "选项29",
          label: "自定义服务用途",
        },
      ],
      // value: "",
      username: "",
      checked: false,
      agree: false,
      form: {
        value: "",
        name: "",
        id_card: "",
      },
      rules: {
        value: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        id_card: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {
            pattern:
              /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: "请输入合法身份证号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      //   console.log(this.value);
      if (this.checked == true && this.agree == true) {
        // console.log(1111);
        const { id_card, name } = this.form;
        const sendobj = {
          fserpur: this.form.value,
          ficd: id_card,
          ficname: name,
          ftype: 1,
        };

        this.Authenticationget(sendobj);
        this.sendobj = [];
      } else {
        this.$message.error({
          offset: 150,
          title: "错误",
          message: "请勾选协议",
          type: "success",
        });
      }
    },
    //   提交认证接口
    async Authenticationget(sendobj) {
      let { code } = await Authentication(sendobj);

      if (code == 0) {
        this.$message({
          offset: 150,
          message: "实名认证成功，即将跳转个人中心",
          type: "success",
        });
        this.timer = setTimeout(() => {
          //设置延迟执行
          this.$router.push({ path: "/personal_center/index" });
        }, 3000);
        return;
      } else if (code == -1) {
        this.$message.error({
          offset: 150,
          title: "错误",
          message: "请填写完整信息",
          type: "success",
        });
      }
    },
    productSelect(e) {
      let value = e.target.value; // 输入框值
      if (value) {
        // 你输入才有这个值 不为空，如果你下拉框选择的话 这个值为空
        this.form.value = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.from1 {
  width: 1320px;
  height: 740px;
  // padding-bottom: 100px;
}
.left-title {
  padding-left: 10px;
  padding-top: 8px;
  color: #333333;
  font-size: 12px;
  line-height: 24px;
}
.el-form {
  width: 440px;
  margin: 0 auto;
  .authentication-title {
    text-align: center;
    padding: 30px 0;
  }
}
.el-button--primary {
  width: 276px;
  height: 36px;
  background: #004598;
  border-radius: 4px 4px 4px 4px;
}
.el-notification {
  margin-top: 180px;
}
</style>
